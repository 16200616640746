import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortAndFilter'
})
export class SortAndFilterPipe implements PipeTransform {

  constructor() { }

  transform(values: any[], filter_value: string, filter_key?: string, sort_key?: string): any {
    if(values){
      if (sort_key) {
        values = values.sort((a, b) => new Date(b[sort_key]).getTime() - new Date(a[sort_key]).getTime())
      }
      if (filter_value && filter_key) {
        values = values.filter(a => {
          if(a[filter_key]){
            if (typeof a[filter_key] === 'string') {
              return a[filter_key].toLowerCase().includes(filter_value)
            } else if (typeof a[filter_key] === 'number') {
              return a[filter_key] === Number(filter_value)
            }
          }
          return true
        })
      }
    }
    return values;
  }
}