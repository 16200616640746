import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, SubscriptionLike } from 'rxjs';
import { CONFIG } from 'src/helper/app-config';
import {
  GetPlanogramBodyParams,
  PlanogramSegmentEntityModal,
  ScreenDataModal,
} from 'src/modals/planogram';
import { PlanogramStateService } from 'src/services/planogram-state.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatDialog } from '@angular/material/dialog';
import { CreateCoolerTemplateDialogComponent } from '../create-cooler-template-dialog/create-cooler-template-dialog.component';
import { ConfirmDialogModalComponent } from '../confirm-dialog-modal/confirm-dialog-modal.component';
import { ComparePlanogramDialogComponent } from '../compare-planogram-dialog/compare-planogram-dialog.component';
import { CopyPlanogramDialogComponent } from '../copy-planogram-dialog/copy-planogram-dialog.component';

@Component({
  selector: 'app-planogram-list',
  templateUrl: './planogram-list.component.html',
  styleUrls: ['./planogram-list.component.css'],
})
export class PlanogramListComponent implements OnInit {
  CONFIG = CONFIG;

  saved_planogram: Observable<Object> = this.planogramState.all_planogram;
  loader: Observable<boolean> = this.planogramState.loader;
  is_more_planogram_observable: Observable<boolean> =
    this.planogramState.is_more_planograms;
  screen_data: Observable<ScreenDataModal[]> =
    this.planogramState.specific_screen_data;
  // draft_planogram: Observable<Object> = this.planogramState.draft_planogram;

  saved_planogram_subscription: SubscriptionLike;
  is_more_planogram_subscription: SubscriptionLike;
  screen_data_subscription: SubscriptionLike;

  tabs = [
    { name: 'Published', path: '../' + CONFIG.PATH.PUBLISHED },
    { name: 'Drafts', path: '../' + CONFIG.PATH.DRAFT },
  ];
  active_tab = this.tabs[1].name;
  displayedColumns: string[] = [
    'name',
    'channel',
    'sub_channel',
    'business_objective',
    'user_name',
    'status',
    'action',
  ];
  search_input = '';
  is_show_loader = false;
  is_more_planogram = true;
  planogram_limit: number;

  dataSource = new MatTableDataSource();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild(MatSort, { static: false }) set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private planogramState: PlanogramStateService,
    public dialog: MatDialog
  ) {
    this.planogram_limit = document.cookie.split('planogram_limit=')[1]
      ? Number(document.cookie.split('planogram_limit=')[1])
      : 0;
    if (!this.planogram_limit) {
      this.planogramState.getSpecificScreenData(['planogram_limit']);
      this.planogramState.getSpecificScreenData([
        'brandchartcolor',
        'categorychartcolor',
      ]);
    } else {
      this.planogramState.getSpecificScreenData([
        'brandchartcolor',
        'categorychartcolor',
      ]);
      let body_params: GetPlanogramBodyParams = {
        limit: this.planogram_limit,
        offset: 0,
        search: '',
        status: 'all',
      };
      this.planogramState.getAllPlanograms(body_params);
    }
    this.activatedRoute.params.subscribe((params) => {
      this.active_tab =
        params['planogram_status'] === 'published'
          ? this.tabs[0].name
          : this.tabs[1].name;
    });
  }

  ngOnInit(): void {
    sessionStorage.setItem('mode', 'edit');
    this.dataSource.sort = this.sort;
    this.saved_planogram_subscription = this.saved_planogram.subscribe((pl) => {
      if (pl) {
        let data = Object.values(pl).sort(
          (a, b) =>
            new Date(b.last_edited_date).getTime() -
            new Date(a.last_edited_date).getTime()
        );
        if (this.is_show_loader) {
          setTimeout(() => {
            this.is_show_loader = false;
          }, 1);
        }
        this.dataSource.data = data;
      }
    });

    this.is_more_planogram_subscription =
      this.is_more_planogram_observable.subscribe((is_more) => {
        this.is_more_planogram = is_more;
      });

    this.listenScreenConfig();
  }

  ngOnDestroy() {
    this.is_more_planogram_subscription.unsubscribe();
    this.saved_planogram_subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.dataSource.filterPredicate = (data: any, filter) => {
      let busniss_objectives = this.getAllBusinessObjectives(
        data.business_objective
      );

      return (
        data.name.toLowerCase().includes(filter) ||
        data.channel.value.toLowerCase().includes(filter) ||
        data.sub_channel.value.toLowerCase().includes(filter) ||
        busniss_objectives.toLowerCase().includes(filter) ||
        data.user_name.toLowerCase().includes(filter) ||
        data.status.toLowerCase().includes(filter)
      );
    };

    this.dataSource.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'channel':
        case 'sub_channel': {
          return item[property].value;
        }

        case 'business_objective': {
          return this.getAllBusinessObjectives(item[property]);
        }

        case 'date':
        case 'last_edited_date': {
          let newDate = new Date(item[property]);
          console.log(newDate);
          return newDate;
        }
        case 'name':
        case 'status':
        case 'user_name':
        default: {
          return item[property];
        }
      }
    };

    this.dataSource.paginator = this.paginator;
    this.dataSource.paginator._intl.firstPageLabel = '';
    this.dataSource.paginator._intl.nextPageLabel = '';
    this.dataSource.paginator._intl.previousPageLabel = '';
  }

  listenScreenConfig = () => {
    this.screen_data_subscription = this.screen_data.subscribe((sc) => {
      let chart_color_config = {};
      sc.forEach((ob) => {
        if (ob.key === 'brandchartcolor' || ob.key === 'categorychartcolor') {
          chart_color_config = {
            ...chart_color_config,
            ...{ [ob.key]: JSON.parse(ob.value) },
          };
        }
        if (ob.key === 'planogram_limit') {
          this.planogram_limit = Number(ob.value);
          document.cookie = `planogram_limit=${this.planogram_limit}`;
          let body_params: GetPlanogramBodyParams = {
            limit: this.planogram_limit,
            offset: 0,
            search: '',
            status: 'all',
          };
          this.planogramState.getAllPlanograms(body_params);
        }
      });
      if (Object.keys(chart_color_config).length > 0) {
        this.planogramState.setChartColorConfig(chart_color_config);
      }
    });
  };

  getAllBusinessObjectives = (business_ob: PlanogramSegmentEntityModal[]) => {
    let busniss_objectives = '';
    if (business_ob) {
      business_ob.forEach((ob, i) => {
        busniss_objectives += ob.value;
        if (i !== business_ob.length - 1) {
          busniss_objectives += ' , ';
        }
      });
    }
    return busniss_objectives;
  };

  paginationChange(pageEvt: PageEvent) {
    if (
      this.is_more_planogram &&
      pageEvt.pageIndex > pageEvt.previousPageIndex!
    ) {
      let body_params: GetPlanogramBodyParams = {
        limit: this.planogram_limit,
        offset: pageEvt.length,
        search: '',
        status: 'all',
      };
      this.planogramState.getAllPlanograms(body_params, false);
    }
  }

  updateFilter = (_event: any) => {
    this.dataSource.filter = this.search_input.trim().toLowerCase();
  };

  addNewPlanogram = () => {
    this.router.navigateByUrl(CONFIG.PATH.NEWPLANOGRAM);
  };

  handleRowClick = (row: any) => {
    let router_state = {
      screen_index: row.screen_id ? row.screen_id : 0,
    };
    this.router.navigateByUrl(CONFIG.PATH.PLANOGRAM + row.id, {
      state: router_state,
    });
  };

  copyPlanogram = (
    event: any,
    planogram_id: number,
    planogram_name: string
  ) => {
    event.stopPropagation();
    this.dialog.open(CopyPlanogramDialogComponent, {
      height: '200px',
      width: '500px',
      data: {
        planogram_id: planogram_id,
        planogram_name: planogram_name,
      },
    });
  };

  handleDeleteClick = (event: any, planogram_id: number) => {
    event.stopPropagation();
    const dialogRef = this.dialog.open(ConfirmDialogModalComponent, {
      height: '200px',
      width: '500px',
      data: {
        header_msg: 'Are you sure you want to delete?',
        header_msg_description: 'This action cannot be undone.',
        cancel_action: 'Cancel',
        ok_action: 'Continue',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (planogram_id !== undefined) {
          this.planogramState.deletePlanogram(planogram_id);
        }
      }
    });
  };

  addCoolerTemplate = () => {
    this.dialog.open(CreateCoolerTemplateDialogComponent, {
      height: '800px',
      width: '1000px',
    });
  };

  openComparePlanogramDialog = () => {
    this.dialog.open(ComparePlanogramDialogComponent, {
      height: '90%',
      width: '80%',
      data: {
        planogram_ids: [],
      },
    });
  };

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
}
