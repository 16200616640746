import { Component, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CONFIG } from 'src/helper/app-config';
import { SKUModal } from 'src/modals/planogram';
import { PlanogramStateService } from 'src/services/planogram-state.service';

@Component({
  selector: 'app-sku-replace-dialog',
  templateUrl: './sku-replace-dialog.component.html',
  styleUrls: ['./sku-replace-dialog.component.css']
})
export class SkuReplaceDialogComponent implements OnDestroy {

  CONFIG = CONFIG;

  sku_search_input = '';
  filter_sku_list: SKUModal[] = [];
  sku_list: SKUModal[] = [];
  sku_filter = 'all';

  //sas='?'+environment.sas
  sas="";
  sku_list_observer: Observable<SKUModal[]> = this.planogramState.available_skus;
  sku_list_subscriber : Subscription;


  constructor(private dialogRef: MatDialogRef<SkuReplaceDialogComponent>, private planogramState: PlanogramStateService) {
    this.sku_list_subscriber = this.sku_list_observer.subscribe(sku_list=>{
      this.sku_list = sku_list;
      this.filterSkus(this.sku_filter)
    })
   }

   filterSkus = (filter:string)=>{
    this.sku_filter = filter;
    if(filter === 'recommanded'){
      this.filter_sku_list = this.sku_list.filter(s=> s.picos === 0)
    }else if(filter === 'picos'){
      this.filter_sku_list = this.sku_list.filter(s=> s.picos === 1)
    }else{
      this.filter_sku_list = this.sku_list;
    }
  }

  ngOnDestroy(){
    this.sku_list_subscriber.unsubscribe();
  }

  selectSku(sku : any){
    this.dialogRef.close(sku)

  }

}
