import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, SubscriptionLike } from 'rxjs';
import { getPlacedSkuDetail, getPlanogramImageUrl, noWhitespaceValidator } from 'src/helper/app-utils';
import { PlanogramBaseDetailModal, SKUModal, PlanogramBaseDetailInfo, PlanogramSegmentEntityModal, ScreenDataModal } from 'src/modals/planogram';
import { PlanogramStateService } from 'src/services/planogram-state.service';
import { ConfirmDialogModalComponent } from '../confirm-dialog-modal/confirm-dialog-modal.component';
import { CONFIG } from 'src/helper/app-config';
import { AzureBlobStorageService } from 'src/services/azure-blob-storage.service';
import { MatSelectChange } from '@angular/material/select';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-setup-planogram',
  templateUrl: './setup-planogram.component.html',
  styleUrls: ['./setup-planogram.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})
export class SetupPlanogramComponent implements OnInit {

  CONFIG = CONFIG;

  sku_placement_change: boolean = false;
  create_new_template: boolean = false;
  stepper_index: number = 0;

  temp_stepper_index: number = 0;
  is_save_detail_and_nevigate: boolean = false;

  planogram_base_detail_form_group: FormGroup;

  cooler_template_form_group: FormGroup = this._formBuilder.group({
    cooler_template: new FormControl('', Validators.required),
  });

  add_sku_form_group: FormGroup = this._formBuilder.group({
    filled_sku: new FormControl(0, [Validators.required, Validators.min(80)]),
    volume: new FormControl(0),
    profit: new FormControl(0),
    revenue: new FormControl(0),
    transaction: new FormControl(0),
    // profit_per_uc: new FormControl(0, Validators.required)
  });
  mode:any;
  edit_template_id = -1;
  cooler_template_search_input = '';
  is_planogram_edit: boolean;
  edit_planogram_detail: any;
  planogram_placed_skus: SKUModal[] = [];
  picos_sku_list: SKUModal[] = [];
  planogram_image_url: string = '';
  planogram_status: string = '';
  saved_templates: any[]=[];
  vertical_brand_association: any={};
  vertical_cooler_orientation: boolean = false;
  is_go_back : boolean = false;
  //sas='?'+environment.sas;
  sas='';

  planogram_base_details_subscription: SubscriptionLike;
  selected_planogram_base_data_subscription: SubscriptionLike;
  next_event_api_response_subscription: SubscriptionLike;
  is_planogram_edit_subscription: SubscriptionLike;
  edit_planogram_detail_subscription: SubscriptionLike;
  planogram_placed_skus_subscription: SubscriptionLike;
  picos_sku_list_subscription: SubscriptionLike;
  cooler_template_form_subscription: SubscriptionLike;
  saved_templates_subscription: SubscriptionLike;
  throughput_metrics_subscription: SubscriptionLike;
  screen_data_subscription: SubscriptionLike;

  is_show_loader: Observable<boolean> = this.planogramState.loader;
  planogram_name_error: Observable<string> = this.planogramState.planogram_name_error;
  planogram_base_details: Observable<PlanogramBaseDetailInfo> = this.planogramState.planogram_base_details;
  is_planogram_edit_observable: Observable<boolean> = this.planogramState.is_planogram_edit;
  edit_planogram_detail_observable: Observable<any> = this.planogramState.edit_planogram_detail;
  saved_templates_observable: Observable<Object> = this.planogramState.saved_templates;
  selected_planogram_base_data: Observable<Object> = this.planogramState.selected_planogram_base_data;
  next_event_api_response: Observable<boolean> = this.planogramState.next_event_api_response;
  planogram_placed_skus_subscriber: Observable<SKUModal[]> = this.planogramState.planogram_placed_skus;
  picos_sku_list_observable: Observable<SKUModal[]> = this.planogramState.picos_skus;
  throughput_metrics_observer: Observable<any> = this.planogramState.throughput_metrics;
  screen_data: Observable<ScreenDataModal[]> = this.planogramState.specific_screen_data;



  @HostListener('window:beforeunload') saveSkusState() {
    if (this.stepper_index === 2 && this.sku_placement_change) {
      this.savePlanogramSkuPlacement(this.edit_planogram_detail, this.stepper_index);
    }
  }

  constructor(private location: Location, private activatedRoute: ActivatedRoute, private router: Router, private _formBuilder: FormBuilder,
    private planogramState: PlanogramStateService, public dialog: MatDialog, private blobService: AzureBlobStorageService) {

    this.planogramState.getBaseData();
    this.planogramState.getSpecificScreenData(['brandchartcolor' , 'categorychartcolor'])
    let planogram_id;
    this.activatedRoute.params.subscribe(params => {
      planogram_id = params['planogram_id'];
      if(planogram_id == undefined){
        sessionStorage.setItem('mode','new')
      }
      if (planogram_id !== undefined && planogram_id !== -1) {
        this.planogramState.setPlanogramEdit(true, planogram_id);
      }
      this.mode=sessionStorage.getItem('mode')
    })

    const router_state = this.router.getCurrentNavigation()?.extras.state;
    if(planogram_id && localStorage.getItem('stepper_index')){
      this.stepper_index = Number(localStorage.getItem('stepper_index'));
    }else if (router_state) {
      this.stepper_index = router_state['screen_index'] ? router_state['screen_index'] : this.stepper_index;
    }
    localStorage.setItem('stepper_index', String(this.stepper_index))

    let planogram_name = '', planogram_name_disable = false, channel = '', sub_channel = '', segment = '', portfolio_selection: number[] = [], business_goals: number[] = [];

    this.planogram_base_detail_form_group = this._formBuilder.group({
      planogram_name: new FormControl({ value: planogram_name, disabled: planogram_name_disable }, [Validators.required, noWhitespaceValidator, Validators.pattern(/^[A-Za-z0-9_]+$/) ]),
      channel: new FormControl({ value: channel, disabled: true }, Validators.required),                                                                  
      sub_channel: new FormControl({ value: sub_channel, disabled: true }, Validators.required),
      segment: new FormControl({ value: segment, disabled: true }, Validators.required),
      portfolio_selection: new FormControl({ value: portfolio_selection, disabled: true }, Validators.required),
      business_goals: new FormControl({ value: business_goals, disabled: true }, Validators.required),
    });
  }


  ngOnInit() {
    this.subscribeFormControls();
    this.selectedPlanogramBaseDataSubscription();
    this.is_planogram_edit_subscription = this.is_planogram_edit_observable.subscribe(is_edit => { this.is_planogram_edit = is_edit })
    this.edit_planogram_detail_subscription = this.edit_planogram_detail_observable.subscribe(detail => { this.edit_planogram_detail = detail })
    this.saved_templates_subscription = this.saved_templates_observable.subscribe(templates=> {this.saved_templates = Object.values(templates) } )
    this.next_event_api_response_subscription = this.next_event_api_response.subscribe(api_res => {
      if (api_res) {
        if (this.stepper_index !== this.temp_stepper_index) {
          // console.log("update index :: ", this.temp_stepper_index,this.stepper_index )
          this.is_save_detail_and_nevigate = false;
          this.updateStepperIndex(this.temp_stepper_index, this.is_planogram_edit, this.edit_planogram_detail, this.temp_stepper_index > this.stepper_index);
        }else if(this.is_go_back){
          this.is_go_back = false;
          this.goBack()
        }
        this.planogramState.setNextEventAPIResponse(false);
      }
    })

    this.planogram_placed_skus_subscription = this.planogram_placed_skus_subscriber.subscribe(skus => {
      this.planogram_placed_skus = skus;
    })

    this.cooler_template_form_subscription = this.cooler_template_form_group.valueChanges.subscribe(_v => {
      if (this.cooler_template_form_group.valid) {
        let scenario = this.getScenario();
        this.planogramState.getPicosSKUsList(this.edit_planogram_detail.id,scenario);
      }
    })

    this.picos_sku_list_subscription = this.picos_sku_list_observable.subscribe(list => {
      this.picos_sku_list = (list);
    })

    this.throughput_metrics_subscription = this.throughput_metrics_observer.subscribe(throughput=>{
      if(throughput && throughput.length > 0){
        this.add_sku_form_group.get('volume')?.setValue(throughput[0].volume);
        this.add_sku_form_group.get('revenue')?.setValue(throughput[0].revenue);
        this.add_sku_form_group.get('profit')?.setValue(throughput[0].profit);
        this.add_sku_form_group.get('transaction')?.setValue(throughput[0].transaction);
      }
    })
    this.listenScreenConfig();
  }

  ngOnDestroy() {
    this.planogram_base_details_subscription.unsubscribe();
    this.selected_planogram_base_data_subscription.unsubscribe();
    this.next_event_api_response_subscription.unsubscribe();
    this.is_planogram_edit_subscription.unsubscribe();
    this.edit_planogram_detail_subscription.unsubscribe();
    this.saved_templates_subscription.unsubscribe();
    this.planogramState.setPlanogramEdit(false, -1);
    localStorage.removeItem('stepper_index');
    localStorage.removeItem('channel');
    this.planogram_status = '';
    this.sku_placement_change = false;
    this.planogramState.clearPlanogramNameError();
  }

  listenScreenConfig = () => {
    this.screen_data_subscription = this.screen_data.subscribe(sc => {
      let chart_color_config = {}
      sc.forEach(ob => {
       if(ob.key === 'brandchartcolor' || ob.key === 'categorychartcolor' ){
          chart_color_config = {...chart_color_config , ...{[ob.key] : JSON.parse(ob.value)}}
        }
      })
      if(Object.keys(chart_color_config).length > 0){
        this.planogramState.setChartColorConfig(chart_color_config);
      }
    })
  }

  getScenario(_channel?:string) {
    return 'primary_key';
  }

  getRequriedDataForSpecificScreen = (stepper_index: number, planogam_id?: number, channel?:string) => {
    switch (stepper_index) {
      case 1:
        if(planogam_id)
          this.planogramState.getTemplates(planogam_id);
        break;

      case 2:
        let scenario = this.getScenario(channel);
        if (planogam_id) {
          this.planogramState.getSKUsListAndCoolerConfig(planogam_id, scenario);
        }
        break;

      case 3:
        if (planogam_id)
          this.planogramState.getPlanogramDataAndCoolerConfig(planogam_id);
        break;

      default:
        break;
    }

  }

  subscribeFormControls = () => {
    this.planogram_base_details_subscription = this.planogram_base_details.subscribe(p_b_d => {
        if (p_b_d.business_goals && p_b_d.business_goals.length > 0) {
          this.planogram_base_detail_form_group.controls['business_goals'].enable();
        }
        if (p_b_d.portfolio_selection && p_b_d.portfolio_selection.length > 0) {
          this.planogram_base_detail_form_group.controls['portfolio_selection'].enable();
        }
        if (p_b_d.channel && p_b_d.channel.length > 0) {
          this.planogram_base_detail_form_group.controls['channel'].enable();
        }
        if (p_b_d.sub_channel && p_b_d.sub_channel.length > 0) {
          this.planogram_base_detail_form_group.controls['sub_channel'].enable();
        }
        if (p_b_d.segment && p_b_d.segment.length > 0) {
          this.planogram_base_detail_form_group.controls['segment'].enable();
        }
    })
  }

  selectedPlanogramBaseDataSubscription = () => {
    this.selected_planogram_base_data_subscription = this.selected_planogram_base_data.subscribe((data: any) => {
      if (data.name) {
        localStorage.setItem('channel', data.channel.value)
        let business_objective = data.business_objective.map((ob: any) => ob.id);
        let portfolio_selection = data.portfolio_selection?.map((ob: any) => ob.id) || [];
        this.planogram_base_detail_form_group.get('planogram_name')?.setValue(data.name)
        this.planogram_base_detail_form_group.get('planogram_name')?.disable();
        this.planogram_base_detail_form_group.get('channel')?.setValue(data.channel.id)
        this.planogram_base_detail_form_group.get('sub_channel')?.setValue(data.sub_channel.id)
        this.planogram_base_detail_form_group.get('segment')?.setValue(data.segment.id)
        this.planogram_base_detail_form_group.get('business_goals')?.setValue(business_objective)
        this.planogram_base_detail_form_group.get('portfolio_selection')?.setValue(portfolio_selection)

        this.cooler_template_form_group.get('cooler_template')?.setValue(data.template_id);
        this.planogram_status = data.status;
        this.getRequriedDataForSpecificScreen(this.stepper_index, data.planogram_id, data.channel.value);

      }
    })
  }


  onBlurPlanogramName = () => {
    let planogram_name = this.planogram_base_detail_form_group.get("planogram_name")?.value.trim();
    if (this.planogram_base_detail_form_group.get("planogram_name")?.valid) {
      this.planogramState.verifyPlanogramUniqueName(planogram_name);
    }
  }

  isRequiredField = (field: string) => {
    const form_field = this.planogram_base_detail_form_group.get(field) as AbstractControl;
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator['required']);
  }


  handleCoolerCheckbox = (event: MatCheckboxChange) => {
    let cooler_template_prev_value = this.cooler_template_form_group.get('cooler_template')?.value;
    let current_value = event.source.value;
    if (cooler_template_prev_value === event.source.value) {
      current_value = '';
    }
    this.cooler_template_form_group.get('cooler_template')?.setValue(current_value)
  }

  handleChannelChange = (event: MatSelectChange)=>{
    localStorage.setItem('channel', event.source.triggerValue)
    // this.planogram_base_detail_form_group.get('channel')?.setValue(event.value)
    this.planogram_base_detail_form_group.get('sub_channel')?.setValue('')
  }

  clearSelectionOnValueChange = (_event:any, form_control_name:string)=>{
    this.planogram_base_detail_form_group.get(form_control_name)?.setValue('')
  }

  savePlanogramBaseDetails = (is_planogram_edit: boolean, edit_planogram_detail: any, screen_index: number, is_save_draft:boolean) => {
    let planogram_info: PlanogramBaseDetailModal = {
      planogram_name: this.planogram_base_detail_form_group.controls['planogram_name'].value,
      channel: this.planogram_base_detail_form_group.controls['channel'].value,
      sub_channel: this.planogram_base_detail_form_group.controls['sub_channel'].value,
      segment: this.planogram_base_detail_form_group.controls['segment'].value,
      portfolio_selection: this.planogram_base_detail_form_group.controls['portfolio_selection'].value,
      business_goal: this.planogram_base_detail_form_group.controls['business_goals'].value,
    }
    if (is_planogram_edit) {
      planogram_info.id = edit_planogram_detail.id;
      if (!planogram_info.screen_index) {
        planogram_info.screen_index = screen_index;
      }
      this.planogramState.editPlanogramBaseDetails(planogram_info, !is_save_draft);
    } else {
      planogram_info.screen_index = screen_index;
      this.planogramState.savePlanogramBaseDetails(planogram_info, !is_save_draft);
    }
  }

  handleCreateNewTemplateBtnClick = () => {
    this.create_new_template = true;
    this.planogramState.setSelectCoolerInfo({});
    this.edit_template_id = -1;
  }

  goToPreviousStep= (index:number) => {
    if(!isNaN(index)){
        this.stepper_index = index;
    }
  }

  handleDiscardClick = (planogram_id:any,arrow_click?:boolean) => {
    const dialogRef = this.dialog.open(ConfirmDialogModalComponent, {
      height: '200px',
      width: '500px',
      data: {
        header_msg: 'Are you sure you want to cancel?',
        header_msg_description: 'All progress will be lost.',
        cancel_action: 'Cancel',
        ok_action: 'Continue'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(planogram_id !== undefined && !arrow_click){
          this.planogramState.deletePlanogram(planogram_id);
        }
        this.goBack();
      }
    });
  }

  draftPlanogramDetails = (is_planogram_edit: boolean, edit_planogram_detail: any, step_index: number) => {
    const dialogRef = this.dialog.open(ConfirmDialogModalComponent, {
      height: '200px',
      width: '500px',
      data: {
        header_msg: 'Save to Drafts',
        header_msg_description: 'You can edit & publish the templates from draft later.',
        cancel_action: 'Cancel',
        ok_action: 'Okay'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.temp_stepper_index = step_index
        this.apiCallToSavePlanogramBaseDetails(is_planogram_edit, edit_planogram_detail, step_index, true);
        this.is_go_back = true;
        // this.goBack();
      }
    });
  }

  apiCallToSavePlanogramBaseDetails = (is_planogram_edit: boolean, edit_planogram_detail: any, step_index: number, is_save_draft: boolean) => {
    let screen_index = is_save_draft ? this.stepper_index : this.stepper_index + 1;

    switch (step_index) {
      case 0:
        this.savePlanogramBaseDetails(is_planogram_edit, edit_planogram_detail, screen_index, is_save_draft);
        break;
      case 1:
        this.saveCoolerToPlanogram(edit_planogram_detail, screen_index, is_save_draft);
        break;

      case 2:
        this.savePlanogramSkuPlacement(edit_planogram_detail, screen_index, is_save_draft);
        break;

      case 3:
        this.publishPlanogram(edit_planogram_detail, screen_index, is_save_draft)
        break;
      default:
        break;
    }
  }

  savePlanogramBaseDetailsAndNavigate = (is_planogram_edit: boolean, edit_planogram_detail: any, step_index: number) => {
    this.is_save_detail_and_nevigate = true;
    this.temp_stepper_index = step_index + 1;
    this.apiCallToSavePlanogramBaseDetails(is_planogram_edit, edit_planogram_detail, step_index, false);
  }

  setStepperIndex = (event: any, is_planogram_edit: boolean, edit_planogram_detail: any) => {
    // if (!this.is_save_detail_and_nevigate) {
      this.updateStepperIndex(event.selectedIndex, is_planogram_edit, edit_planogram_detail, event.selectedIndex > event.previouslySelectedIndex);
    // }
  }

  updateStepperIndex = (index: number, is_planogram_edit: boolean, edit_planogram_detail: any, _get_screen_data: boolean) => {
    this.stepper_index = index;
    localStorage.setItem('stepper_index', String(this.stepper_index))
    if (this.create_new_template) {
      this.create_new_template = false;
    }

    this.cooler_template_search_input = '';

    if (index === 0 && is_planogram_edit && edit_planogram_detail.id) {
      this.planogram_base_detail_form_group.get('planogram_name')?.disable();
    }
    this.getRequriedDataForSpecificScreen(index, edit_planogram_detail.id)

  }

  comparesegmentSelectedObjects(o1: any, o2: any) {
    if (o1.id == o2.id && o1.value === o2.value)
      return true;
    else return false
  }

  goBack = () => {
    this.router.navigateByUrl(CONFIG.PATH.PLANOGRAMS);
    this.planogramState.setPlanogramEdit(false, -1);
    this.planogramState.setSelectCoolerInfo({})
  }

  templateCreateBackEvent = (is_create: boolean) => {
    this.create_new_template = is_create;
    this.edit_template_id = -1;
  }

  templateCreateBackAndNextScreenNavigateEventHandle = (event_params: { is_success: boolean, cooler_id: number }) => {
    if (event_params.is_success) {
      this.create_new_template = false;
      this.edit_template_id = -1;
      this.planogramState.getTemplates(this.edit_planogram_detail.id);
      this.cooler_template_form_group.controls['cooler_template'].setValue(event_params.cooler_id);
    }
  }

  editTemplate(template: any) {
    this.create_new_template = true;
    this.edit_template_id = template.id;
    this.planogramState.setSelectCoolerInfo({ id: template.id, template_name: template.name })
  }

  saveCoolerToPlanogram(edit_planogram_detail: any, screen_index: number, is_save_draft:boolean) {
    if (edit_planogram_detail.id !== undefined) {
      let info = {
        screen_index: screen_index,
        planogram_id: edit_planogram_detail.id,
        template_id: this.cooler_template_form_group.controls['cooler_template'].value
      }
      this.planogramState.saveTemplateToPlanogram(info, !is_save_draft);
    }
  }

  /**
   * 
   * @param edit_planogram_detail 
   * @param screen_index 
   * @param is_save_draft 
   * async is used for blob file generation
   */
  async savePlanogramSkuPlacement(edit_planogram_detail: any, screen_index: number, is_save_draft: boolean = true) {
    if (edit_planogram_detail.id !== undefined) {
      // console.log(" this.vertical_brand_association :: ", this.vertical_brand_association)
      let info = {
        screen_index: screen_index,
        planogram_id: edit_planogram_detail.id,
        placed_skus: this.planogram_placed_skus.map(s => { return { sku_id: s.sku_id, sku_position: s.position, shelf: s.shelf, door:s.door } }),
        sku_details: getPlacedSkuDetail(this.planogram_placed_skus),
        vertical_orientation_flag: this.vertical_cooler_orientation,
        vertical_brand_association: this.vertical_cooler_orientation ? this.vertical_brand_association : {}
      }
 
      let name = this.planogram_base_detail_form_group.get('planogram_name')?.value;
      this.planogramState.savePlanogramSkuPlacement(info, !is_save_draft,name,edit_planogram_detail.id)
    

    }
  }

 

  publishPlanogram(edit_planogram_detail: any, _screen_index: number, is_save_draft: boolean = true) {
    if (edit_planogram_detail.id !== undefined) {
      let info = {
        planogram_id: edit_planogram_detail.id,
        screen_id: 3,
        status: is_save_draft ? 'draft' : 'publish',
        planogram_image: getPlanogramImageUrl(edit_planogram_detail.id, this.planogram_base_detail_form_group.get('planogram_name')?.value, false)
      }

      this.planogramState.publishPlanogram(info);

    }
  }

  filledSkuEvent(sku_flled: number) {
    this.add_sku_form_group.get('filled_sku')?.setValue(sku_flled);
  }

  skuPlacementFlagUpdate(is_change:boolean){
    this.sku_placement_change = is_change;
  }

  businessInsightCalculationEvent(business_insight_cal: any) {
    this.add_sku_form_group.get('volume')?.setValue(business_insight_cal.volume);
    this.add_sku_form_group.get('revenue')?.setValue(business_insight_cal.revenue);
    this.add_sku_form_group.get('profit')?.setValue(business_insight_cal.profit);
    this.add_sku_form_group.get('transaction')?.setValue(business_insight_cal.transaction);
  }

  sortTemplates(saved_templates: any[]){
    let selected_template_id = this.cooler_template_form_group.get('cooler_template')?.value;
    if(this.saved_templates.length > 0 && !isNaN(selected_template_id) && this.saved_templates[0].id !== selected_template_id){
      let index = this.saved_templates.findIndex(t=> t.id === selected_template_id);
      if(index !== -1){
        let selected_template = this.saved_templates[index];
        this.saved_templates.splice(index,1);
        this.saved_templates.unshift(selected_template)
      }
     document.getElementById('templates-container')?.scrollTo({top:0})
    }

    return saved_templates;
  }

  getUniqueValues = (source: PlanogramSegmentEntityModal[], key:string)=>{
    if(source && source.length > 0){
      return [...new Map(source.map((s:any) => [s[key], s])).values()];
    }
    return [];
  }

  updateVerticalColumnBrandAssociation = (brand_association: any)=>{
    this.vertical_brand_association = brand_association;
  }

  updateCoolerOrientation = (orientation:boolean)=>{
    this.vertical_cooler_orientation = orientation;
  }

}

