import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { Observable, Subscription, SubscriptionLike } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CONFIG } from 'src/helper/app-config';
import { noWhitespaceValidator } from 'src/helper/app-utils';
import { CoolerTemplateBaseDataModal, CoolerTypeInfo, PlanogramModal, ScreenDataModal } from 'src/modals/planogram';
import { PlanogramStateService } from 'src/services/planogram-state.service';


@Component({
  selector: 'app-cooler-template-creator',
  templateUrl: './cooler-template-creator.component.html',
  styleUrls: ['./cooler-template-creator.component.css']
})
export class CoolerTemplateCreatorComponent implements OnInit, OnDestroy {

  CONFIG = CONFIG;
  constructor(private _formBuilder: FormBuilder, private planogramState: PlanogramStateService) {
    let required_keys : string[] = ['shelf_height', 'placeholder_width', 'distance_between_shelves', 'dim_benchmark_clearance',
                                      'max_height_of_sku', 'placeholder_image']
    this.planogramState.getSpecificScreenData(required_keys);
  }

  @Input() is_in_dialog_modal: boolean;
  @Input() edit_template_id: number;
  @Input() planogram_id:number;
  @Output() newTemplateCreateBackEvent = new EventEmitter<boolean>();
  @Output() newTemplateCreateBackAndNextScreenNavigateEvent = new EventEmitter<{ is_success: boolean, cooler_id: number }>();

  cooler_type_subscription: Subscription;
  banner_subscription: Subscription;
  shelf_subscription: Subscription;
  facing_subscription: Subscription;
  traffic_direction_subscription: Subscription;
  individual_shelf_facing_subscription: Subscription;
  screen_data_subscription: SubscriptionLike;
  create_new_template_success_subscription: SubscriptionLike;
  selected_cooler_template_base_data_subscription: SubscriptionLike;
  cooler_template_base_data_subscription: SubscriptionLike;
  no_of_doors_subscription: SubscriptionLike;
  ic_facing_subscription: SubscriptionLike;
  fc_facing_subscription: SubscriptionLike;
  ic_shelves_subscription: SubscriptionLike;

  cooler_config: PlanogramModal = {} as any;
  //sas='?'+environment.sas;
  sas='';
  dim_benchmark_clearance  = 0;
  sku_max_height = 1;
  shelf_height = 20;
  placeholder_image_link = '';
  placeholder_width = 1;
  distance_between_shelves = 1;
  update_select_cooler_template_data = false;
  selected_cooler_template:any;
  cooler_template_base_data : CoolerTemplateBaseDataModal;
  is_default_template: boolean = false;
  planogram_create_template_form_group: FormGroup = this._formBuilder.group({
    template_name: new FormControl('', [Validators.required, noWhitespaceValidator, Validators.pattern(/^[A-Za-z0-9_]+$/)]),
    cooler_type: new FormControl({ value: '', disabled: false }, Validators.required),
    sub_cooler: new FormControl({ value: '', disabled: true }),
    shelf_count: new FormControl({ value: 1, disabled: true }, [Validators.required, Validators.min(1)]),
    fc_shelf_facings: new FormControl({ value: 1, disabled: true }, [ Validators.min(1)]),
    ic_shelf_facings:new FormControl({ value: 1, disabled: true }, [ Validators.min(1)]),
    mode_of_facings: new FormControl({ value: CONFIG.COOLERFACINGMODE.BATCH, disabled: true }, Validators.required),
    traffic_direction: new FormControl({ value: '', disabled: true }, Validators.required),
    banner: new FormControl({ value: '', disabled: true }, Validators.required),
    no_of_doors: new FormControl({ value: 1, disabled: true }, Validators.required),
    ic_shelves: new FormControl({ value: [], disabled: true })
  });

  individual_shelf_facing: FormGroup = this._formBuilder.group({});

  selected_base_cooler_info: any = {};

  cooler_template_base_data_observer: Observable<CoolerTemplateBaseDataModal> = this.planogramState.cooler_template_base_data;
  screen_data: Observable<ScreenDataModal[]> = this.planogramState.specific_screen_data;
  template_name_error: Observable<string> = this.planogramState.template_unique_name_error;
  create_new_template_success: Observable<boolean> = this.planogramState.create_new_template_success;
  selected_cooler_template_observer: Observable<any> = this.planogramState.selected_cooler_template;
  selected_cooler_template_base_data: Observable<any> = this.planogramState.selected_cooler_template_base_data;

  ngOnInit(): void {
    if (this.edit_template_id !== -1) {
      this.planogramState.getSavedCoolerTemplateDataAndTemplateBaseData(this.planogram_id, this.edit_template_id);
    } else {
      this.planogramState.getCoolerTemplateBaseData(this.planogram_id);
    }

    this.coolerTypeFormFeildValueChangeSubscription();
    this.shelfFormFeildValueChangeSubscription();

    this.banner_subscription = this.planogram_create_template_form_group.get('banner')?.valueChanges.subscribe(value => {
      this.cooler_config.banner = value;
    }) as any;

    this.traffic_direction_subscription = this.planogram_create_template_form_group.get('traffic_direction')?.valueChanges.subscribe(value => {
      this.cooler_config.traffic_direction = value;
    }) as any;

    this.ic_facing_subscription = this.planogram_create_template_form_group.get('ic_shelf_facings')?.valueChanges.subscribe(value => {
      this.cooler_config.facings_ic = value;
      this.updateFacings()
    }) as any;

    this.fc_facing_subscription = this.planogram_create_template_form_group.get('fc_shelf_facings')?.valueChanges.subscribe(value => {
      this.cooler_config.facings_fc = value;
      this.updateFacings()
    }) as any;

    this.ic_shelves_subscription = this.planogram_create_template_form_group.get('ic_shelves')?.valueChanges.subscribe(value => {
      this.cooler_config.ic_shelves = value;
      this.planogram_create_template_form_group.get('shelf_count')?.setValidators([Validators.required, Validators.min(1), Validators.max(this.getMaxShelfCount(this.selected_base_cooler_info.cooler_height_calc))])
      this.planogram_create_template_form_group.get('shelf_count')?.updateValueAndValidity();
      this.updateFacings()
    }) as any;

    this.no_of_doors_subscription = this.planogram_create_template_form_group.get('no_of_doors')?.valueChanges.subscribe(value => {
      this.cooler_config.no_of_doors = value;
      if(!this.validTrafficDirection(value)){
        this.planogram_create_template_form_group.get('traffic_direction')?.setValue(this.getTrafficDirection(value))
      }
    }) as any;

    this.selected_cooler_template_base_data_subscription = this.selected_cooler_template_observer.subscribe(cooler=>{
      this.selected_cooler_template = cooler;
    })

    this.cooler_template_base_data_subscription = this.cooler_template_base_data_observer.subscribe(t_info=>{
      this.cooler_template_base_data = t_info;
    })

    this.listenScreenConfig();
    this.listenNewTemplateCreationSuccessFlag();
    this.listenSelectedCoolerDetail();

  }

  ngOnDestroy(): void {
    this.cooler_type_subscription.unsubscribe();
    this.banner_subscription.unsubscribe();
    this.screen_data_subscription.unsubscribe();
    this.shelf_subscription.unsubscribe();
    this.traffic_direction_subscription.unsubscribe();
    this.create_new_template_success_subscription.unsubscribe();
    this.selected_cooler_template_base_data_subscription.unsubscribe();
    this.cooler_template_base_data_subscription.unsubscribe();
    this.no_of_doors_subscription.unsubscribe();
    this.ic_facing_subscription.unsubscribe();
    this.fc_facing_subscription.unsubscribe();
    this.ic_shelves_subscription.unsubscribe();
    this.planogramState.clearTemplateNameError();
  }

  coolerTypeFormFeildValueChangeSubscription = () => {
    this.cooler_type_subscription = this.planogram_create_template_form_group.get('cooler_type')?.valueChanges.subscribe(value => {
      if (this.cooler_config.cooler_id !== value) {
          let cooler_info: CoolerTypeInfo = this.cooler_template_base_data.cooler_type.find(ob => ob.id === value)!;
          if (cooler_info && cooler_info.id) {
            this.selected_base_cooler_info = cooler_info;

            this.cooler_config.cooler_height_calc = cooler_info.cooler_height_calc;
            this.cooler_config.cooler_width_calc = cooler_info.cooler_width_calc;
            this.cooler_config.cooler_height = cooler_info.height;
            this.cooler_config.cooler_width = cooler_info.width;
            this.cooler_config.shelf_length = cooler_info.width;
            this.cooler_config.cooler_length = cooler_info.length;
            this.cooler_config.max_sku_height_fc = this.cooler_template_base_data.max_sku_height_fc;
            this.cooler_config.min_sku_height_ic = this.cooler_template_base_data.min_sku_height_ic;
            this.cooler_config.max_sku_height_ic = this.cooler_template_base_data.max_sku_height_ic;
            this.cooler_config.avg_sku_width_ic = this.cooler_template_base_data.avg_sku_width_ic;
            this.cooler_config.avg_sku_width_fc = this.cooler_template_base_data.avg_sku_width_fc;

            let sub_cooler_disable = cooler_info.name === 'Regular';
            let max_shelf = this.getMaxShelfCount(cooler_info.cooler_height_calc);
            let max_ic_facing = this.getMaxFacing(this.cooler_template_base_data.avg_sku_width_ic, cooler_info.cooler_width_calc);
            let max_fc_facing = this.getMaxFacing(this.cooler_template_base_data.avg_sku_width_fc, cooler_info.cooler_width_calc);
            
            this.cooler_config.no_of_shelves = this.update_select_cooler_template_data ? this.cooler_config.no_of_shelves : max_shelf;

            if (!this.update_select_cooler_template_data) {
              this.cooler_config.name = cooler_info.name;
              this.cooler_config.cooler_id = cooler_info.id;
              this.cooler_config.no_of_doors = cooler_info.no_of_doors || 1;
              this.cooler_config.facings_fc = max_fc_facing;
              this.cooler_config.facings_ic = max_ic_facing;
              this.cooler_config.ic_shelves = this.ICShelfs();
              this.cooler_config.traffic_direction = this.getTrafficDirection(this.cooler_config.no_of_doors);

              this.planogram_create_template_form_group.get('mode_of_facings')?.reset({ value: CONFIG.COOLERFACINGMODE.BATCH, disabled: false })
              this.planogram_create_template_form_group.get('shelf_count')?.reset({ value: max_shelf, disabled: false });
              this.planogram_create_template_form_group.get('ic_shelf_facings')?.reset({ value: max_ic_facing, disabled: false })
              this.planogram_create_template_form_group.get('fc_shelf_facings')?.reset({ value: max_fc_facing, disabled: false })
              this.planogram_create_template_form_group.get('traffic_direction')?.reset({ value: this.cooler_config.traffic_direction, disabled: false })
              this.planogram_create_template_form_group.get('banner')?.reset({ value: '', disabled: false })
              this.planogram_create_template_form_group.get('sub_cooler')?.reset({ value: '', disabled: sub_cooler_disable })
              this.planogram_create_template_form_group.get('no_of_doors')?.reset({ value: 1, disabled: false })
              this.planogram_create_template_form_group.get('ic_shelves')?.reset({ value: this.cooler_config.ic_shelves, disabled: false })
              
            }

            this.cooler_config.shelves = Array(this.cooler_config.no_of_shelves).fill(0).map((_x, i) => {
              return {
                cooler_shelf: i + 1, no_of_facings: this.getShelfNoOfFacings(this.planogram_create_template_form_group.get('ic_shelves')?.value, i + 1),
                placeholder_image: this.placeholder_image_link
              }
            })

            this.updateValidators(sub_cooler_disable, max_shelf, max_ic_facing, max_fc_facing);

            this.planogram_create_template_form_group.get('shelf_count')?.updateValueAndValidity()
            this.planogram_create_template_form_group.get('ic_shelf_facings')?.updateValueAndValidity()
            this.planogram_create_template_form_group.get('fc_shelf_facings')?.updateValueAndValidity()
            this.planogram_create_template_form_group.get('ic_shelves')?.updateValueAndValidity()
            if (this.update_select_cooler_template_data) {
              this.update_select_cooler_template_data = false;
            }
          }
      }
    }) as any;

  }

  ICShelfs = ()=>{
    return this.cooler_template_base_data.ic_flag && !this.cooler_template_base_data.fc_flag ? this.counter(this.cooler_config.no_of_shelves) : [];
  }

  updateValidators = (sub_cooler_disable: boolean, max_shelf: number, max_ic_facing: number, max_fc_facing: number)=>{

    if(sub_cooler_disable){
      this.planogram_create_template_form_group.get('sub_cooler')?.clearValidators();
    }else{
      this.planogram_create_template_form_group.get('sub_cooler')?.setValidators([Validators.required])
    }
    this.planogram_create_template_form_group.get('sub_cooler')?.updateValueAndValidity();
    this.planogram_create_template_form_group.get('shelf_count')?.setValidators([Validators.required, Validators.min(1), Validators.max(max_shelf)])
    
    if(this.cooler_template_base_data.ic_flag){
      this.planogram_create_template_form_group.get('ic_shelf_facings')?.setValidators([Validators.required, Validators.min(0), Validators.max(max_ic_facing)])
    }else{
      this.planogram_create_template_form_group.get('ic_shelf_facings')?.clearValidators();
    }

    if(this.cooler_template_base_data.fc_flag){
      this.planogram_create_template_form_group.get('fc_shelf_facings')?.setValidators([Validators.required, Validators.min(0), Validators.max(max_fc_facing)])
    }else{
      this.planogram_create_template_form_group.get('fc_shelf_facings')?.clearValidators();
    }

    if(this.cooler_template_base_data.ic_flag && this.cooler_template_base_data.fc_flag){
      this.planogram_create_template_form_group.get('ic_shelves')?.setValidators([Validators.required])
    }else{
      this.planogram_create_template_form_group.get('ic_shelves')?.clearValidators();
    }
  }

  getMaxFacing = (avg_sku_width: number, cooler_width:number)=>{
    if(avg_sku_width && cooler_width){
      cooler_width = cooler_width*(1- this.dim_benchmark_clearance)
      return Math.round(cooler_width/ avg_sku_width)
    }
   return 0;
  }

  getMaxShelfCount = (cooler_height:number)=>{
    if(cooler_height){
      let ic_shelves = this.planogram_create_template_form_group.get('ic_shelves')?.value;
      if(ic_shelves && ic_shelves.length>0){
        return Math.floor((cooler_height - (this.cooler_config.max_sku_height_ic*ic_shelves.length))/ (this.cooler_template_base_data.max_sku_height_fc + CONFIG.SHELF_HEIGHT_CLEARANCE)) + ic_shelves.length;
      }
      return this.cooler_template_base_data.ic_flag && !this.cooler_template_base_data.fc_flag ? Math.floor(cooler_height/(this.cooler_config.max_sku_height_ic + CONFIG.SHELF_HEIGHT_CLEARANCE)) : Math.floor(cooler_height/(this.cooler_config.max_sku_height_fc + CONFIG.SHELF_HEIGHT_CLEARANCE));
    }
    return 0;
  }

  getTrafficDirection = (door: number)=>{
    switch (door) {
      case 3:
        return CONFIG.COOLERTRAFFICDIRECTIONS.DOOR3[0];

      case 2:
        return CONFIG.COOLERTRAFFICDIRECTIONS.DOOR2[0];
    
      default:
        return CONFIG.COOLERTRAFFICDIRECTIONS.DOOR1[0];
    }
  }

  validTrafficDirection = (door: number)=>{
    let traffic_direction = this.planogram_create_template_form_group.get('traffic_direction')?.value;
    switch (door) {
      case 3:
        return CONFIG.COOLERTRAFFICDIRECTIONS.DOOR3.includes(traffic_direction);

      case 2:
        return CONFIG.COOLERTRAFFICDIRECTIONS.DOOR2.includes(traffic_direction);
    
      default:
        return CONFIG.COOLERTRAFFICDIRECTIONS.DOOR1.includes(traffic_direction);
    }
  }

  listenSelectedCoolerDetail = () => {
    this.selected_cooler_template_base_data_subscription = this.selected_cooler_template_base_data.subscribe(template_info => {
      if (template_info.name) {
        
        this.is_default_template = template_info.default_flag;
        this.update_select_cooler_template_data = true;

        let ic_shelves = this.is_default_template && !this.cooler_template_base_data.fc_flag ? this.counter(template_info.shelves.length) : template_info.ic_shelves;
        this.cooler_config.no_of_shelves = template_info.shelves.length;
        this.cooler_config.traffic_direction = template_info.traffic_direction;
        this.cooler_config.name = template_info.name;
        this.cooler_config.cooler_id = template_info.id;
        this.cooler_config.ic_shelves = ic_shelves;
        this.planogram_create_template_form_group.get('template_name')?.reset({ value: template_info.name, disabled: true });
        this.planogram_create_template_form_group.get('sub_cooler')?.reset({ value: template_info.sub_cooler.id, disabled: template_info.cooler_type.name === 'Regular' });
        // this.planogram_create_template_form_group.get('sub_cooler')?.reset({ value: template_info.sub_cooler.id, disabled: template_info.cooler_type.id === 1 });
        this.planogram_create_template_form_group.get('banner')?.reset({ value: template_info.banner, disabled: this.is_default_template });
        this.planogram_create_template_form_group.get('shelf_count')?.reset({ value: template_info.shelves.length, disabled: false });
        this.planogram_create_template_form_group.get('mode_of_facings')?.reset({ value: CONFIG.COOLERFACINGMODE.BATCH, disabled: this.is_default_template });
        this.planogram_create_template_form_group.get('traffic_direction')?.reset({ value: template_info.traffic_direction, disabled: this.is_default_template });
        this.planogram_create_template_form_group.get('no_of_doors')?.reset({ value: template_info.no_of_doors, disabled: this.is_default_template })
        this.planogram_create_template_form_group.get('ic_shelves')?.reset({ value: ic_shelves, disabled: false })
       

        this.planogram_create_template_form_group.get('ic_shelf_facings')?.reset({ value: template_info.facings_ic, disabled: false })
        this.planogram_create_template_form_group.get('fc_shelf_facings')?.reset({ value: template_info.facings_fc, disabled: false })

        this.planogram_create_template_form_group.get('cooler_type')?.reset({ value: template_info.cooler_type.id, disabled: this.is_default_template });
      }
    })
  }

  shelfFormFeildValueChangeSubscription = () => {
    this.shelf_subscription = this.planogram_create_template_form_group.get('shelf_count')?.valueChanges.subscribe(value => {
      if (value > 0) {
        if (!this.update_select_cooler_template_data) {
          if(this.cooler_template_base_data.ic_flag && !this.cooler_template_base_data.fc_flag)
            this.cooler_config.ic_shelves = this.counter(value)
          this.cooler_config.no_of_shelves = value;
          this.cooler_config.shelves = Array(value).fill(0).map((_x, i) => {
            if (this.cooler_config.shelves && this.cooler_config.shelves[i]) {
              return this.cooler_config.shelves[i];
            } else {
              return {
                cooler_shelf: i + 1, no_of_facings: this.getShelfNoOfFacings(this.planogram_create_template_form_group.get('ic_shelves')?.value , i+1),
                placeholder_image: this.placeholder_image_link
              }
            }
          })
        }

      }

    }) as any;
  }

  updateFacings=()=>{
    let ic_shelves = this.planogram_create_template_form_group.get('ic_shelves')?.value;
    if(ic_shelves && this.cooler_config.shelves){
      this.cooler_config.shelves = this.cooler_config.shelves.map(s=>{
        s.no_of_facings = this.getShelfNoOfFacings(ic_shelves, s.cooler_shelf)
        return s;
      })
    }
    
  }

  getShelfNoOfFacings = (ic_shelves: number[], cooler_shelf: number)=>{
    if(ic_shelves && ic_shelves.includes(cooler_shelf)){
     return this.planogram_create_template_form_group.get('ic_shelf_facings')?.value || 0
    }else{
      return this.planogram_create_template_form_group.get('fc_shelf_facings')?.value || 0
    }
  }

  facingsFormFeildValueChangeSubscription = () => {
    this.facing_subscription = this.planogram_create_template_form_group.get('facings')?.valueChanges.subscribe(value => {
      let shelf = this.planogram_create_template_form_group.get('shelf_count')?.value;
      if (this.planogram_create_template_form_group.get('mode_of_facings')?.value === CONFIG.COOLERFACINGMODE.BATCH) {
        this.cooler_config.shelves = Array(shelf).fill(0).map((_x, i) => {
          let no_of_facings = value;
          return {
            cooler_shelf: i + 1, no_of_facings: no_of_facings,
            placeholder_image: this.placeholder_image_link
          }
        })
      }

    }) as any;
  }

  individualShelfFacingFormGroupValueChangeSubscription = () => {
    this.individual_shelf_facing_subscription = this.individual_shelf_facing.valueChanges.subscribe(i_s_f_v => {
      let total_facing = 0, shelf_count = 0;
      Object.entries(i_s_f_v).forEach(([key, value], _i) => {
        let index = Number(key.split('_')[1]);
        if (this.cooler_config.shelves && this.cooler_config.shelves[index - 1]) {
          this.cooler_config.shelves[index - 1].no_of_facings = value as any;
        }
        total_facing += value as any;
        shelf_count++;
      })

      if (this.planogram_create_template_form_group.get('mode_of_facings')?.value === CONFIG.COOLERFACINGMODE.INDIVIDUAL &&
        this.planogram_create_template_form_group.get('shelf_count')?.value === shelf_count &&
        total_facing !== this.planogram_create_template_form_group.get('facings')?.value) {
        this.planogram_create_template_form_group.get('facings')?.setValue(total_facing)
      }
    })
  }

  listenScreenConfig = () => {
    this.screen_data_subscription = this.screen_data.subscribe(sc => {
      sc.forEach(ob => {
        switch (ob.key) {
          case 'placeholder_image':
            this.placeholder_image_link = ob.value;
            if (this.cooler_config.shelves) {
              this.cooler_config.shelves = this.cooler_config.shelves.map(s => {
                s.placeholder_image = this.placeholder_image_link
                return s;
              })
            }
            break;

          case 'max_height_of_sku':
            this.sku_max_height = ob.value;
            break;

          case 'shelf_height':
          case 'placeholder_width':
          case 'distance_between_shelves':
          case 'dim_benchmark_clearance':
            this[ob.key] = ob.value;
            break;

          default:
            break;
        }
      })
    })
  }

  listenNewTemplateCreationSuccessFlag () {

    this.create_new_template_success_subscription = this.create_new_template_success.subscribe(flg => {
  
      if (flg && this.individual_shelf_facing.valid && this.planogram_create_template_form_group.valid) {
          if (this.selected_cooler_template && flg === true) {
            this.newTemplateCreateBackAndNextScreenNavigateEvent.emit({ is_success: true, cooler_id: this.selected_cooler_template.id });
            this.planogramState.setCreateNewTemplateSuccessFlag(false);
          }
      }
    })
  }


  isRequiredField = (field: string) => {
    const form_field = this.planogram_create_template_form_group.get(field) as AbstractControl;
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator['required']);
  }

  handleBackClick = () => {
    this.newTemplateCreateBackEvent.emit(false);
    if (this.edit_template_id !== -1) {
      this.planogramState.setSelectCoolerInfo({})
    }
  }

  onBlurTemplateName = () => {
    let template_name = this.planogram_create_template_form_group.get("template_name")?.value.trim();
    if (this.planogram_create_template_form_group.get("template_name")?.valid) {
      this.planogramState.verifyTemplateUniqueName(template_name);
    }
  }

  handleFormFeildCounterChnage = (form_group: FormGroup, feild_name: string, counter_change: number) => {
    let form_field = form_group.get(feild_name);
    if (form_field && form_field.enabled) {
      let prev_value = form_field?.value;
      prev_value = isNaN(prev_value) ? 0 : prev_value;
      form_group.get(feild_name)?.setValue(prev_value + counter_change)
    }
  }

  updateCoolerTemplateBanner = (banner: any) => {
    if (this.planogram_create_template_form_group.controls['banner'].enabled) {
      let prev_value = this.planogram_create_template_form_group.controls['banner'].value;
      let banner_value = banner;
      if (prev_value.id === banner.id) {
        banner_value = ''
      }
      this.planogram_create_template_form_group.controls['banner'].setValue(banner_value);
    }
  }

  counter = (number: number) => {
    if(number && !isNaN(number) ){
      return Array(number).fill(0).map((_x,i)=> i+1);
    }
    return [];
  }

  createNewCoolerTemplate = () => {

    let planogram_create_template_form_group_values = this.planogram_create_template_form_group.getRawValue();
    let shelf_count = planogram_create_template_form_group_values.shelf_count;
    let ic_shelves : number[] = [];
    if(this.cooler_template_base_data.ic_flag){
      ic_shelves = this.cooler_template_base_data.fc_flag ? planogram_create_template_form_group_values.ic_shelves : this.counter(shelf_count)
    }
    let cooler_template_info: any = {
      template_name: planogram_create_template_form_group_values.template_name,
      cooler_type: planogram_create_template_form_group_values.cooler_type,
      sub_cooler: planogram_create_template_form_group_values.sub_cooler,
      banner: planogram_create_template_form_group_values.banner.id,
      shelves: shelf_count,
      mode_of_facings: planogram_create_template_form_group_values.mode_of_facings,
      facings_ic: this.cooler_template_base_data.ic_flag ? planogram_create_template_form_group_values.ic_shelf_facings : 0,
      facings_fc: this.cooler_template_base_data.fc_flag ? planogram_create_template_form_group_values.fc_shelf_facings : 0,
      no_of_doors: planogram_create_template_form_group_values.no_of_doors,
      ic_shelves: ic_shelves,
      traffic_direction: planogram_create_template_form_group_values.traffic_direction,
      planogram_id: this.planogram_id
    };

    if (!isNaN(this.edit_template_id) && this.edit_template_id !== -1 && this.selected_cooler_template.id) {
      cooler_template_info.template_id = this.selected_cooler_template.id
      this.planogramState.editCoolerTemplate(cooler_template_info);
    } else {
      this.planogramState.saveCoolerTemplate(cooler_template_info);
    }
  }

  getSelectedDoorTrafficOptions(){
    let door = this.planogram_create_template_form_group.get('no_of_doors')?.value || 1;
    switch (door) {
      case 3:
        return CONFIG.COOLERTRAFFICDIRECTIONS.DOOR3; 

      case 2:
        return CONFIG.COOLERTRAFFICDIRECTIONS.DOOR2; 

      default:
        return CONFIG.COOLERTRAFFICDIRECTIONS.DOOR1;
    }
  }

  getCoolerTypeList = (cooler_templets?: CoolerTypeInfo[])=>{
    if(cooler_templets){
      return !this.is_default_template ? cooler_templets.filter(ct=> !ct.default_flag && !this.is_default_template)  :cooler_templets
    }
    return []
  }

}
