import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, SubscriptionLike } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CONFIG } from 'src/helper/app-config';
import { SKUModal } from 'src/modals/planogram';
import { PlanogramStateService } from 'src/services/planogram-state.service';


interface DialogData{
  sku_id:number;
}

@Component({
  selector: 'app-sku-info-dialog',
  templateUrl: './sku-info-dialog.component.html',
  styleUrls: ['./sku-info-dialog.component.css']
})
export class SkuInfoDialogComponent implements OnInit, OnDestroy {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, 
  private dialogRef: MatDialogRef<SkuInfoDialogComponent>, private planogramState: PlanogramStateService) { }
  //sas='?'+environment.sas
  sas="";
  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(_res=>{
      this.planogramState.setSKUInfo({});
    })
    this.sku_list_subscription = this.sku_list_subscriber.subscribe(list=>{
      let selected_sku = list.find(l=> l.sku_id === this.data.sku_id);
      if(selected_sku){
        this.sku = selected_sku
      }
    })
  }

  ngOnDestroy(): void {
    this.sku_list_subscription.unsubscribe()
  }

  CONFIG = CONFIG;
  sku_list_subscription: SubscriptionLike;
  sku_list_subscriber: Observable<SKUModal[]> = this.planogramState.available_skus;
  sku: SKUModal = {} as any;

  dialogClose(){
    this.dialogRef.close()
  }

}




