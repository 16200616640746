<div id="nav-container" style="height: 100%; width: 100%;">
  <nav class="navbar navbar-expand-lg navbar-light" style="height: 100%;">
    <!-- <img src="./assets/coke_logo.png" style="height: 60%; cursor: pointer;"  [routerLink]="CONFIG.PATH.PLANOGRAMS" alt="" /> -->
    <div class="dp-logo-container">
      <img src="../../../assets/dplogo.png" alt="profile" height="37px" width="100px">
    </div>
    <div class="collapse navbar-collapse" id="navbar-supported-content">
      <ul class="navbar-nav mx-auto" style="height: 100%;margin-left:200px !important">
        <li class="nav-item" *ngFor="let tab of tabs">
          <a class="nav-link" [routerLink]="tab.pathname" routerLinkActive="tab.name === active_tab"
            [ngClass]="{'active': tab.name === active_tab, 'disabled': tab.name !== tabs[0].name}">{{tab.name}}</a>
        </li>
      </ul>

      <div class="profile">
        <em class="bi bi-person" style="font-size: 1.5rem;cursor: pointer; "></em>
        <!-- <mat-menu #menu="matMenu" class="logout-menu"> 
          <button mat-menu-item >Logout <em class="bi bi-box-arrow-left"
              style="float: right;font-size: 1.5rem;"></em></button>
        </mat-menu> -->
      </div>
    </div>
  </nav>
</div>