import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { filter, takeUntil } from 'rxjs/operators';
import { CONFIG } from 'src/helper/app-config';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};

@Component({
  selector: 'app-auth-response',
  templateUrl: './auth-response.component.html',
  styleUrls: ['./auth-response.component.css']
})
export class AuthResponseComponent implements OnInit {

  profile!: ProfileType;
  saved_access_token_db:'';
  private readonly _destroying$ = new Subject<void>();

  constructor(private http: HttpClient,
    private router: Router) { }
  login_display = false;
  ngOnInit(): void {
  //   this.msalBroadcastService.msalSubject$
  //     .pipe(
  //       filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || 
  //       msg.eventType === EventType.HANDLE_REDIRECT_END)
  //     )
  //     .subscribe((result: EventMessage) => {

  //       if(result.eventType === EventType.HANDLE_REDIRECT_END){
  //         this.getProfile();
  //       }
  //     });

  //   this.msalBroadcastService.inProgress$
  //     .pipe(
  //       filter((status: InteractionStatus) => status === InteractionStatus.None),
  //       takeUntil(this._destroying$)
  //     )
  //     .subscribe(() => {
  //       this.login_display = this.authService.instance.getAllAccounts().length > 0;
  //     })
  // }

  // getProfile() {
  //   this.http.get('https://graph.microsoft.com/v1.0/me')
  //     .subscribe(profile => {
  //       this.profile = profile;
  //       this.router.navigateByUrl(CONFIG.PATH.PLANOGRAMS);
  //     });
  }

  getClaims(claims: any) {

    let list: Claim[]  =  new Array<Claim>();

    if(claims){
      Object.keys(claims).forEach(function(k, v){
      
        let c = new Claim()
        c.id = v;
        c.claim = k;
        c.value =  claims ? claims[k]: null;
        list.push(c);
      });
      
      console.log("list :: ", list)
    }

  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

}

export class Claim {
  id: number;
  claim: string;
  value: string;
}
