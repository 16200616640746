import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {getHeaders, handleError, handleResponse, handleUniqueNameCheckApiResponse} from './service-utilities';
import { CONFIG } from 'src/helper/app-config';
import { GetPlanogramBodyParams, PlanogramBaseDetailModal } from 'src/modals/planogram';

@Injectable({
  providedIn: 'root',
})
export class PlanogramService {
  constructor(private http: HttpClient) { }

  getSKUsList(planogram_id: number, _filter:string, _scenario:string) {
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.SKUGETALL, { "planogram_id": planogram_id}, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
    // "demo_type":"demo_scenario_1"
    // return this.http.post(environment.api_base_url + CONFIG.API.SKUGETALL, { "planogram_id": planogram_id, "filter_basedon":filter, "type": scenario}, options).pipe(
    //   map(handleResponse),
    //   catchError(handleError)
    // );
  }

  getPicosSKUsList(planogram_id: number, _filter:string, _scenario:string) {
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.GETPICOSSKUS, { "planogram_id": planogram_id}, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }


  getSpecificSku(id:number){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };

    return this.http.post(environment.api_base_url + CONFIG.API.GETSPECIFICSKU, {"id":id}, options).pipe(
       map(handleResponse), 
        catchError(handleError)
    );
  }

  getSpecificPlanogram(id:number){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };

    return this.http.post(environment.api_base_url + CONFIG.API.GETSPECIFICPLANOGRAM, {"id":id}, options).pipe(
       map(handleResponse), 
        catchError(handleError)
    );
  }

  verifyPlanogramuniqueName(name: string){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.CHECKUNIQUEPLANOGRAM, {"planogram_name": name}, options).pipe(
      map(handleUniqueNameCheckApiResponse),
      catchError(handleError)
    );
  }

  getbasedata(){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.get(environment.api_base_url + CONFIG.API.GETPLANOGRAMBASEDATA, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  getAllPlanograms(body_params: GetPlanogramBodyParams){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.GETPLANOGRAMS, JSON.stringify(body_params) , options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  savePlanogramBaseDetails(body_params : PlanogramBaseDetailModal){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.SAVEPLANAGRAM, JSON.stringify(body_params), options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  editPlanogramBaseDetails(body_params : PlanogramBaseDetailModal){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.EDITPLANAGRAM, JSON.stringify(body_params), options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  deletePlanogram(id:number){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.DELETEPLANAGRAM, {"planogram_id":id}, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  getPlanogramSavedData(id:number){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.GETPLANOGRAMSAVEDBASEDATA, {"id":id}, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  getCoolerTemplateBaseData(id:number){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.COOLERTEMPLATEBASEDATA, {"planogram_id":id} , options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  getSpecificScreenData(keys:string[]){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.SEPECIFICSCREENCONFIG, {"key": keys}, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  verifyTemplateUniqueName(name: string){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.CHECKUNIQUETEMPLATE, {"template_name": name}, options).pipe(
      map(handleUniqueNameCheckApiResponse),
      catchError(handleError)
    );
  }

  saveCoolerTemplate(info: any){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.SAVETEMPLATE, JSON.stringify(info), options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  editCoolerTemplate(info: any){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.EDITTEMPLATE, JSON.stringify(info), options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  getSavedCoolerTemplateData(template_id: number){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.GETSAVEDTEMPLATEDATA, {"id": template_id}, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  getAllTemplates(id:number){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.GETALLTEMPLATES, {"planogram_id":id} , options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  saveTemplateToPlanogram(info:any){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.SAVETEMPLATETOPLANOGRAM, JSON.stringify(info), options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  editCoolerFacing(info:any){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.EDITCOOLERFACING, JSON.stringify(info), options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  savePlanogramSkuPalcement(info:any){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.SAVEPLANOGRAMSKUPLACEMENT, JSON.stringify(info), options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  publishPlanogram(info:any){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.PUBLISHPLANOGRAM, JSON.stringify(info), options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  comparePlanogram(ids:number[]){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.COMPAREPLANOGRAM, {"planogram_ids":ids}, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  autoFillSKUS(planogram_id:number){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    }; 
    return this.http.post(environment.api_base_url + CONFIG.API.AUTOFILLSKUS, {"planogram_id":planogram_id}, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  getRecommandations(id:number){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.RECOMMANDATIONS, {"planogram_id":id}, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  getAdditionalInfo(planogram_id:number){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.ADDITIONALINFO, {"planogram_id": planogram_id} , options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  calculateThroughput(info:any){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.CALCULATETHROUGHPUT, JSON.stringify(info) , options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  verticalAutoFillSKUS(planogram_id:number, block_config:any){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    }; 
    return this.http.post(environment.api_base_url + CONFIG.API.VERTICALAUTOFILL, {"planogram_id":planogram_id, "vertical_brand_association":block_config}, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

  copyPlanogram(planogram_id:number, planogram_name:string){
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    }; 
    return this.http.post(environment.api_base_url + CONFIG.API.COPYPLANOGRAM, {"planogram_id":planogram_id, "planogram_name":planogram_name}, options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }

}

