import { CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { bottom } from '@popperjs/core';
import { Subscription, Observable } from 'rxjs';
import { CONFIG } from 'src/helper/app-config';
import { getFilledWidth, shelfHeight } from 'src/helper/app-utils';
import { PlanogramModal, ShelfModal, SKUModal } from 'src/modals/planogram';
import { PlanogramStateService } from 'src/services/planogram-state.service';
import { ConfirmDialogModalComponent } from '../confirm-dialog-modal/confirm-dialog-modal.component';
import { SkuInfoDialogComponent } from '../sku-info-dialog/sku-info-dialog.component';
import { SkuReplaceDialogComponent } from '../sku-replace-dialog/sku-replace-dialog.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-planogram-component',
  templateUrl: './planogram-component.component.html',
  styleUrls: ['./planogram-component.component.css'],
})
export class PlanogramComponentComponent implements OnInit, OnChanges {

  constructor(private planogramState: PlanogramStateService, public dialog: MatDialog) { }

  @Input() drop_item_ids: string[] = [];
  @Input() placed_sku_list: SKUModal[];
  @Input() cooler_config: PlanogramModal;
  @Input() add_sku_click_event?: Observable<any>;
  @Input() drag_drop_allowed: boolean = false;
  @Input() is_update_highlight_sku_placeholder: boolean = false;
  @Input() vertical_cooler_orientation: boolean;
  @Input() vertical_brand_association: any = {};
  @Input() dim_benchmark_clearance: number = 0;

  @Output() skuPlacementFlagEventEmitter = new EventEmitter()


  @Output() newDropItemIdEvent = new EventEmitter<{ id: string, row_no: number, position: number }>();

  //sas='?'+environment.sas;
  sas="";
  highlighted_placeholder_skus: string[] = [];
  selected_skus: string[] = [];

  sku_click_events_subscription?: Subscription;

  @ViewChild('tbl', { read: ElementRef }) child: ElementRef;

  current_drag_element_id: any;
  current_dragged_element: any;
  CONFIG = CONFIG;


  ngOnInit() {
    this.sku_click_events_subscription = this.add_sku_click_event?.subscribe((sku) => {
      this.addMultipleSkuToCooler(sku);
    });
  }

  ngOnDestroy() {
    this.sku_click_events_subscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['placed_sku_list'] && this.is_update_highlight_sku_placeholder) {
      if (this.drag_drop_allowed) {
        if (this.placed_sku_list.length === 0) {
          this.highlighted_placeholder_skus = ['1_1_1'];
        } else {
          this.highlighted_placeholder_skus = this.findFirstEmptyPlaceholderPosition(this.placed_sku_list);
        }
        setTimeout(() => {
          this.planogramState.updateHighlightSkuPlaceholder(false);
        }, 0);
      }
    }
  }

  skuWarningMessage = (ic_shelf_error: boolean, fc_shelf_error: boolean) => {
    let msg = CONFIG.SKU_PLACEMENT_WARNING.FCICSKUERRORMESSAGE;
    if (ic_shelf_error && !fc_shelf_error) {
      msg = CONFIG.SKU_PLACEMENT_WARNING.ICSKUERRORMESSAGE
    } else if (!ic_shelf_error && fc_shelf_error) {
      msg = CONFIG.SKU_PLACEMENT_WARNING.FCSKUERRORMESSAGE
    }
    return msg;
  }

  findFirstEmptyPlaceholderPosition = (placed_sku_list: SKUModal[]) => {
    let sku_pos;
    this.cooler_config.shelves.every(shelf=>{
      let no_of_facings = shelf.no_of_facings * (this.cooler_config.no_of_doors);
      return Array(no_of_facings).fill(0).map((_x, i) => i+1).every(pos=>{
        let index = placed_sku_list.findIndex(s => (s.position === pos && s.shelf === shelf.cooler_shelf));
        if (index === -1) {
          sku_pos = shelf.cooler_shelf + '_' + pos + '_' + Math.ceil(pos / shelf.no_of_facings);
        }
        return index !== -1
      })
    })

    if (sku_pos) {
      return [sku_pos]
    } else {
      return []
    }
  }

  addMultipleSkuToCooler = (sku: SKUModal) => {
    let placed_sku_list = this.placed_sku_list.slice();
    let show_warning: boolean = false;
    let allowed_brands: string[] = [];
    let ic_shelf_error = false, fc_shelf_error = false, is_vertical_error = false, unsufficient_space = false;
    this.highlighted_placeholder_skus.forEach(placeholder_sku => {
      let shelf = Number(placeholder_sku.split('_')[0]);
      let is_right_shelf = this.isSkuInRightShelf(sku, this.cooler_config.shelves[shelf - 1]);
      if (!is_right_shelf.right_shelf) {
        show_warning = true;
        if (is_right_shelf.ic_shelf_error) {
          ic_shelf_error = is_right_shelf.ic_shelf_error
        } else {
          fc_shelf_error = !is_right_shelf.ic_shelf_error
        }
      }
      let vertical_block_validation_res = this.isFacingInsideInvalidBlock(shelf, Number(placeholder_sku.split('_')[1]), sku.brand!)
      if (this.vertical_cooler_orientation && vertical_block_validation_res.is_invalid) {
        show_warning = true;
        is_vertical_error = true;
        allowed_brands = allowed_brands.concat(vertical_block_validation_res.valid_brands);
      }

      if (!this.isSufficientSpaceAvailableforSkuPlacement(placed_sku_list, shelf, Number(placeholder_sku.split('_')[1]), sku.sku_diameter_length)) {
        show_warning = true;
        unsufficient_space = true;
      }

      if (!show_warning) {
        placed_sku_list.push({
          shelf: shelf, position: Number(placeholder_sku.split('_')[1]),
          door: Number(placeholder_sku.split('_')[2]),
          sku_diameter_length: sku.sku_diameter_length,
          sku_id: sku.sku_id,
          sku_name: sku.sku_name,
          sku_height: sku.sku_height,
          sku_volume: sku.sku_volume,
          sku_img_link: sku.sku_img_link
        });
      }

    })

    if (show_warning) {
      this.showAddSKUWarning(unsufficient_space, { ic_shelf_error, fc_shelf_error, is_vertical_error }, allowed_brands, { shelf: Number(this.highlighted_placeholder_skus[0].split('_')[0]), position: Number(this.highlighted_placeholder_skus[0].split('_')[1]) }, this.highlighted_placeholder_skus.length > 1, false)
    }

    this.planogramState.updateSkuPlacing(placed_sku_list);
    this.highlighted_placeholder_skus = this.findFirstEmptyPlaceholderPosition(placed_sku_list);
    this.skuPlacementFlagEventEmitter.emit();
  }

  removeSku = (_sku: SKUModal) => {
    let placed_sku_list = this.placed_sku_list.slice()
    this.selected_skus.forEach(sku => {
      let index = placed_sku_list.findIndex(sk => (sk.shelf === Number(sku.split('_')[0]) && sk.position === Number(sku.split('_')[1])));
      if (index !== -1) {
        placed_sku_list.splice(index, 1);
      }
    })
    this.planogramState.updateSkuPlacing(placed_sku_list);
    this.selected_skus = [];
    this.highlighted_placeholder_skus = this.findFirstEmptyPlaceholderPosition(placed_sku_list);
    this.skuPlacementFlagEventEmitter.emit();
  }

  shelfHeight = (row_index: number) => {
    return shelfHeight(row_index, this.cooler_config);
  }

  coolerAvailableHeight = () => {
    let height = 0;
    if (this.cooler_config) {
      let shelfs = this.cooler_config.shelves.length;
      for (let index = 1; index <= shelfs; index++) {
        height += this.cooler_config.ic_shelves.includes(index) ? (this.cooler_config.max_sku_height_ic + CONFIG.SHELF_HEIGHT_CLEARANCE) : (this.cooler_config.max_sku_height_fc + CONFIG.SHELF_HEIGHT_CLEARANCE)
      }
      height = this.cooler_config.cooler_height_calc - height;
    }
    return height;
  }

  coolerShelfPadding = () => {
    if (this.cooler_config) {
      let height = this.coolerAvailableHeight();
      let shelfs = this.cooler_config.shelves.length;
      return (height / shelfs) * CONFIG.MMTOPIXCEL / CONFIG.HEIGHTCONSTANT;
    }
    return 0;
  }

  getPlaceholderWidth = (row_index: number) => {
    if (this.cooler_config.ic_shelves && !this.cooler_config.ic_shelves.includes(row_index)) {
      return this.cooler_config.avg_sku_width_fc;
    }
    return this.cooler_config.avg_sku_width_ic;
  }

  getPlaceholderHeight = (row_index: number) => {
    let placeholder_height = this.cooler_config.max_sku_height_ic;
    if (this.cooler_config.ic_shelves && !this.cooler_config.ic_shelves.includes(row_index)) {
      placeholder_height = this.cooler_config.max_sku_height_fc;
    }
    return placeholder_height - CONFIG.PLACEHOLDER_HEIGHT_CLEARANCE;
  }

  maxPossibleSKUs = (_cooler_row_width: number, row_index: number) => {
    let facings: number = 0;

    if (this.cooler_config.shelves[row_index - 1].no_of_facings !== undefined) {
      facings = this.cooler_config.shelves[row_index - 1].no_of_facings;
    }
    return Array(facings).fill(0).map((_x, i) => i)
  }

  possibleSkuCount = (cooler_row_width: number, row_index: number) => {
    let skus_placed_on_row = this.placed_sku_list.filter(sku => sku.shelf === row_index);
    let filled_width = getFilledWidth(skus_placed_on_row);
    let available_width = Math.floor(cooler_row_width - filled_width);
    return Math.floor(available_width / this.cooler_config.avg_sku_width_ic) + skus_placed_on_row.length;
  }

  getPlacedSKUIndex = (row_index: number, index: number) => {
    return this.placed_sku_list.findIndex(sk => sk.shelf === row_index && sk.position === index)
  }

  getSKU = (row_index: number, index: number) => {
    return this.placed_sku_list.find(sk => sk.shelf === row_index && sk.position === index)
  }

  isSufficientSpaceAvailableforSkuPlacement(placed_sku_list: SKUModal[], row_index: number, position: number, width: number) {
    let shelf_facing = this.cooler_config.shelves[row_index - 1].no_of_facings;

    let skus_placed_on_row = placed_sku_list.filter(sku => sku.shelf === row_index && Math.ceil(sku.position! / shelf_facing) === Math.ceil(position / shelf_facing));
    let filled_width = getFilledWidth(skus_placed_on_row);
    let available_width = (this.cooler_config.cooler_width_calc * (1 - this.dim_benchmark_clearance)) - filled_width;
    return available_width - width > 0;
  }

  getSkuInfo = (drop_data: any) => {
    return drop_data.inside_drop ? drop_data.sku : drop_data;
  }

  handleSKUDrop(event: any, row_index: number, position: number, door: number) {
    if (event.isPointerOverContainer) {
      let placed_sku_list = this.placed_sku_list.slice();
      let sku = this.getSkuInfo(event.item.data);
      let width = sku.sku_diameter_length;

      let new_sku: SKUModal, allowed_brands: string[] = [];

      let show_warning = false, ic_shelf_error = false, fc_shelf_error = false, is_vertical_error = false, unsufficient_space = false;
      let is_right_shelf = this.isSkuInRightShelf(sku, this.cooler_config.shelves[row_index - 1]);
      if (!is_right_shelf.right_shelf) {
        show_warning = true;
        ic_shelf_error = is_right_shelf.ic_shelf_error
        fc_shelf_error = !is_right_shelf.ic_shelf_error
      }
      let vertical_block_validation_res = this.isFacingInsideInvalidBlock(row_index, position, sku.brand!)

      if (this.vertical_cooler_orientation && vertical_block_validation_res.is_invalid) {
        show_warning = true;
        is_vertical_error = true;
        allowed_brands = allowed_brands.concat(vertical_block_validation_res.valid_brands);
      }

      if (!this.isSufficientSpaceAvailableforSkuPlacement(placed_sku_list, row_index, position, width)) {
        show_warning = true;
        unsufficient_space = true;
      }

      if (show_warning) {
        this.showAddSKUWarning(unsufficient_space, { ic_shelf_error, fc_shelf_error, is_vertical_error }, allowed_brands, { shelf: row_index, position: position }, false, false)
      }
      else {
        if (event.item.data.inside_drop) {
          placed_sku_list = this.deleteSKU(placed_sku_list, sku.shelf, sku.position)
        }

        new_sku = {
          shelf: row_index, position: position, door: door,
          sku_diameter_length: sku.sku_diameter_length,
          sku_id: sku.sku_id,
          sku_name: sku.sku_name,
          sku_height: sku.sku_height,
          sku_volume: sku.sku_volume,
          sku_img_link: sku.sku_img_link
        };

        placed_sku_list = placed_sku_list.concat(new_sku);
        this.highlighted_placeholder_skus = this.findFirstEmptyPlaceholderPosition(placed_sku_list);
        this.planogramState.updateSkuPlacing(placed_sku_list);
        this.skuPlacementFlagEventEmitter.emit();
      }
    }
  }

  deleteSKU = (placed_sku_list: SKUModal[], sku_shelf: number, sku_position: number) => {
    let index = this.findSKUIndex(placed_sku_list, sku_shelf, sku_position);
    if (index !== -1) {
      placed_sku_list.splice(index, 1);
    }
    return placed_sku_list;
  }

  findSKUIndex = (placed_sku_list: SKUModal[], sku_shelf: number, sku_position: number) => {
    return placed_sku_list.findIndex(sk => (sk.shelf === sku_shelf && sk.position === sku_position))
  }

  showAddSKUWarning = (unsufficient_space: boolean, error: { ic_shelf_error: boolean, fc_shelf_error: boolean, is_vertical_error: boolean }, allowed_brands: string[], sku_position: { shelf: number, position: number }, is_multiple: boolean, is_replace: boolean) => {
    let msg: string[] = [];
    if (unsufficient_space) {
      msg = msg.concat(CONFIG.SKU_PLACEMENT_WARNING.SKU_DROP_ERROR_MSG);
    }
    if (error.ic_shelf_error || error.fc_shelf_error) {
      let err_msg = this.skuWarningMessage(error.ic_shelf_error, error.fc_shelf_error)
      if (is_replace) {
        err_msg = err_msg.replace('added', 'replaced');
      }
      msg = msg.concat(err_msg);
    }
    if (error.is_vertical_error) {
      let facing_type = (this.cooler_config.ic_shelves.includes(sku_position.shelf) ? 'IC' : 'FC') + " facing "
      let vertical_error_msg = is_multiple ? "Only reserved brand SKU's is placed on a particular facing." : "Only " + allowed_brands.join(' , ') + " brand allowed to " + facing_type + sku_position.position;
      msg = msg.concat(vertical_error_msg);
    }
    this.showWarning(msg)
  }

  showWarning(msg: string[]) {
    this.dialog.open(ConfirmDialogModalComponent, {
      // height: '200px',
      width: '500px',
      data: {
        header_msg: msg.length > 1 ? 'Following error occured while placing SKU' : '',
        header_msg_description: msg,
        ok_action: 'Continue'
      },
    });
  }

  showSKUInformation(sku: SKUModal) {
    this.dialog.open(SkuInfoDialogComponent, {
      position: { right: '0', top: '0', bottom: '0' },
      height: '100%', width: '400px',
      data: { sku_id: sku.sku_id }
    });
  }

  openSkuReplaceDialog = () => {
    const dialogRef = this.dialog.open(SkuReplaceDialogComponent, {
      height: '800px',
      width: '400px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let placed_sku_list = this.placed_sku_list.slice()
        let show_warning: boolean = false;
        let ic_shelf_error = false, fc_shelf_error = false, is_vertical_error = false, allowed_brands: string[] = [], unsufficient_space = false;
        this.selected_skus.forEach(sku => {
          let shelf = Number(sku.split('_')[0]);
          let index = this.findSKUIndex(placed_sku_list, shelf, Number(sku.split('_')[1]));
          if (index !== -1) {
            let replace_sku_res = this.replaceSKU(result, placed_sku_list, shelf, index, sku, {show_warning, unsufficient_space, ic_shelf_error, fc_shelf_error, is_vertical_error}, allowed_brands);
            allowed_brands = replace_sku_res.allowed_brands;
            show_warning = replace_sku_res.show_warning;
            ic_shelf_error = replace_sku_res.ic_shelf_error;
            fc_shelf_error = replace_sku_res.fc_shelf_error;
            is_vertical_error = replace_sku_res.is_vertical_error;
            unsufficient_space = replace_sku_res.unsufficient_space;
           
          }
        })

        if (show_warning) {
          this.showAddSKUWarning(unsufficient_space, { ic_shelf_error, fc_shelf_error, is_vertical_error }, allowed_brands, { shelf: Number(this.selected_skus[0].split('_')[0]), position: Number(this.selected_skus[0].split('_')[1]) }, this.selected_skus.length > 1, true)
        }
        this.planogramState.updateSkuPlacing(placed_sku_list);
        this.selected_skus = [];
        this.skuPlacementFlagEventEmitter.emit();
      }
    });
  }

  replaceSKU = (result:any, placed_sku_list: SKUModal[], shelf: number, index: number, sku: string , error:{show_warning: boolean, unsufficient_space: boolean, ic_shelf_error: boolean, fc_shelf_error: boolean, is_vertical_error: boolean}, allowed_brands: string[] )=>{
    let is_valid_sku : boolean = true;
    if (!this.isSufficientSpaceAvailableforSkuPlacement(placed_sku_list, shelf, Number(sku.split('_')[1]), result.sku_diameter_length - placed_sku_list[index].sku_diameter_length)) {
      error.show_warning = true;
      error.unsufficient_space = true;
      is_valid_sku = false;
    }

    let is_right_shelf = this.isSkuInRightShelf(result, this.cooler_config.shelves[shelf - 1]);
    if (!is_right_shelf.right_shelf) {
      error.show_warning = true;
      is_valid_sku = false;
      if (is_right_shelf.ic_shelf_error) {
        error.ic_shelf_error = is_right_shelf.ic_shelf_error
      } else {
        error.fc_shelf_error = !is_right_shelf.ic_shelf_error
      }
    }

    let vertical_block_validation_res = this.isFacingInsideInvalidBlock(placed_sku_list[index].shelf!, placed_sku_list[index].position!, result.brand!)

    if (this.vertical_cooler_orientation && vertical_block_validation_res.is_invalid) {
      error.show_warning = true;
      error.is_vertical_error = true;
      is_valid_sku = false;
      allowed_brands = allowed_brands.concat(vertical_block_validation_res.valid_brands);
    }

    if (is_valid_sku) {
      placed_sku_list[index] = {
        ...placed_sku_list[index],
        ...{
          sku_diameter_length: result.sku_diameter_length,
          sku_id: result.sku_id,
          sku_name: result.sku_name,
          sku_height: result.sku_height,
          sku_volume: result.sku_volume,
          sku_img_link: result.sku_img_link
        }
      }
    }

    return {...error, allowed_brands};
  }

  isSkuInRightShelf = (sku: SKUModal, shelf: ShelfModal) => {
    let is_right_shelf = true;
    let ic_shelf_error = false;
    if (shelf.ic_shelf_flag !== undefined) {
      // console.log("sku :: ",sku)
      is_right_shelf = (shelf.ic_shelf_flag && sku.sku_volume <= CONFIG.ICSHELFMAXVOLUME) || (!shelf.ic_shelf_flag && sku.sku_volume > CONFIG.ICSHELFMAXVOLUME)
      ic_shelf_error = shelf.ic_shelf_flag && sku.sku_volume > CONFIG.ICSHELFMAXVOLUME
    }
    return { right_shelf: is_right_shelf, ic_shelf_error: ic_shelf_error };
  }

  hightlightSkuPlaceholder(event: any, row_index: number, placeholder_position: number, door: number) {
    if (event.shiftKey) {
      this.highlighted_placeholder_skus = this.highlighted_placeholder_skus.concat([row_index + "_" + placeholder_position + "_" + door])
    } else {
      this.highlighted_placeholder_skus = [row_index + "_" + placeholder_position + "_" + door]
    }
  }

  selectSkus(event: any, row_index: number, placeholder_position: number, door: number) {
    if (event.shiftKey) {
      console.log("shift key pressed...")
      this.selected_skus = this.selected_skus.concat([row_index + "_" + placeholder_position + "_" + door])
    } else {
      this.selected_skus = [row_index + "_" + placeholder_position + "_" + door]
    }
  }

  addDropId(row_no: number, position: number) {
    let id = 'placeholder_row_' + row_no + '_position_' + position;
    this.newDropItemIdEvent.emit({ id, row_no, position });
    return id;
  }

  counter = (number: number) => {
    return Array(number).fill(0).map((_x, i) => i);
  }

  dragStart(event: CdkDragStart) {
    let id = 'placed_sku_' + event.source.data.sku.shelf + '_' + event.source.data.sku.position;
    this.current_drag_element_id = id;
    let target_elem = document.getElementById(id);
    this.current_dragged_element = target_elem?.firstElementChild;
  }

  moved(_event: CdkDragMove) {
    // Check if stored HTML field is as same as current field
    let target_elem = document.getElementById(this.current_drag_element_id);
    if ((target_elem?.children && target_elem?.firstElementChild !== this.current_dragged_element) || target_elem?.childElementCount === 0) {
      target_elem.appendChild(this.current_dragged_element)
    }
  }

  isFacingInsideInvalidBlock = (shelf: number, position: number, brand: string) => {
    let is_invalid = false;
    let is_ic = this.cooler_config.ic_shelves.includes(shelf);
    let associatted_brands: string[] = [];
    if (brand && position) {
      associatted_brands = this.getVerticalBlockFacingBrands(position, is_ic)
      if (associatted_brands.length > 0) {
        is_invalid = !associatted_brands.includes(brand)
      }
    }

    return { is_invalid: is_invalid, valid_brands: associatted_brands };
  }

  getVerticalBlockFacingBrands = (position: number, is_ic: boolean) => {
    let brands: string[] = [], facing_count = 0;
    Object.values(this.vertical_brand_association).every((block: any, _index) => {
      if (is_ic) {
        facing_count += block.no_of_facings;
      } else {
        facing_count += block.no_of_facings == 1 ? 1 : Math.round((block.no_of_facings * this.cooler_config.avg_sku_width_ic) / this.cooler_config.avg_sku_width_fc);
      }
      if (position <= facing_count) {
        brands = block.brand;
        return false;
      }
      return true
    })
    return brands;
  }

  getDoorClass = () => {
    let cls_name = ''

    if (this.cooler_config && this.cooler_config.no_of_doors && this.cooler_config.traffic_direction) {
      switch (this.cooler_config.no_of_doors) {
        case 3:
          cls_name = this.cooler_config.traffic_direction === CONFIG.COOLERTRAFFICDIRECTIONS.DOOR3[0] ? 'left-center-door' : 'right-center-door'
          break;

        case 2:
          cls_name = 'center-door'
          break;

        default:
          cls_name = this.cooler_config.traffic_direction === CONFIG.COOLERTRAFFICDIRECTIONS.DOOR1[0] ? 'left-door' : 'right-door'
          break;
      }
    }

    return cls_name;
  }

  isHighImactFacing = (cooler_shelf: number, shelf_facings:number , position:number, door: number)=>{

    let is_high_impact_facing = false;
    if(this.cooler_config.high_impact_config && Object.keys(this.cooler_config.high_impact_config).includes(String(cooler_shelf))){
      let is_left = this.getDoorDirection(door)
      // console.log("high_impact_config :: ", this.cooler_config.high_impact_config)
      // console.log(" shelf :: ", cooler_shelf, " position :: ", position, " door :: ", door, " is_left ", is_left, "this.cooler_config.high_impact_config[cooler_shelf] :: ",this.cooler_config.high_impact_config[cooler_shelf] )
      position = !is_left ? shelf_facings - position + 1 : position
      is_high_impact_facing = this.cooler_config.high_impact_config[cooler_shelf] >= position
    }
    return is_high_impact_facing;
  }

  getDoorDirection = (door: number)=>{
    let is_left = true;
    if(door === 1){
      is_left = this.cooler_config.traffic_direction === CONFIG.COOLERTRAFFICDIRECTIONS.DOOR1[0];
    }else if(door === 2){
      is_left = this.cooler_config.traffic_direction !== CONFIG.COOLERTRAFFICDIRECTIONS.DOOR3[1]
    }
    return is_left;
  }

  // isSKUOverlaying = (cooler_shelf: number, position:number, door: number, sku_width: number)=>{
  //   let is_overlay : boolean = false;
  //   if(this.cooler_config && this.cooler_config.shelves){
  //     let shelf_facing = this.cooler_config.shelves[cooler_shelf - 1].no_of_facings;
  //     if(shelf_facing){
  //     let skus_placed_on_row = this.placed_sku_list.filter(sku => sku.shelf === cooler_shelf && Math.ceil(sku.position! / shelf_facing) === Math.ceil(position / shelf_facing));
  //     let filled_width = getFilledWidth(skus_placed_on_row);
  //     let available_width = (this.cooler_config.cooler_width_calc * (1 - this.dim_benchmark_clearance)) - filled_width;
  //     is_overlay = available_width - sku_width >= sku_width;
  //     }
      
  //   }
  //   return is_overlay
  // }

}
