<ng-template #skudetaildialogtemplete let-sku="sku"
            [ngTemplateOutlet]="skudetaildialogtemplete"
            [ngTemplateOutletContext]="{sku: sku}">

<mat-dialog-content class="sku-dialog-content" style="text-align: center">
    <button mat-icon-button class="close-button" disableRipple [mat-dialog-close]="true">
        <mat-icon class="close-icon" >close</mat-icon>
    </button>

    <img alt="" [src]="sku.sku_img_link + sas" [ngStyle]="{'height.px': sku.sku_height*CONFIG.MMTOPIXCEL/CONFIG.HEIGHTCONSTANT , 
    'width.px': sku.sku_diameter_length*CONFIG.MMTOPIXCEL/CONFIG.WIDTHCONSTANT, 'border-radius':0,  margin:'20px 0'}" />

    <p>{{sku.name}}</p>

    <mat-grid-list cols="2" class="sku-info-list-grid" rowHeight="3:1">
        <mat-grid-tile>
            <div class="sku-info-header">Brand</div>
            <div class="sku-info">{{sku.brand}}</div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="sku-info-header">Category</div>
            <div class="sku-info">{{sku.category}}</div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="sku-info-header">Flavour</div>
            <div class="sku-info">{{sku.flavour}}</div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="sku-info-header">Cooling Required</div>
            <div class="sku-info">{{sku.cooling_required ? 'YES' : 'NO'}}</div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="sku-info-header">Pack Size</div>
            <div class="sku-info">{{sku.sku_volume}}ml</div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="sku-info-header">Dimensions</div>
            <div class="sku-info">{{sku.sku_diameter_length}} X {{sku.sku_height}} X {{sku.sku_thickness}} {{sku.dimension_unit}}</div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="sku-info-header">Pack Type</div>
            <div class="sku-info">{{sku.pack_type}}</div>
        </mat-grid-tile>
    </mat-grid-list>

    <!-- <mat-divider style="margin: 20px 0"></mat-divider>

    <mat-grid-list cols="2" class="sku-info-list-grid" rowHeight="3:1">
        <mat-grid-tile>
            <div class="sku-info-header">Price</div>
            <div class="sku-info">M-LC {{sku.price}}</div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="sku-info-header">Profit margin</div>
            <div class="sku-info">{{sku.profit_margin}}%</div>
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="sku-info-header">Sales Velocity</div>
            <div class="sku-info">{{sku.sales_velocity}}</div>
        </mat-grid-tile>
    </mat-grid-list>

    <mat-card class="drinking-moment-card" >
        <div class="sku-info-header" >Drinking Moments</div>
        {{sku.drinking_moments}}
    </mat-card> -->

</mat-dialog-content>
</ng-template>