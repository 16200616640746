import { Component, EventEmitter, Output } from '@angular/core';
import { CONFIG } from 'src/helper/app-config';

@Component({
  selector: 'app-nav-component',
  templateUrl: './nav-component.component.html',
  styleUrls: ['./nav-component.component.css']
})
export class NavComponentComponent {

  @Output() logoutEventEmitter = new EventEmitter();
  
  CONFIG = CONFIG
  tabs = [{name:'Planograms', pathname: CONFIG.PATH.PLANOGRAMS}];

  active_tab = this.tabs[0].name;

  handleActiveTab = (name: string)=>{
    this.active_tab = name;
  }

  // logout = ()=>{
  //   this.logoutEventEmitter.emit();
  // }

}
