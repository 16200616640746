import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, SubscriptionLike } from 'rxjs';
import { noWhitespaceValidator } from 'src/helper/app-utils';
import { PlanogramStateService } from 'src/services/planogram-state.service';

interface DialogData{
  planogram_id: number;
  planogram_name: string;
}

@Component({
  selector: 'app-copy-planogram-dialog',
  templateUrl: './copy-planogram-dialog.component.html',
  styleUrls: ['./copy-planogram-dialog.component.css']
})
export class CopyPlanogramDialogComponent implements OnInit {
  
  planogram_dialog_close_subscription: SubscriptionLike;

  planogram_name : FormControl = new FormControl('',[Validators.required, noWhitespaceValidator, Validators.pattern(/^[A-Za-z0-9_]+$/)]);
  planogram_name_error: Observable<string> = this.planogramState.planogram_name_error;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData,
  private dialogRef: MatDialogRef<CopyPlanogramDialogComponent>,
  private planogramState: PlanogramStateService) {
   }

  ngOnInit(): void {

    this.planogram_dialog_close_subscription = this.planogramState.close_copy_planogram_dialog.subscribe(is_close=>{
      if(is_close){
        this.dialogRef.close();
      }
    })
  }

  ngOnDestroy(){
    this.planogram_dialog_close_subscription.unsubscribe();
    this.planogramState.clearPlanogramNameError()
  }

  validatePlanogramname(){
    let planogram_name = this.planogram_name.value.trim();
    if (this.planogram_name.valid) {
      this.planogramState.verifyPlanogramUniqueName(planogram_name, this.data);
    }
  }

}
