<mat-dialog-content class="sku-replace-content">
    <h1 mat-dialog-title style="display: inline;">
        <em class="bi bi-arrow-repeat"></em>
        Replace By
    </h1>
    <button mat-icon-button class="close-button" disableRipple [mat-dialog-close]="false">
        <mat-icon class="close-icon">close</mat-icon>
    </button>

    <mat-form-field appearance="fill" class="sku-search-input">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput [(ngModel)]="sku_search_input" type="text" placeholder="Search">
        <mat-icon>sort</mat-icon>

    </mat-form-field>

    <mat-chip-list>
        <mat-chip disableRipple [ngClass]="sku_filter === 'all' ? 'selected-sku-filter-chip' : '' "
            (click)="filterSkus('all')">All</mat-chip>
        <mat-chip disableRipple [ngClass]="sku_filter === 'recommanded' ? 'selected-sku-filter-chip' : '' "
            (click)="filterSkus('recommanded')">Recommended</mat-chip>
        <mat-chip disableRipple [ngClass]="sku_filter === 'picos' ? 'selected-sku-filter-chip' : '' "
            (click)="filterSkus('picos')">PicOS based</mat-chip>
    </mat-chip-list>

    <div id="sku-cards-list">
        <mat-card class="no-padding"
            *ngFor="let sku of filter_sku_list | sortAndFilter :  sku_search_input.trim().toLowerCase() : 'sku_name'"
            (click)="selectSku(sku)">
            <img [src]='sku.sku_img_link + sas' alt="..." class="static-sku-dimension" [ngStyle]="{'height.px': sku.sku_height*CONFIG.MMTOPIXCEL/CONFIG.HEIGHTCONSTANT , 
                  'width.px': sku.sku_diameter_length*CONFIG.MMTOPIXCEL/CONFIG.WIDTHCONSTANT , 'border-radius':0}" />
            <div class="sku-name"> {{sku.sku_name}} </div>
            <p class="sku-volume">
                {{sku.sku_volume}} ml
            </p>

        </mat-card>
    </div>

</mat-dialog-content>