import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlanogramBuilderComponent } from '../components/planogram-builder/planogram-builder.component';

import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatListModule} from '@angular/material/list';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatChipsModule} from '@angular/material/chips';
import {MatTabsModule} from '@angular/material/tabs';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatBadgeModule} from '@angular/material/badge';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';

import { AngularSplitModule } from "angular-split";
import { NgChartsModule } from 'ng2-charts';


import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import {MatSortModule} from '@angular/material/sort';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { SkuComponentComponent } from '../components/sku-component/sku-component.component';
import { PlanogramComponentComponent } from '../components/planogram-component/planogram-component.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavComponentComponent } from '../components/nav-component/nav-component.component';
import { PlanogramListComponent } from '../components/planogram-list/planogram-list.component';
import { LoaderComponent } from '../components/loader/loader.component';
import { CoolerTemplateCreatorComponent } from '../components/cooler-template-creator/cooler-template-creator.component';
import { LoginComponent } from '../components/login/login.component';
import { SafePipe } from '../custom-pipes/safe.pipe';
import { AuthResponseComponent } from '../components/auth-response/auth-response.component';
import { SortAndFilterPipe } from 'src/custom-pipes/sort-and-filter-pipe';
import { environment } from 'src/environments/environment';
import { SnakbarMsgDisplayComponent } from 'src/components/snakbar-msg-display/snakbar-msg-display.component';
import { ConfirmDialogModalComponent } from '../components/confirm-dialog-modal/confirm-dialog-modal.component';
import { CreateCoolerTemplateDialogComponent } from '../components/create-cooler-template-dialog/create-cooler-template-dialog.component';
import { SetupPlanogramComponent } from '../components/setup-planogram/setup-planogram.component';
import { SkuInfoDialogComponent } from '../components/sku-info-dialog/sku-info-dialog.component';
import { SkuReplaceDialogComponent } from '../components/sku-replace-dialog/sku-replace-dialog.component';
import { PublishPlanogramComponent } from '../components/publish-planogram/publish-planogram.component';
import { ComparePlanogramComponent } from '../components/compare-planogram/compare-planogram.component';
import { ComparePlanogramDialogComponent } from '../components/compare-planogram-dialog/compare-planogram-dialog.component';
import { ComparePlanogramImagePreviewComponent } from '../components/compare-planogram-image-preview/compare-planogram-image-preview.component';
import { CopyPlanogramDialogComponent } from '../components/copy-planogram-dialog/copy-planogram-dialog.component';
import { AuthTokenInterceptor } from 'src/interceptor/auth-token-interceptor';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
console.log("isIE :: ", isIE)

@NgModule({
  declarations: [
    SafePipe,
    SortAndFilterPipe,
    AppComponent,
    PlanogramBuilderComponent,
    SkuComponentComponent,
    PlanogramComponentComponent,
    NavComponentComponent,
    PlanogramListComponent,
    LoaderComponent,
    CoolerTemplateCreatorComponent,
    LoginComponent,
    AuthResponseComponent,
    SnakbarMsgDisplayComponent,
    ConfirmDialogModalComponent,
    CreateCoolerTemplateDialogComponent,
    SetupPlanogramComponent,
    SkuInfoDialogComponent,
    SkuReplaceDialogComponent,
    PublishPlanogramComponent,
    ComparePlanogramComponent,
    ComparePlanogramDialogComponent,
    ComparePlanogramImagePreviewComponent,
    CopyPlanogramDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatIconModule,
    MatInputModule,
    DragDropModule,
    MatListModule,
    MatGridListModule,
    MatTooltipModule,
    MatChipsModule,
    MatTabsModule,
    MatStepperModule,
    MatButtonModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    MatDialogModule,
    MatMenuModule,
    MatBadgeModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    AngularSplitModule,
    NgChartsModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    MatSortModule,
    HttpClientModule,
  ],
   providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true
    }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
