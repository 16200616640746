<div id='planogram-builder-builder'>
    <mat-horizontal-stepper linear #mystepper id="planogram-stepper" [selectedIndex]="stepper_index"
        (selectionChange)="setStepperIndex($event, is_planogram_edit, edit_planogram_detail)">

        <ng-template matStepperIcon="edit" let-index="index">
            {{index + 1}}
        </ng-template>

        <mat-step [completed]="planogram_base_detail_form_group.valid" label="Setup Planogram"
            [stepControl]="planogram_base_detail_form_group">
            <div class="stepper-actions-container">
                <button mat-button [disabled]="mode=='edit'" (click)="handleDiscardClick(edit_planogram_detail.id)">Discard</button>
                <button mat-button
                    [disabled]="planogram_base_detail_form_group.invalid || (planogram_name_error | async)  !== ''"
                    (click)="draftPlanogramDetails(is_planogram_edit, edit_planogram_detail, 0)">Save
                    as draft</button>
                <button mat-raised-button style="background-color:#25D4CF"
                    (click)="savePlanogramBaseDetailsAndNavigate(is_planogram_edit, edit_planogram_detail, 0)"
                    [disabled]="planogram_base_detail_form_group.invalid || (planogram_name_error | async)  !== ''">Next</button>
            </div>

            <ng-template matStepContent>
                <em class="bi bi-arrow-left back-icon" (click)="handleDiscardClick(edit_planogram_detail.id,true)"></em>
                <div id="planogram-setup-form-group-container" *ngIf="stepper_index === 0">
                    <h1>Enter Planogram name, Channel and Business goals: </h1>
                    <div id="planogram-setup-form-group" [formGroup]="planogram_base_detail_form_group">
                        <div class="form-group">
                            <label for="planogram_name"
                                [ngClass]="{'text-danger' : (planogram_name_error | async)  !== ''}">Planogram Name
                                <span *ngIf="isRequiredField('planogram_name')" class="astrick-class"> *</span>
                            </label>
                            <div style="display: flex; align-items: center;">
                                <input type="text" class="form-control" id="planogramname"
                                    formControlName="planogram_name" placeholder="Enter Planogram Name" required
                                    style="min-width: 100%"
                                    [ngClass]="{'is-invalid' : (planogram_name_error | async)  !== ''}"
                                    (blur)="onBlurPlanogramName()">
                                <em class="bi" [ngClass]="{'bi-check-circle text-success': (planogram_base_detail_form_group.get('planogram_name')?.valid && (planogram_name_error | async)  === ''),
                                'bi-x-circle text-danger': (planogram_name_error | async)  !== ''}"
                                    style="margin: 0 5px;"></em>
                            </div>

                            <span *ngIf="(planogram_name_error | async)  !== ''" class="error-message">
                                {{planogram_name_error | async}} </span>
                            <span class="error-message" *ngIf="planogram_base_detail_form_group.controls['planogram_name'].errors?.['pattern']">
                                <span *ngIf="(planogram_name_error | async)">,</span>Special characters are not allowed.
                            </span>
                            <!-- Form starts -->
                            <!-- <span class="help-block" *ngIf="!SignupForm.get('userData.username').valid&&SignupForm.get('userData.username').touched">
                                <span *ngIf = "SignupForm.get('userData.username').errors['nameIsForbidden']">name is invalid</span>
                                <span *ngIf = "SignupForm.get('userData.username').errors['required']">field is required</span>
                            </span> -->
                        </div>

                        <div class="form-group mb-3">
                            <label for="storesegment">Channel
                                <span *ngIf="isRequiredField('channel')" class="astrick-class"> *</span>
                            </label>

                            <mat-select class="custom-select d-block w-100" placeholder="Select Channel"
                                formControlName="channel" disableOptionCentering
                                [ngClass]="{'disable-dropdown': !(planogram_base_details | async)!.channel || (planogram_base_details | async)!.channel.length === 0}"
                                [attr.disabled]="!(planogram_base_details | async)!.channel || (planogram_base_details | async)!.channel.length === 0"
                                (selectionChange)="handleChannelChange($event)">
                                <mat-option *ngFor="let channel of (planogram_base_details | async)!.channel"
                                    [value]="channel.id">
                                    <em [ngClass]="{'bi-check-circle-fill' : planogram_base_detail_form_group.controls['channel'].value === channel.id,
                                        'bi-circle' : planogram_base_detail_form_group.controls['channel'].value !== channel.id}"
                                        class="bi"></em>
                                    {{channel.value}}
                                </mat-option>
                            </mat-select>

                        </div>

                        <div class="form-group mb-3">
                            <label for="sub_channel">Sub-Channel
                                <span *ngIf="isRequiredField('sub_channel')" class="astrick-class"> *</span>
                            </label>
                            <!-- 
                                [ngClass]="{'error': submitted && myForm.address.controls.cityName.errors}"  
                                    <option *ngFor="let city of City" [ngValue]="city">{{city}}</option> -->
                            <ng-template #subchanneltemp let-subchannellist="subchannellist" [ngTemplateOutlet]="subchanneltemp"
                                [ngTemplateOutletContext]="{subchannellist: (planogram_base_details | async)!.sub_channel | sortAndFilter :  planogram_base_detail_form_group.controls['channel'].value : 'channel'}" >
                            <mat-select class="custom-select d-block w-100" placeholder="Select Sub-Channel"
                                formControlName="sub_channel" disableOptionCentering
                                (selectionChange)="this.clearSelectionOnValueChange($event, 'segment')"
                                [ngClass]="{'disable-dropdown': !(planogram_base_details | async)!.sub_channel || subchannellist.length === 0}"
                                [attr.disabled]="!(planogram_base_details | async)!.sub_channel || subchannellist.length === 0">
                                <mat-option *ngFor="let sub_channel of getUniqueValues(subchannellist, 'value')"
                                    [value]="sub_channel.id">
                                    <em [ngClass]="{'bi-check-circle-fill' : planogram_base_detail_form_group.controls['sub_channel'].value === sub_channel.id,
                                        'bi-circle' : planogram_base_detail_form_group.controls['sub_channel'].value !== sub_channel.id}"
                                        class="bi"></em>
                                    {{sub_channel.value}}
                                </mat-option>
                            </mat-select>
                            </ng-template>
                        </div>

                        <div class="form-group mb-3">
                            <label for="segment">Segment
                                <span *ngIf="isRequiredField('segment')" class="astrick-class"> *</span>
                            </label>
                            <!-- [compareWith]="compareOccasionSelectedObjects"  -->
                            <ng-template #segmenttemp let-segmentlist="segment_list" [ngTemplateOutlet]="segmenttemp"
                                    [ngTemplateOutletContext]="{segment_list: (planogram_base_details | async)!.segment | sortAndFilter :  planogram_base_detail_form_group.controls['sub_channel'].value : 'sub_channel'}" >
                            <mat-select class="custom-select d-block w-100" placeholder="Select Segment"
                                formControlName="segment"  disableOptionCentering
                                value="planogram_base_detail_form_group.controls['segment'].value.value"
                                [ngClass]="{'disable-dropdown': !(planogram_base_details | async)!.segment || segmentlist.length === 0}"
                                [attr.disabled]="!(planogram_base_details | async)!.segment || segmentlist.length === 0">
                                <!-- <mat-select-trigger>
                                    {{planogram_base_detail_form_group.controls['segment'].value.value}}
                                </mat-select-trigger> -->

                                <!-- <mat-optgroup *ngFor="let segment of (planogram_segment | async)"
                                    [label]="segment.id"> -->
                                    <mat-option *ngFor="let segment of getUniqueValues(segmentlist, 'value')" [value]="segment.id">
                                        <em [ngClass]="{'bi-check-circle-fill' : planogram_base_detail_form_group.controls['segment'].value.id === segment.id,
                                        'bi-circle' : planogram_base_detail_form_group.controls['segment'].value.id !== segment.id}"
                                            class="bi"></em>
                                        {{segment.value}}
                                    </mat-option>
                                <!-- </mat-optgroup> -->
                            </mat-select>
                            </ng-template>
                        </div>

                        <div class="form-group mb-3">
                            <label>Portfolio Selection
                                <span *ngIf="isRequiredField('portfolio_selection')" class="astrick-class"> *</span>
                            </label>
                            <mat-select class="custom-select d-block w-100" placeholder="Select Portfolio Selection"
                                formControlName="portfolio_selection" multiple disableOptionCentering
                                panelClass="multiple-select-panel-class" [ngClass]="{'disable-dropdown': !(planogram_base_details | async)!.portfolio_selection || (planogram_base_details | async)!.portfolio_selection.length === 0}"
                                [attr.disabled]="!(planogram_base_details | async)!.portfolio_selection || (planogram_base_details | async)!.portfolio_selection.length === 0">
                                <mat-option *ngFor="let portfolio_selection of (planogram_base_details | async)!.portfolio_selection"
                                    [value]="portfolio_selection.id">{{portfolio_selection.value}}</mat-option>
                            </mat-select>
                        </div>

                        <div class="form-group mb-3">
                            <label>Business Goals
                                <span *ngIf="isRequiredField('business_goals')" class="astrick-class"> *</span>
                            </label>
                            <mat-select class="custom-select d-block w-100" placeholder="Select Business Goals"
                                formControlName="business_goals" multiple disableOptionCentering
                                panelClass="multiple-select-panel-class" [ngClass]="{'disable-dropdown': !(planogram_base_details | async)!.business_goals || (planogram_base_details | async)!.business_goals.length === 0}"
                                [attr.disabled]="!(planogram_base_details | async)!.business_goals || (planogram_base_details | async)!.business_goals.length === 0">
                                <mat-option *ngFor="let business_goal of (planogram_base_details | async)!.business_goals"
                                    [value]="business_goal.id">{{business_goal.value}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </ng-template>

        </mat-step>
        <mat-step [completed]="cooler_template_form_group.valid" label="Select Cooler"
            [stepControl]="cooler_template_form_group">
            <div class="stepper-actions-container">
                <button mat-button [disabled]="mode=='edit'" (click)="handleDiscardClick(edit_planogram_detail.id)">Discard</button>
                <button mat-button [disabled]="cooler_template_form_group.invalid || create_new_template"
                    (click)="draftPlanogramDetails(is_planogram_edit, edit_planogram_detail, 1)">Save as
                    draft</button>
                <button mat-raised-button style="background-color:#25D4CF" [disabled]="cooler_template_form_group.invalid || create_new_template"
                    (click)="savePlanogramBaseDetailsAndNavigate(is_planogram_edit, edit_planogram_detail, 1)">Next</button>
            </div>
            <ng-template matStepContent>
                <em *ngIf="!create_new_template" class="bi bi-arrow-left back-icon" (click)="goToPreviousStep(0); mystepper.previous()"></em>
                <div id="cooler-template-container" *ngIf="stepper_index === 1">
                    <div *ngIf="create_new_template; then thenBlock else elseBlock"></div>
                    <ng-template #thenBlock>
                        <app-cooler-template-creator (newTemplateCreateBackEvent)="templateCreateBackEvent($event)"
                            (newTemplateCreateBackAndNextScreenNavigateEvent)="templateCreateBackAndNextScreenNavigateEventHandle($event)"
                            [planogram_id]="edit_planogram_detail.id"
                            [edit_template_id]="edit_template_id"></app-cooler-template-creator>
                    </ng-template>
                    <ng-template #elseBlock>
                        <div style="display: flex; height: 100%; width: 100%;">
                            <div style="min-height: 100%; width: 70%;padding-right:10px">
                                <h3>Create a cooler template or select from the existing templates</h3>
                                <button id="create-new-template-btn" mat-raised-button style="background-color:#25D4CF"
                                    (click)="handleCreateNewTemplateBtnClick()">Create New Template</button>
                                <div id="existing-cooler-template-container">

                                    <mat-form-field appearance="fill" class="template-search-input">
                                        <input matInput [(ngModel)]="cooler_template_search_input" type="text"
                                            placeholder="Select">
                                        <mat-icon>search</mat-icon>
                                    </mat-form-field>

                                    <div id="templates-container" [formGroup]="cooler_template_form_group"
                                        *ngIf="cooler_template_form_group.get('cooler_template')">
                                        <mat-card class="template-mat-card"
                                            [ngClass]="{'selected-border':cooler_template_form_group.controls['cooler_template'].value === template.id}"
                                            *ngFor="let template of sortTemplates(saved_templates) | sortAndFilter :  cooler_template_search_input.trim().toLowerCase() : 'name' : 'created_date' ">
                                            <mat-card-title-group>
                                                <mat-card-title>
                                                    <mat-checkbox class="cooler-template-checkbox" 
                                                        [value]="template.id"
                                                        [checked]="cooler_template_form_group.controls['cooler_template'].value === template.id"
                                                        (change)="handleCoolerCheckbox($event)">
                                                        <img [src]="template.img_link + sas" style="width: 24px;"  alt="" />
                                                        {{template.name}}
                                                        <span *ngIf="template.door" 
                                                        style="float: right; margin-right: 50px;" > 
                                                        ( {{template.door || 1}} Door Cooler ) </span>
                                                    </mat-checkbox>
                                                </mat-card-title>
                                                <em class="bi bi-pencil"
                                                    [ngStyle]="{'cursor': 'pointer' }"
                                                    [ngClass]="{'selected-text':cooler_template_form_group.controls['cooler_template'].value === template.id}"
                                                    (click)="editTemplate(template)"></em>
                                            </mat-card-title-group>
                                        </mat-card>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="cooler_template_form_group.valid" id="picos-sku-list-container" >
                                <div style="width: calc(100% + 30px); height: calc(100%);overflow: auto;" >
                                <h3>PicOs based Recommended SKUs</h3>
                                    <mat-list id="sku-list" >
                                        <mat-list-item class="no-padding" *ngFor="let sku of picos_sku_list">
                                            <img mat-list-icon [src]='sku.sku_img_link + sas' alt="..."
                                                class="static-sku-dimension" [ngStyle]="{'height.px': sku.sku_height*CONFIG.MMTOPIXCEL/CONFIG.HEIGHTCONSTANT , 
                                                'width.px': sku.sku_diameter_length*CONFIG.MMTOPIXCEL/CONFIG.WIDTHCONSTANT , 'border-radius':0}" />
                                            <h3 mat-line> {{sku.sku_name}} </h3>
                                            <p mat-line>
                                                <span> {{sku.sku_volume}}ml </span>
                                            </p>
                                        </mat-list-item>
                                    </mat-list>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                </div>
            </ng-template>
        </mat-step>

        <mat-step [completed]="add_sku_form_group.valid || planogram_status === CONFIG.PLANOGRAMSTATUS.ACTIVE " label="Add SKUs">
            <div class="stepper-actions-container">
                <button mat-button [disabled]="mode=='edit'" (click)="handleDiscardClick(edit_planogram_detail.id)">Discard</button>
                <button mat-button [disabled]="add_sku_form_group.invalid" (click)="draftPlanogramDetails(is_planogram_edit, edit_planogram_detail, 2)">Save as
                    draft</button>
                <button mat-raised-button style="background-color:#25D4CF" [disabled]="add_sku_form_group.invalid"
                    (click)="savePlanogramBaseDetailsAndNavigate(is_planogram_edit, edit_planogram_detail, 2)">Next</button>
            </div>
            <ng-template matStepContent>
                <app-planogram-builder [planogram_id]="edit_planogram_detail.id" [is_display]="stepper_index === 2"
                    [scenario]="getScenario()" [sku_placement_change]="sku_placement_change"
                    (skuPlacementEventEmitter)="skuPlacementFlagUpdate($event)"
                    (filledSkuEventEmitter)="filledSkuEvent($event)"
                    (businessInsightCalculationEventEmitter)="businessInsightCalculationEvent($event)"
                    (verticalColumnBrandAssociationEventEmitter)="updateVerticalColumnBrandAssociation($event)"
                    (coolerOrientationEventEmitter)="updateCoolerOrientation($event)"
                    (goToPreviousStepEventEmitter)="goToPreviousStep($event);mystepper.previous()"
                    ></app-planogram-builder>
                </ng-template>
        </mat-step>
        <mat-step [completed]="planogram_status === CONFIG.PLANOGRAMSTATUS.ACTIVE" label="Review & Publish">
            <div class="stepper-actions-container">
                <button mat-button [disabled]="mode=='edit'" (click)="handleDiscardClick(edit_planogram_detail.id)">Discard</button>
                <button mat-button (click)="draftPlanogramDetails(is_planogram_edit, edit_planogram_detail, 3)">Save as
                    draft</button>
                <button mat-raised-button style="background-color:#25D4CF"
                    (click)="savePlanogramBaseDetailsAndNavigate(is_planogram_edit, edit_planogram_detail, 3)">Publish</button>
            </div>
            <ng-template matStepContent>
                <app-publish-planogram [is_display]="stepper_index === 3"  [planogram_id]="edit_planogram_detail.id" (goToPreviousStepEventEmitter)="goToPreviousStep($event);mystepper.previous()" ></app-publish-planogram>
            </ng-template>
        </mat-step>
    </mat-horizontal-stepper>
    <app-loader [is_show_loader]="(is_show_loader | async)!"></app-loader>
</div>