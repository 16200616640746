import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthResponseComponent } from 'src/components/auth-response/auth-response.component';
import { ComparePlanogramComponent } from 'src/components/compare-planogram/compare-planogram.component';
import { LoginComponent } from 'src/components/login/login.component';
import { PlanogramListComponent } from 'src/components/planogram-list/planogram-list.component';
import { SetupPlanogramComponent } from 'src/components/setup-planogram/setup-planogram.component';
import { CONFIG } from 'src/helper/app-config';

const routes: Routes = [
  { path: CONFIG.PATH.PLANOGRAMS, component: PlanogramListComponent },
  { path: CONFIG.PATH.PLANOGRAM + ':planogram_id', component: SetupPlanogramComponent },
  { path: CONFIG.PATH.NEWPLANOGRAM, component: SetupPlanogramComponent },
  { path: CONFIG.PATH.COMPAREPLANOGRAM, component: ComparePlanogramComponent},
  { path: CONFIG.PATH.LOGIN, component: LoginComponent },
  { path: CONFIG.PATH.AUTHRESPONSE, component: AuthResponseComponent },
  { path: CONFIG.PATH.EMPTY, redirectTo: CONFIG.PATH.PLANOGRAMS, pathMatch: "full" },
  {path: '**', redirectTo: CONFIG.PATH.PLANOGRAMS },
];


const isIframe = window !== window.parent && !window.opener;

console.log("isIframe :: ", isIframe)

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload',
    initialNavigation:'enabled' // Don't perform initial navigation in iframes
    //initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
