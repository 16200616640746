import { Command, CommandResult } from '../../../../modals/undo-redo-command';

/**
 * Updates multiple properties of a target object.
 */
export interface UpdatePropertiesCommandData<TTarget> {
  /**
   * The target object.
   */
  target: string;
  // target: TTarget;
  /**
   * A partial instance of the target type with only the values to be updated.
   */
  collection: TTarget;
  newValues: TTarget;
}

export class UpdatePropertiesCommand<T> implements Command {
  private previousValues?: T;
  // public displayName?: string;
  // private collection?: T;
  constructor(
    private commandData: UpdatePropertiesCommandData<T>
  ) {

  }

  public execute(): CommandResult {
    const newValues = this.commandData.newValues;
    if (!newValues) {
      return { success: false };
    }

    this.previousValues = this.updateProperties(newValues);
    
    return {
      success: true,
      canUndo: true,
      canRedo: false, // setting the same properties twice is not useful
      collection: this.commandData.collection as any
     };
  }

  public undo(): CommandResult {
    if (!this.previousValues)
      return { success: false };

    this.updateProperties(this.previousValues);
    this.previousValues = undefined;
    return { success: true, collection:this.commandData.collection as any};
  }

  public redo(): CommandResult {
    if (this.previousValues) // we can only redo in this state
      return { success: false };

    return this.execute();
  }

  private updateProperties(newValues: T): T {

    let previousValues: T = JSON.parse(JSON.stringify(this.commandData.collection));
    this.commandData.collection = JSON.parse(JSON.stringify(newValues))

    return previousValues;
  }
}
