import { CdkDragMove, CdkDragStart } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatList } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SubscriptionLike } from 'rxjs';
import { abbreviateNumber } from 'src/helper/app-utils';
import { PlanogramModal, SKUModal } from 'src/modals/planogram';
import { PlanogramStateService } from 'src/services/planogram-state.service';
import { CONFIG } from '../../helper/app-config';
import { ConfirmDialogModalComponent } from '../confirm-dialog-modal/confirm-dialog-modal.component';
import { SkuInfoDialogComponent } from '../sku-info-dialog/sku-info-dialog.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-sku-component',
  templateUrl: './sku-component.component.html',
  styleUrls: ['./sku-component.component.css']
})



export class SkuComponentComponent implements OnChanges {
  @ViewChild(MatList, { read: ElementRef }) child: ElementRef;

  @Input() drop_item_ids: string[] = [];
  @Input() sku_list: SKUModal[] = [];
  @Input() cooler_config: PlanogramModal;
  @Input() planogram_id: number;
  // @Input() placeholder_width: number;
  @Input() empty_cooler_alert: boolean = false;
  @Input() scenario: string;
  @Input() vertical_cooler_orientation: boolean;
  @Input() vertical_brand_association: any = {};
  @Input() dim_benchmark_clearance : number = 0;
  @Input() sku_brands: any = {};

  @Output() addSkuEventEmitter = new EventEmitter<any>();
  @Output() editFacingEventEmitter = new EventEmitter<any>();
  @Output() skuPlacementFlagEventEmitter = new EventEmitter()
  @Output() coolerOrientationEventEmitter = new EventEmitter<boolean>()
  @Output() clearVerticalColumnPlacementEventEmitter = new EventEmitter<number>();
  @Output() verticalColumnBrandAssociationEventEmitter = new EventEmitter<any>();

  //sas='?'+environment.sas;
  sas=""
  JSON = JSON
  CONFIG = CONFIG;
  Math = Math;
  sku_search_input = '';
  is_update_facing = false;
  // shelf_facing_input: FormControl = new FormControl({ value: undefined, disabled: true }, Validators.required);

  edit_facing_form_group: FormGroup = this._formBuilder.group({
    fc_shelf_facings: new FormControl({ value: 0, disabled: true }, [Validators.min(0)]),
    ic_shelf_facings: new FormControl({ value: 0, disabled: true }, [Validators.min(0)])
  })
  
  max_facing = 1;
  filter_sku_list: SKUModal[] = [];
  sku_filter = 'all';
  available_brands: string[] = [];
  vertical_column_brand_mapping_form: FormGroup = this._formBuilder.group({})
  _currentIndex: any;
  _currentField: any;
  shelf_facing_input_subscription: SubscriptionLike;

  constructor(private planogramState: PlanogramStateService, private _formBuilder: FormBuilder, public dialog: MatDialog) { }

  updateFacing = (edit_facings: any) => {
    if (this.cooler_config && edit_facings['ic_shelf_facings'] !== this.cooler_config.facings_ic ||
    edit_facings['fc_shelf_facings'] !== this.cooler_config.facings_fc) {
      let info = {
        planogram_id: this.planogram_id,
        template_id: this.cooler_config.template_id,
        facings_ic: this.cooler_config.ic_flag ? edit_facings['ic_shelf_facings'] : 0,
        facings_fc: this.cooler_config.fc_flag ? edit_facings['fc_shelf_facings'] : 0,
        facings: Array(this.cooler_config.shelves.length).fill(0).map((_x,i)=> this.cooler_config.ic_shelves.includes(i+1) ? edit_facings.ic_shelf_facings : edit_facings.fc_shelf_facings)
      }
      this.planogramState.editCoolerFacing(info);
      this.editFacingEventEmitter.emit(edit_facings);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['cooler_config']) && Object.keys(this.cooler_config).length > 0) {
        this.ShelfFacingChanges();
    }

    if (changes['sku_list']) {
      this.filter_sku_list = this.sku_list;
    }

    if(changes['sku_brands']){
      if (Object.keys(this.sku_brands).length > 0) {
        this.available_brands = Object.keys(this.sku_brands);
      }
    }

    if (changes['cooler_config'] && this.vertical_cooler_orientation) {
      this.buildVerticalBlockFormGroup(false);
    }

  }

  ShelfFacingChanges = ()=>{
    if (this.edit_facing_form_group.get('ic_shelf_facings')?.value !== this.cooler_config.facings_ic ||
    this.edit_facing_form_group.get('fc_shelf_facings')?.value !== this.cooler_config.facings_fc) {
    if (this.shelf_facing_input_subscription) {
      this.shelf_facing_input_subscription.unsubscribe();
    }

    if(this.cooler_config.ic_flag){
      this.edit_facing_form_group.get('ic_shelf_facings')?.reset({ value: this.cooler_config.facings_ic, disabled: false })
      this.edit_facing_form_group.get('ic_shelf_facings')?.setValidators([Validators.required, Validators.min(0), Validators.max(this.getMaxFacing(this.cooler_config.avg_sku_width_ic, this.cooler_config.cooler_width_calc))])
      this.edit_facing_form_group.get('ic_shelf_facings')?.updateValueAndValidity();
    }

    if(this.cooler_config.fc_flag){
      this.edit_facing_form_group.get('fc_shelf_facings')?.reset({ value: this.cooler_config.facings_fc, disabled: false })
      this.edit_facing_form_group.get('fc_shelf_facings')?.setValidators([Validators.required, Validators.min(0), Validators.max(this.getMaxFacing(this.cooler_config.avg_sku_width_fc, this.cooler_config.cooler_width_calc))])
      this.edit_facing_form_group.get('fc_shelf_facings')?.updateValueAndValidity();
    }

    this.subscribeShelfFacingValueChange();
  }
  }

  updateICFCFacings(){
    if (this.edit_facing_form_group.valid) {
      this.is_update_facing = false;
      if (this.empty_cooler_alert) {
        const dialogRef = this.dialog.open(ConfirmDialogModalComponent, {
          height: '200px',
          width: '500px',
          data: {
            header_msg: 'Are you sure you want to cancel?',
            header_msg_description: 'All progress will be lost.',
            // cancel_action: 'Cancel',
            ok_action: 'Continue'
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.updateFacing(this.edit_facing_form_group.getRawValue())
          }
        });
      } else {
        this.updateFacing(this.edit_facing_form_group.getRawValue())
      }
    }
  }

  getMaxFacing = (avg_sku_width: number, cooler_width:number)=>{
    cooler_width = cooler_width*(1- this.dim_benchmark_clearance)
    return Math.round(cooler_width/ avg_sku_width)
  }

  buildVerticalBlockFormGroup = (is_update_orientation: boolean) => {
    let max = this.cooler_config && this.cooler_config.facings_ic*this.cooler_config.no_of_doors || 0

    if (Object.keys(this.vertical_brand_association).length > 0 && this.vertical_brand_association[1].brand) {
      Object.values(this.vertical_brand_association).forEach((block: any, index) => {
        this.vertical_column_brand_mapping_form.setControl(`block${index + 1}`, new FormControl(block.brand, [Validators.required, this.blockBrandValidator.bind(this)]))
        this.vertical_column_brand_mapping_form.setControl(`block${index + 1}facingcount`, new FormControl(block.no_of_facings, [Validators.required, Validators.min(1), Validators.max(max), this.blockFacingValidator.bind(this)]))
      })
    } else if (is_update_orientation && this.cooler_config.ic_flag) {
      this.vertical_column_brand_mapping_form.setControl('block1', new FormControl([], [Validators.required, this.blockBrandValidator.bind(this)]))
      this.vertical_column_brand_mapping_form.setControl('block1facingcount', new FormControl(1, [Validators.required, Validators.max(max),Validators.min(1), this.blockFacingValidator.bind(this)]))

    }

  }

  subscribeShelfFacingValueChange() {
    this.shelf_facing_input_subscription = this.edit_facing_form_group.valueChanges.subscribe(_value => {
      this.is_update_facing = true
    }) as any;
  }

  handleFormFeildCounterChnage = (field: FormControl | AbstractControl, counter_change: number) => {
    if (field.enabled) {
      field.setValue(field.value + counter_change)
    }
  }

  dragStart(event: CdkDragStart) {
    this._currentIndex = this.filter_sku_list.filter(s => s.sku_name.toLowerCase().includes(this.sku_search_input.trim().toLowerCase())).indexOf(event.source.data); // Get index of dragged type
    this._currentField = this.child.nativeElement.children[this._currentIndex]; // Store HTML field
  }

  moved(_event: CdkDragMove) {
    // Check if stored HTML field is as same as current field
    if (this.child.nativeElement.children[this._currentIndex] !== this._currentField) {
      if (this._currentIndex === 0) {
        this.child.nativeElement.children[this._currentIndex].insertAdjacentElement('beforebegin', this._currentField);
      } else {
        this.child.nativeElement.children[this._currentIndex - 1].insertAdjacentElement('afterend', this._currentField);
      }
    }
  }

  showSKUInformation(sku: any) {
    this.dialog.open(SkuInfoDialogComponent, {
      position: { right: '0', top: '0', bottom: '0' },
      height: '100%', width: '400px',
      data: { sku_id: sku.sku_id }
    });
  }

  filterSkus = (filter: string) => {
    this.sku_filter = filter;
    if (filter === 'recommanded') {
      this.filter_sku_list = this.sku_list.filter(s => s.picos === 0)
    } else if (filter === 'picos') {
      this.filter_sku_list = this.sku_list.filter(s => s.picos === 1)
    } else {
      this.filter_sku_list = this.sku_list;
    }
  }

  autoFillSkus() {
    if (this.vertical_cooler_orientation && this.cooler_config.ic_flag) {
      if (this.vertical_column_brand_mapping_form.invalid) {
        this.dialog.open(ConfirmDialogModalComponent, {
          height: '200px',
          width: '500px',
          data: {
            header_msg: `First configure the block correctly.`,
            header_msg_description: '',
            // cancel_action: 'Cancel',
            ok_action: 'Ok'
          },
        });
      } else {
        this.planogramState.verticalAutofillSkus(this.planogram_id, this.vertical_brand_association)
      }
    } else {
      this.planogramState.autofillSkus(this.planogram_id)
    }
    this.skuPlacementFlagEventEmitter.emit();
  }

  addSku(sku: any) {
    this.addSkuEventEmitter.emit(sku);
  }

  abbreviateNumber = (number: number) => {
    return abbreviateNumber(number)
  }

  counter = (number: number) => {
    if (number && !isNaN(number)) {
      return Array(number).fill(0).map((_x, i) => i + 1);
    }
    return [];
  }

  verticalBlocks = () => {
    if (Object.keys(this.vertical_column_brand_mapping_form.getRawValue()).length > 0) {
      return Object.keys(this.vertical_column_brand_mapping_form.getRawValue()).length / 2
    }
    return 0
  }

  isAddNewBlock = () => {
    let total_facing = this.cooler_config.facings_ic* this.cooler_config.no_of_doors || 0;
    let block_facings = this.getTotalBlockedFacings();
    return block_facings < total_facing;
  }

  removeBlock = (block_index: number) => {
    this.vertical_column_brand_mapping_form.removeControl('block' + block_index);
    this.vertical_column_brand_mapping_form.removeControl('block' + block_index + 'facingcount');
    this.clearVerticalColumnPlacementEventEmitter.emit(block_index);
    this.verticalColumnBrandAssociationEventEmitter.emit(this.blockwiseBrandAssociation());
  }

  blockFacingValidator(_control: FormControl) {
    let total_facing = 0;
    if(this.cooler_config){
      total_facing = this.cooler_config && this.cooler_config.facings_ic ? this.cooler_config.facings_ic : 0;
      total_facing = total_facing* this.cooler_config.no_of_doors
    }
    
    return total_facing >= this.getTotalBlockedFacings() ? null : { 'blockfacing': { error: true, max: total_facing + 1 } };
  }

  blockBrandValidator(control: FormControl) {
    let selected_brands : string[] = control.value || [];
    let is_error: boolean = selected_brands.length === 0 ? false : true;

    if(this.cooler_config && this.sku_brands){
      selected_brands.forEach(brand=>{
        if(this.sku_brands[brand].fc_flag === this.cooler_config.fc_flag &&  this.sku_brands[brand].ic_flag === this.cooler_config.ic_flag){
          is_error = false;
        }
      })
    }
    
    return is_error ? { 'blockbrand': { error: true } } : null;
  }

  addBlock = () => {
    if (this.cooler_config.shelves[0]) {
      let total_facings = this.cooler_config.facings_ic*this.cooler_config.no_of_doors;
      let index = Object.keys(this.vertical_column_brand_mapping_form.getRawValue()).length / 2 + 1
      if (total_facings + 1 >= index) {
        this.vertical_column_brand_mapping_form.setControl('block' + index, new FormControl([], [Validators.required, this.blockBrandValidator.bind(this)]))
        this.vertical_column_brand_mapping_form.setControl('block' + index + 'facingcount', new FormControl(1, [Validators.required, Validators.min(1), Validators.max(total_facings), this.blockFacingValidator.bind(this)]))
       } else {
        this.dialog.open(ConfirmDialogModalComponent, {
          height: '200px',
          width: '500px',
          data: {
            header_msg: `Maximum ${this.cooler_config.facings_ic} blocks can be added.`,
            header_msg_description: '',
            ok_action: 'Ok'
          },
        });
      }

    }

  }

  updateOrientation = (event: MatSlideToggleChange) => {
    if (event.checked) {
      if (this.empty_cooler_alert) {
        const dialogRef = this.dialog.open(ConfirmDialogModalComponent, {
          height: '200px',
          width: '500px',
          data: {
            header_msg: 'Are you sure you want to cancel?',
            header_msg_description: 'All progress will be lost.',
            // cancel_action: 'Cancel',
            ok_action: 'Continue'
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.updateOrientationFlag(event.checked);
          }
        });
      } else {
        this.updateOrientationFlag(event.checked);
      }

      this.buildVerticalBlockFormGroup(true);

    } else {
      this.updateOrientationFlag(event.checked);
    }

  }

  updateOrientationFlag = (cooler_orientation: boolean) => {
    this.vertical_cooler_orientation = cooler_orientation;
    this.coolerOrientationEventEmitter.emit(this.vertical_cooler_orientation);
  }

  handleBrandChange = (_event: MatSelectChange, block: number) => {
    // console.log("form values :: ", this.vertical_column_brand_mapping_form.getRawValue())
    this.clearVerticalColumnPlacementEventEmitter.emit(block);
    this.verticalColumnBrandAssociationEventEmitter.emit(this.blockwiseBrandAssociation());
  }

  handleBlockFacingChange = (_event: any, block: number) => {
    if (this.vertical_column_brand_mapping_form.valid) {
      this.clearVerticalColumnPlacementEventEmitter.emit(block);
      this.verticalColumnBrandAssociationEventEmitter.emit(this.blockwiseBrandAssociation());
    }
  }

  blockwiseBrandAssociation = () => {
    let res: any = {};
    let form_values = this.vertical_column_brand_mapping_form.getRawValue();
    for (let index = 1; index <= Object.keys(form_values).length / 2; index++) {
      res[index] = { brand: form_values['block' + index], no_of_facings: form_values['block' + index + 'facingcount'] }
    }
    return res;
  }

  getTotalBlockedFacings = () => {
    let count = 0;
    let form_values = this.vertical_column_brand_mapping_form.getRawValue();
    for (let index = 1; index <= Object.keys(form_values).length / 2; index++) {
      count += form_values['block' + index + 'facingcount']
    }
    return count;
  }

  getBlockMaxFacing = (block_index : number)=>{
    if(this.cooler_config){
      let total_facing = this.cooler_config && this.cooler_config.facings_ic ? this.cooler_config.facings_ic : 0;
      total_facing = total_facing* this.cooler_config.no_of_doors;
      let block_facings = this.getTotalBlockedFacings();
      // console.log(" block " , block_index," max :: ", total_facing - (block_facings - this.vertical_column_brand_mapping_form.controls['block' + block_index + 'facingcount'].value))
      return total_facing - (block_facings - this.vertical_column_brand_mapping_form.controls['block' + block_index + 'facingcount'].value)
    }
    return 0;
  }

}
