import { Injectable } from '@angular/core';
import {BlobServiceClient, ContainerClient} from '@azure/storage-blob';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AzureBlobStorageService {

  public uploadImage( content: Blob, name: string, handler: (res:any) => void) {
    this.uploadBlob(content, name, this.containerClient(environment.sas),  handler)
  }

  public copyImage( source_url: string, destination: string, source_file_name: string, handler: (res:any) => void) {
    this.copyBlob(source_url, destination, source_file_name, this.containerClient(environment.sas), handler)

  }


  public downloadImage( name: string, handler: (blob: Blob) => void) {
    this.downloadBlob(name, this.containerClient(environment.sas), handler)
  }

  private uploadBlob(content: Blob, name: string, client: ContainerClient, handler: (res:any) => void) {
    
    let blockBlobClient = client.getBlockBlobClient(name);
    blockBlobClient.uploadData(content, { blobHTTPHeaders: { blobContentType: content.type } })
      .then(() => handler(blockBlobClient.url))
  }

  private containerClient(sas: string): ContainerClient {
    
    return new BlobServiceClient(`https://${environment.picturesAccount}.blob.core.windows.net?${sas}`)
            .getContainerClient(environment.picturesContainer);
  }

  private downloadBlob(name: string, client: ContainerClient, handler: (blob: Blob) => void) {
    const blobClient = client.getBlobClient(name);
    blobClient.download().then(resp => {
      resp.blobBody?.then(blob => {
        handler(blob)
      })
    })
  }

  private copyBlob(source: string, destination: string,source_file_name: string, client: ContainerClient, handler: (blob: any) => void){
    const blobClient = client.getBlobClient(destination);
    client.getBlobClient(source_file_name).exists().then(is_exist=>{
      if(is_exist){
        blobClient.syncCopyFromURL(source).then(res=>{
          handler(res)
        })
      }
    })
  }

}
