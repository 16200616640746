import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {getHeaders, handleError, handleResponse} from './service-utilities';
import { CONFIG } from 'src/helper/app-config';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) { }

  saveUserToken(user_info: any) {
    let headers = getHeaders();
    let options = {
      headers: headers,
      responseType: "text" as "text",
    };
    return this.http.post(environment.api_base_url + CONFIG.API.SAVEUSERTOKEN, JSON.stringify(user_info), options).pipe(
      map(handleResponse),
      catchError(handleError)
    );
  }
}