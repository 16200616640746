import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { throwError } from "rxjs";


export function getHeaders(){
    return new HttpHeaders({
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type"
    });
}


  export function handleResponse (response : any){
    let response_ = JSON.parse(response);
    if(response_.code === 200){
      return response_.data;
    }else if(response_.code === 400){
      throw new Error(JSON.stringify(response_.data))
    }
    return response_;
  }

  export function handleUniqueNameCheckApiResponse (response : any){
    let response_ = JSON.parse(response);
    if(response_.code === 200){
      return response_.data;
    }
    return response_;
  }


export function handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    }else if(error.status === 500){
      console.log("error :: ", error)
    return throwError(error.statusText);
    }else if(error.status === 401){
      let error_ = JSON.parse(error.error);
      return throwError(error_.err_msg)
    }else if(error.status === 400){
      let error_ = JSON.parse(error.error);
      return throwError(error_)
    }
     else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error("error :: ",error)
    }
    // Return an observable with a user-facing error message.
    return throwError(error.message);
  }


  export const getCookie = (name: string) => {
    const ca: Array<string> = decodeURIComponent(document.cookie).split(';');
    const caLen: number = ca.length;
    const cookieName = `${name}=`;
    let c: string;
    for (let i = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  };
