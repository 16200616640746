import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DialogData{
}

@Component({
  selector: 'app-create-cooler-template-dialog',
  templateUrl: './create-cooler-template-dialog.component.html',
  styleUrls: ['./create-cooler-template-dialog.component.css']
})
export class CreateCoolerTemplateDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private dialogRef: MatDialogRef<CreateCoolerTemplateDialogComponent>) { }

  templateCreateBackAndNextScreenNavigateEventHandle = (event_params: { is_success: boolean, cooler_id: number }) => {
    if (event_params.is_success) {
      this.dialogRef.close();
      console.log("dialog closed...")
    }
  }

}
