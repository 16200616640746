<div id="cooler-container" [ngStyle]="{'padding' : drag_drop_allowed ? '0 20px' : '20px'}"
    *ngIf="cooler_config && cooler_config.cooler_height_calc && cooler_config.cooler_width_calc && cooler_config.shelves && cooler_config.shelves.length > 0">
    <ng-template #coolertemplate let-sku_position="sku_position" let-shelfpadding="shelfpadding"
        [ngTemplateOutlet]="coolertemplate"
        [ngTemplateOutletContext]="{avail_height:coolerAvailableHeight() , shelfpadding:coolerShelfPadding()}">
        <div style="margin: auto;">
            <div id="image-capture-container" [ngStyle]="{'padding' : drag_drop_allowed ? '10px' : '0'}">
                <div *ngFor="let door of counter(cooler_config.no_of_doors)">
                    <div [id]="'door-'+ (door+1)" style="display: flex; align-self: center;"
                        [ngStyle]="{'margin-left': door > 0 ? '2px' : '0' }">
                        <div class="cooler-door-container left-door-container" [ngClass]="getDoorClass()"
                            *ngIf="cooler_config.no_of_doors && cooler_config.traffic_direction">
                            <div class="cooler-door" style="margin-right: -6px;">
                            </div>
                        </div>
                        <table id="cooler" aria-describedby=""
                            [ngStyle]="{'width.px': cooler_config.cooler_width_calc*CONFIG.MMTOPIXCEL/CONFIG.WIDTHCONSTANT}">
                            <thead>
                                <tr>
                                    <th class="no-sku-placement-cell"
                                        [ngStyle]="{'height.px': 40 , 'width': '100%', 'text-align':'center', 'border-bottom': '1px solid black'}">
                                        <img [src]="cooler_config.banner ? cooler_config.banner.image + sas  : './assets/coke_logo_white.png' "
                                            style="height: 100%; width:auto; margin:auto" alt="'" />
                                    </th>
                                </tr>
                            </thead>

                            <tbody #tbl
                                [ngStyle]="{'height.px': cooler_config.cooler_height_calc *CONFIG.MMTOPIXCEL/CONFIG.HEIGHTCONSTANT }">
                                <tr *ngFor="let row of cooler_config.shelves;">
                                    <td [ngStyle]="{'height.px': (shelfHeight(row.cooler_shelf)*CONFIG.MMTOPIXCEL/CONFIG.HEIGHTCONSTANT)+ shelfpadding  , 
                            'width.px': cooler_config.cooler_width_calc*CONFIG.MMTOPIXCEL/CONFIG.WIDTHCONSTANT} ">
                                        <div class="transparent-div-container"
                                            [ngStyle]="{'height': row.cooler_shelf !== cooler_config.shelves.length ? 'calc(100% - 4px)' : '100%'}">
                                            <!-- padding-top.px':shelfpadding > 10 ? shelfpadding - 10 : shelfpadding' -->
                                            <div class="inline-flex-container">
                                                <div class='img-bottom-align'
                                                    *ngFor="let index of (maxPossibleSKUs(cooler_config.cooler_width_calc , row.cooler_shelf)); index as i">
                                                    <!-- [ngStyle]="{'background': isHighImactFacing(row.cooler_shelf, row.no_of_facings , i+1, door+1) ? 'yellow' : 'transparent' }"  -->
                                                    <div style="height: 100%;" class="text-align-div" >
                                                            <em *ngIf="isHighImactFacing(row.cooler_shelf, row.no_of_facings , i+1, door+1)"
                                                            class="bi bi-star-fill high-impact-sku-place"></em>
                                                        <div class="flex-bottom-align-div" >
                                                            <ng-template #skutemplete let-sku_position="sku_position"
                                                            [ngTemplateOutlet]="skutemplete"
                                                            [ngTemplateOutletContext]="{sku_position: i+1 + row.no_of_facings *door}">
                                                            <!-- sku_position:: {{sku_position}} -->
                                                            <div
                                                                *ngIf="getPlacedSKUIndex(row.cooler_shelf , sku_position) !== -1; then thenBlock else elseBlock">
                                                            </div>

                                                            <ng-template #thenBlock>
                                                                <ng-template #placedsku let-sku="object"
                                                                    [ngTemplateOutlet]="placedsku"
                                                                    [ngTemplateOutletContext]="{object: getSKU(row.cooler_shelf , sku_position)}">
                                                                    <ng-template #tooltipContent><em
                                                                            class="bi bi-plus"></em>
                                                                    </ng-template>
                                                                    <div cdkDropList cdkDropListSortingDisabled
                                                                        [cdkDropListConnectedTo]="drop_item_ids"
                                                                        [id]="'placed_sku_' + sku.shelf + '_' + sku.position"
                                                                        class="mat-badge mat-badge-above mat-badge-after mat-badge-medium"
                                                                        [ngbPopover]="tipContent" placement="bottom"
                                                                        container="body" autoClose="outside"
                                                                        #p="ngbPopover" (click)="p.open()"
                                                                        triggers="manual"
                                                                        popoverClass="sku-popover-class">
                                                                        <!-- <span *ngIf="p.isOpen()" class="mat-badge-content mat-badge-active">
                                                        <i class="bi bi-plus" (click)="copySku(sku, p);"></i>
                                                    </span> -->

                                                                        <ng-template #tipContent>
                                                                            <div *ngIf="selected_skus.length <= 1">
                                                                                {{sku.sku_name}}
                                                                            </div>
                                                                            <div *ngIf="selected_skus.length <= 1">
                                                                                {{sku.sku_volume}} ml
                                                                            </div>
                                                                            <div>
                                                                                <em class="bi bi-trash"
                                                                                    (click)="removeSku(sku)"></em>
                                                                                <em class="bi bi-arrow-repeat"
                                                                                    (click)="openSkuReplaceDialog();p.close()"></em>
                                                                                <em *ngIf="selected_skus.length <= 1"
                                                                                    class="bi bi-info-circle"
                                                                                    (click)="showSKUInformation(sku)"></em>
                                                                            </div>

                                                                        </ng-template>

                                                                        <!-- triggers="click" [autoClose]="'outside'" placement="end" tooltipClass="sku-tooltip-class"
                                                    [ngbTooltip]="tooltipContent" -->
                                                                        <!-- <i *ngIf="isHighImactFacing(row.cooler_shelf, row.no_of_facings , i+1, door+1)" class="bi bi-star-fill high-impact-sku-place"></i> -->
                                                                        <!-- *ngIf="!isSKUOverlaying(row.cooler_shelf , sku_position , door+1, sku.sku_diameter_length)" -->
                                                                        <img  cdkDrag
                                                                            [cdkDragData]="{inside_drop: true , sku}"
                                                                            (cdkDragStarted)="dragStart($event)"
                                                                            (cdkDragMoved)="moved($event)"
                                                                            [src]="sku.sku_img_link + sas" alt="..."
                                                                            (click)="selectSkus($event,row.cooler_shelf, sku_position, door+1)"
                                                                            [ngClass]="{'highlight-sku': selected_skus.includes((row.cooler_shelf)+'_'+(sku_position)+ '_'+( door+1))}"
                                                                            [ngStyle]="{ 'width.px': sku.sku_diameter_length * CONFIG.MMTOPIXCEL/CONFIG.WIDTHCONSTANT  , 'height.px': sku.sku_height * CONFIG.MMTOPIXCEL/CONFIG.HEIGHTCONSTANT}" />
                                                                    </div>
                                                                </ng-template>

                                                            </ng-template>
                                                            <ng-template #elseBlock>
                                                                <div cdkDropList
                                                                    [id]="addDropId(row.cooler_shelf, sku_position)"
                                                                    cdkDropListOrientation="horizontal"
                                                                    cdkDropListSortingDisabled
                                                                    (cdkDropListDropped)="handleSKUDrop($event, row.cooler_shelf , sku_position, door+1)">
                                                                    <div class='placeholder-div'
                                                                        (click)="hightlightSkuPlaceholder($event,row.cooler_shelf, sku_position, door+1)">
                                                                        <!-- *ngIf="!isSKUOverlaying(row.cooler_shelf , sku_position , door+1, getPlaceholderWidth(row.cooler_shelf))" -->
                                                                        <!-- <i *ngIf="isHighImactFacing(row.cooler_shelf, row.no_of_facings , i+1, door+1)" class="bi bi-star-fill high-impact-sku-place"></i> -->
                                                                        <img  [src]="row.placeholder_image + sas" alt="..."
                                                                            [ngClass]="{'highlight-placeholder': highlighted_placeholder_skus.includes((row.cooler_shelf)+'_'+(sku_position) + '_'+( door+1))}"
                                                                            [ngStyle]="{ 'width.px': getPlaceholderWidth(row.cooler_shelf)* CONFIG.MMTOPIXCEL/CONFIG.WIDTHCONSTANT , 
                                                                    'height.px': getPlaceholderHeight(row.cooler_shelf)* CONFIG.MMTOPIXCEL/CONFIG.HEIGHTCONSTANT }" />
                                                                    </div>
                                                                </div>
                                                            </ng-template>
                                                        </ng-template>
                                                        </div>
                                                        
                                                    </div>

                                                </div>
                                            </div>

                                            <div *ngIf="row.cooler_shelf !== cooler_config.shelves.length"
                                                class="border-bottom-div">
                                            </div>

                                        </div>

                                    </td>
                                </tr>
                            </tbody>

                            <tfoot>
                                <tr>
                                    <td class="no-sku-placement-cell" [ngStyle]="{'height.px': 60 , 'width': '100%'}">
                                    </td>
                                </tr>
                            </tfoot>

                        </table>

                        <div class="cooler-door-container right-door-container" [ngClass]="getDoorClass()"
                            *ngIf="cooler_config.no_of_doors && cooler_config.traffic_direction">
                            <div class="cooler-door" style="margin-left: -6px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>