import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData{
  header_msg: string;
  header_msg_description: string | string[];
  cancel_action: string;
  ok_action: string;
}

@Component({
  selector: 'app-confirm-dialog-modal',
  templateUrl: './confirm-dialog-modal.component.html',
  styleUrls: ['./confirm-dialog-modal.component.css']
})
export class ConfirmDialogModalComponent {
  messages : string[] = []
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    if(typeof data.header_msg_description === 'string'){
      this.messages = [data.header_msg_description]
    }else{
      this.messages = data.header_msg_description
    }
   }

}
