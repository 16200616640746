<mat-dialog-content id="compare-planogram-dialog-content">
    <div class="header" >
        <div>
            <span >Add To compare</span>
            <mat-form-field appearance="fill" class="compare-planogram-search-input">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput type="text" [(ngModel)]="search_input" placeholder="Search Planogram"
                    (ngModelChange)="updateFilter()">
                <!-- <em class="bi bi-funnel"></i> -->
            </mat-form-field>
        </div>
        
        <div class="buttons" >
            <button mat-raised-button color="warn" [disabled]="planogram_ids.length < 2" (click)="comparePlanograms()" >
                <em class="bi bi-check-circle-fill"></em>
                Compare Now
            </button>
            <button mat-icon-button class="close-button" disableRipple [mat-dialog-close]="true">
                <mat-icon class="close-icon" >close</mat-icon>
            </button>
        </div>
        
    </div>
  
    <div class="warning" > Note: For Comparison Maximum 3 Planogram will be selected.</div>

    <div style="width: 100%; height: calc(100% - 100px); overflow:auto">
        <!-- <ng-template #mytable let-planograms="data_source" [ngTemplateOutlet]="mytable"
            [ngTemplateOutletContext]="{data_source: getDataSource()}"> -->
        <table id="planogram-list-table" mat-table *ngIf="dataSource.data.length > 0" aria-describedby=""
            [dataSource]="dataSource" #mytable matSort (matSortChange)="announceSortChange($event)"
            class="my-table mat-elevation-z8">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th style="padding-left: 10px;" mat-header-cell *matHeaderCellDef mat-sort-header> Name
                </th>
                <td mat-cell *matCellDef="let planogram" class="planogram-name-cell" >
                    <em [ngClass]="{'bi-check-square text-danger' : planogram_ids.includes(planogram.id) ,
                    'bi-square default-color' : !planogram_ids.includes(planogram.id)}"
                    class="bi" (click)="$event.stopPropagation(); selectPlanogram(planogram.id)" ></em> {{planogram.name}} </td>
            </ng-container>

            <!-- Channel Column -->
            <ng-container matColumnDef="channel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Channel </th>
                <td mat-cell *matCellDef="let planogram"> {{planogram.channel.value}} </td>
            </ng-container>

            <!-- Sub Segment Column -->
            <ng-container matColumnDef="sub_channel">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub-Channel </th>
                <td mat-cell *matCellDef="let planogram"> {{planogram.sub_channel.value}} </td>
            </ng-container>

            <!-- Business Goals Column -->
            <ng-container matColumnDef="business_objective">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Business Goals </th>
                <td mat-cell *matCellDef="let planogram">
                    {{getAllBusinessObjectives(planogram.business_objective)}} </td>
            </ng-container>

            <!-- Created By Column -->
            <ng-container matColumnDef="user_name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                <td mat-cell *matCellDef="let planogram"> {{planogram.user_name}} </td>
            </ng-container>
            
            <!-- Business Goals Column -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                <td mat-cell *matCellDef="let planogram">
                    <span class="planogram-status" [ngClass]="{'active' : planogram.status === CONFIG.PLANOGRAMSTATUS.ACTIVE,
                            'inactive': planogram.status === CONFIG.PLANOGRAMSTATUS.INACTIVE, 
                         'draft': planogram.status === CONFIG.PLANOGRAMSTATUS.DRAFT}">
                        {{planogram.status}}
                    </span>
                </td>
            </ng-container>

            <!-- (click)="handleRowClick(row)" -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectPlanogram(row.id)" ></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="6">No data matching with the filter</td>
            </tr>

        </table>
    </div>

    <app-loader [is_show_loader]="(is_show_loader | async)!" ></app-loader>

</mat-dialog-content>