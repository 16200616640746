import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartData, ChartOptions } from 'chart.js';
import { SubscriptionLike, Observable } from 'rxjs';
import { CONFIG } from 'src/helper/app-config';
import { abbreviateNumber, getChartColor, getPlanogramImageUrl } from 'src/helper/app-utils';
import { PlanogramStateService } from 'src/services/planogram-state.service';
import { ComparePlanogramDialogComponent } from '../compare-planogram-dialog/compare-planogram-dialog.component';
import { ComparePlanogramImagePreviewComponent } from '../compare-planogram-image-preview/compare-planogram-image-preview.component';

@Component({
  selector: 'app-compare-planogram',
  templateUrl: './compare-planogram.component.html',
  styleUrls: ['./compare-planogram.component.css']
})
export class ComparePlanogramComponent implements OnInit {

  constructor(private planogramState: PlanogramStateService, public dialog: MatDialog, private router: Router,
    private activatedRoute: ActivatedRoute, public location: Location) { 

    this.activatedRoute.url.subscribe(url => {
    const router_state = this.router.getCurrentNavigation()?.extras.state;
    if (router_state) {
      this.compare_planogram_ids = router_state['ids'] ? router_state['ids'] : this.compare_planogram_ids;
      this.is_from_publish = router_state['is_from_publish'] ? router_state['is_from_publish'] : false;
    }
    })

  }

  CONFIG = CONFIG;
  compare_planogram_data: any;
  compare_planogram_ids: number[] = [];
  is_from_publish : boolean = false;
  brandchartcolor: any= {};
  categorychartcolor: any= {};

  chart_options: ChartOptions = {
    responsive: true, maintainAspectRatio: false, animation: false,
    // layout: { padding: {top:30, bottom:30} },
    plugins: { legend: { display:false, position: 'right', align:'center' , fullSize:false}, },
    scales:{
      xAxes:{display:false, beginAtZero:true},
      yAxes:{display:false, beginAtZero:false},
      ticks:{beginAtZero:false, display:false}
    },
    indexAxis: 'y',
    
  }

  compare_planogram_data_subscription: SubscriptionLike;
  brandchartcolor_subscription: SubscriptionLike;
  categorychartcolor_subscription: SubscriptionLike;

  compare_planogram_data_observer: Observable<Object> = this.planogramState.compare_planogram_data;
  is_show_loader: Observable<boolean> = this.planogramState.loader;
  brandchartcolor_observer: Observable<any> = this.planogramState.brandchartcolor;
  categorychartcolor_observer: Observable<any> = this.planogramState.categorychartcolor;

  ngOnInit(): void {

    this.compare_planogram_data_subscription = this.compare_planogram_data_observer.subscribe((d: any) => {
      if (Object.keys(d).length > 0 ) {
        this.compare_planogram_data = d;

        if(this.compare_planogram_ids.length === 0){
          this.compare_planogram_ids = Object.keys(d).map(id=> Number(id));
        }
      }
    })

    this.brandchartcolor_subscription = this.brandchartcolor_observer.subscribe((brandcolor) => {
      this.brandchartcolor = brandcolor;
    })

    this.categorychartcolor_subscription =
      this.categorychartcolor_observer.subscribe((categorycolor) => {
        const newCategoryColor: any = {};
        for (const key in categorycolor) {
          if (categorycolor.hasOwnProperty(key)) {
            newCategoryColor[key.toLowerCase()] = categorycolor[key];
          }
        }
        console.log('keys colors',newCategoryColor);
        this.categorychartcolor =newCategoryColor;
      });
  }

  ngOnDestroy(): void {
    this.compare_planogram_data_subscription.unsubscribe();
    this.brandchartcolor_subscription.unsubscribe();
    this.categorychartcolor_subscription.unsubscribe();
  }

  goBack(){
    if(this.is_from_publish){
      localStorage.setItem('stepper_index' , '3')
    }
    this.location.back();
  }

  getPlanogramPreviewImageSrc=(id:any)=>{
    return getPlanogramImageUrl(id, this.compare_planogram_data[id].planogram_name, false)
  }

  getBarChartData = (category:any, is_category_distribution: boolean)=>{
    let chart_data: ChartData = {} as any;
    if (category) {

      chart_data = { labels: [''],
      datasets: Object.keys(category).map((key:any)=> {
        let config = is_category_distribution ? this.categorychartcolor : this.brandchartcolor;
        let color = getChartColor([key], config);
        return {data: [category[key]], label:key, stack:'', barThickness: 20, backgroundColor: color, hoverBackgroundColor: color, hoverBorderColor: color }
      }),
    };
    }
    // console.log("chart data :: ", chart_data)
    return chart_data;
  }

  getLabels = (key:string)=>{
    let labels : string[]=[];
    if(Object.keys(this.compare_planogram_data).length > 0){
      this.compare_planogram_ids.forEach(id=>{
        if(Object.keys(this.compare_planogram_data[id]['assortment'][key]).length > 0){
          let categories = Object.keys(this.compare_planogram_data[id]['assortment'][key])
          labels = Array.from(new Set([...labels, ...categories]))
        }
      })
    }
    return labels;
  }
  getConfigLabel=(str:string)=>{
    str=str.toLowerCase()
    return str.replace(/\s+/g, '')
   }
  abbreviateNumber=(number:number)=>{
    return abbreviateNumber(number)
  }

  openImagePreviewDialog=()=>{
      const dialogRef = this.dialog.open(ComparePlanogramImagePreviewComponent, {
        height: '90%',
        width: '80%',
        data: {
          compare_planogram_ids:this.compare_planogram_ids,
          compare_planogram_data: this.compare_planogram_data
        }
      });
  }

  removePlanogram = (id:number)=>{
    this.compare_planogram_ids = this.compare_planogram_ids.filter(i=> i !== id)
  }

  addPlanogramToCompare(){
    if(this.compare_planogram_ids.length < 3){
      const dialogRef = this.dialog.open(ComparePlanogramDialogComponent, {
        height: '90%',
        width: '80%',
        data: {
          planogram_ids:this.compare_planogram_ids
        }
      });
    }
  }

}
