import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, SubscriptionLike } from 'rxjs';
import { CONFIG } from 'src/helper/app-config';
import { GetPlanogramBodyParams, PlanogramSegmentEntityModal } from 'src/modals/planogram';
import { PlanogramStateService } from 'src/services/planogram-state.service';
import { ConfirmDialogModalComponent } from '../confirm-dialog-modal/confirm-dialog-modal.component';

interface DialogData{
  planogram_ids:number[];
  is_from_publish:boolean;
}

@Component({
  selector: 'app-compare-planogram-dialog',
  templateUrl: './compare-planogram-dialog.component.html',
  styleUrls: ['./compare-planogram-dialog.component.css']
})
export class ComparePlanogramDialogComponent implements OnInit {

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private _liveAnnouncer: LiveAnnouncer,
    private planogramState: PlanogramStateService, public dialog: MatDialog,
    private dialogRef: MatDialogRef<ComparePlanogramDialogComponent>) {
      this.planogram_ids = data.planogram_ids;
  }

  dataSource = new MatTableDataSource();
  CONFIG = CONFIG;

  compare_planogram: Observable<Object> = this.planogramState.compare_planogram;
  is_show_loader: Observable<boolean> = this.planogramState.loader;
  // draft_planogram: Observable<Object> = this.planogramState.draft_planogram;

  saved_planogram_subscription: SubscriptionLike;

  displayedColumns: string[] = ['name', 'channel', 'sub_channel', 'business_objective',  'user_name', 'status'];
  search_input = '';
  planogram_ids: number[] = [];


  @ViewChild(MatSort, { static: false }) set sort(value: MatSort) {
    this.dataSource.sort = value;
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;

    this.saved_planogram_subscription = this.compare_planogram.subscribe(pl => {
        let data = Object.values(pl).filter(p=> p.status === CONFIG.PLANOGRAMSTATUS.ACTIVE);
        data.sort((a,b)=> new Date(b.last_edited_date).getTime() - new Date(a.last_edited_date).getTime());
        this.dataSource.data = data
    })

    if(this.dataSource.data.length === 0){
      let body_params: GetPlanogramBodyParams = { limit: 100, offset: 0 , search:'', status:'active'}
      this.planogramState.getComparePlanograms(body_params);
    }
    
  }

  ngOnDestroy() {
    this.saved_planogram_subscription.unsubscribe();
  }

  ngAfterViewInit() {

    this.dataSource.filterPredicate = ((data: any, filter) => {
      let busniss_objectives = this.getAllBusinessObjectives(data.business_objective);

      return data.name.toLowerCase().includes(filter) ||
        data.channel.value.toLowerCase().includes(filter) ||
        data.sub_channel.value.toLowerCase().includes(filter) ||
        busniss_objectives.toLowerCase().includes(filter);
    });

    this.dataSource.sortingDataAccessor = (item: any, property) => {
      switch (property) {
        case 'channel':
        case 'sub_channel': {
          return item[property].value;
        }

        case 'business_objective': {
          return this.getAllBusinessObjectives(item[property]);
        }

        case 'date': {
          let newDate = new Date(item.date);
          console.log(newDate);
          return newDate;
        }
        case 'name':
        case 'status':
        default: {
          return item[property];
        }
      }
    };
  }

  getAllBusinessObjectives = (business_ob: PlanogramSegmentEntityModal[]) => {
    let busniss_objectives = '';
    if (business_ob) {
      business_ob.forEach((ob, i) => {
        busniss_objectives += ob.value;
        if (i !== business_ob.length - 1) {
          busniss_objectives += ' , ';
        }
      })
    }
    return busniss_objectives
  }

  selectPlanogram = (id:number)=>{
    if(this.planogram_ids.includes(id)){
      this.planogram_ids = this.planogram_ids.filter(p_id => p_id !== id)
    }else if(this.planogram_ids.length < 3){
      this.planogram_ids  = this.planogram_ids.concat(id)
    }else{
      this.dialog.open(ConfirmDialogModalComponent, {
        id: 'dialog2',
        height: '200px',
        width: '500px',
        data: {
          header_msg: 'Cannot select more than 3 planograms.',
          header_msg_description: 'Please clear existing selection to select other planograms.',
          ok_action: 'Continue'
        },
      });
    }
  }

  comparePlanograms(){
    this.planogramState.comparePlanogram(this.planogram_ids , this.data.is_from_publish);
    this.dialogRef.close();
  }

  updateFilter = () => {
    this.dataSource.filter = this.search_input.trim().toLowerCase();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

}
