export const CONFIG = {
    CMTOPIXCEL : 37.8,
    CMTOPIXCELHALF__ : 18.9,
    CMTOPIXCELHALF___: 9.4,
    CMTOPIXCELHALF____ : 4.7,
    CMTOPIXCELHALF : 4.7,
    PLACEHOLDER_HEIGHT_CLEARANCE:30,
    SHELF_HEIGHT_CLEARANCE:10,
    MMTOPIXCEL: 3.77,
    WIDTHCONSTANT:6,
    HEIGHTCONSTANT:10,
    NUMBER_SUFFIX: ["", "K", "M", "B", "T"],
    DECIMALPLACE:1,
    PATH: {
        LOGIN:'login',
        PLANOGRAMS: 'planograms',
        PUBLISHED:'published',
        DRAFT:'drafts',
        EMPTY: '',
        CPCMASTER:'',
        NEWPLANOGRAM:'new-planogram',
        PLANOGRAM:'planogram/',
        COMPAREPLANOGRAM:'compare-planograms',
        AUTHRESPONSE:'auth-response'
    },
    API:{
        SAVEUSERTOKEN:'api/v1/users/savetoken',
        SKUGETALL:'api/v1/sku/getplanogramspecificsku',
        // SKUGETALL:'api/v1/sku/getall',
        GETPICOSSKUS:'api/v1/sku/getplanogramspecificpicos',
        GETSPECIFICSKU:'api/v1/sku/getspecific',
        // GETSPECIFICPLANOGRAM:'api/v1/planogram/getspecificplanogram',
        GETSPECIFICPLANOGRAM:'api/v1/planogram/planogram',
        CHECKUNIQUEPLANOGRAM:'api/v1/planogram/checkuniqueplanogramname',
        GETPLANOGRAMBASEDATA:'api/v1/planogram/getbasedata',
        GETPLANOGRAMS:'api/v1/planogram/getallplanograms',
        SAVEPLANAGRAM:'api/v1/planogram/savebasedata',
        EDITPLANAGRAM:'api/v1/planogram/editbasedata',
        DELETEPLANAGRAM:'api/v1/planogram/deleteplanogram',
        GETPLANOGRAMSAVEDBASEDATA:'api/v1/planogram/getsavedbasedata',
        COOLERTEMPLATEBASEDATA:'api/v1/cooler/getbasedatatemplate',
        SEPECIFICSCREENCONFIG:"getspecificscreenconfig",
        CHECKUNIQUETEMPLATE:'api/v1/cooler/checkuniquetemplatename',
        SAVETEMPLATE:'api/v1/cooler/savebasetemplatedata',
        EDITTEMPLATE:'api/v1/cooler/editbasetemplatedata',
        GETSAVEDTEMPLATEDATA:'api/v1/cooler/getsavedbasetemplatedata',
        GETALLTEMPLATES:'api/v1/cooler/getalltemplates',
        SAVETEMPLATETOPLANOGRAM:'api/v1/cooler/savetemplatetoplanogram',
        EDITCOOLERFACING:'api/v1/cooler/editfacings',
        SAVEPLANOGRAMSKUPLACEMENT:'api/v1/planogram/saveplanogramskuplacement',
        PUBLISHPLANOGRAM:'api/v1/planogram/publishplanogram',
        COMPAREPLANOGRAM:'api/v1/planogram/compareplanogram',
        // AUTOFILLSKUS:'api/v1/planogram/autofill',
        AUTOFILLSKUS:'api/v1/sku/autofill',
        RECOMMANDATIONS:'api/v1/sku/recommendations',
        // RECOMMANDATIONS:'api/v1/cooler/getrecommendations',
        ADDITIONALINFO:'api/v1/cooler/getadditionalinfo',
        CALCULATETHROUGHPUT:'api/v1/sku/calculatethroughput',
        VERTICALAUTOFILL:'api/v1/sku/verticalautofill',
        COPYPLANOGRAM:'api/v1/planogram/cloneplanogram',
    },
    PLANOGRAMSTATUS:{ACTIVE:'Active', INACTIVE:'Inactive', DRAFT:'Draft'},
    COOLERFACINGMODE:{
        BATCH:'batch',
        INDIVIDUAL:'individual'
    },
    COOLERTRAFFICDIRECTIONS:{
        DOOR1:['Left', 'Right'],
        DOOR2:['Center'],
        DOOR3:['Left Center', 'Right Center' ]},
    ICSHELFMAXVOLUME: 750,
    SKU_PLACEMENT_WARNING :{
        COPY_SKU_TO_ADJACENT : 'No adjacent space remaining.',
        SKU_DROP_ERROR_MSG:'No sufficient space remaining for target SKU.',
        SKU_DROP_IN_WRONG_SHELF: 'SKU is not added as it does not placed in right shelf.',
        SKU_ADDITION_IN_WRONG_SHELF:'Some SKUs are not added as they do not placed in right shelf.',
        SKU_REPLACEMENT_IN_WRONG_SHELF: 'Some SKUs are not replaced as they do not placed in right shelf.',
        FCSKUERRORMESSAGE:'SKU cannot be added as the shelf is reserved for FC SKUs only.',
        ICSKUERRORMESSAGE:'SKU cannot be added as the shelf is reserved for IC SKUs only.',
        FCICSKUERRORMESSAGE:'SKUs cannot be added as the shelf is reserved for IC/FC SKUs only.'
    },
    COOLEROVERSHOOTING:{
        SHELFWIWIDTH: 'overshooting permissible internal width.',
        SHELFHEIGHT: 'overshooting permissible internal height.'
    },
    VERTICAL_BLOCK_BRAND_ERROR: "Selected brand does not have IC/FC SKUs, please select additional brands to complete the block."
    // CATEGORYCHARTCOLOR:{ 'juice': '#ffff00', 'ssd': '#ff0000', 'stills': '#008000', 'water': '#00bfff', 'rtd tea': '#00008b', 'syrups':'#ff8c00', 'flv water':'#b0c4de' } as {[key: string]: string},
    // BRANDCHARTCOLOR: {'brisa':'#1e90ff','coca-cola':'#ad0505', 'del valle':'#ffff00', 'kola roman':'#ff0000', 'powerade': '#00bfff', 'schweppes':'#ffd700', 'premio':'#cd5c5c', 'quatro':'#663399', 'sprite':'#32cd32',
    // 'tai': '#2e8b57', 'fuze tea':'#ffb6c1' , 'manantial':'#90ee90', 'vallefrut':'#800080'} as {[key: string]: string},
}