import { Injectable, OnDestroy } from '@angular/core';
import { StateService } from './state.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { GetPlanogramBodyParams, PlanogramModal, SKUModal, PlanogramBaseDetailModal, CoolerTemplateBaseDataModal, ScreenDataModal, APIErrorObj, PlanogramBaseDetailInfo } from 'src/modals/planogram';
import { PlanogramService } from './planogram.service';
import { Router } from '@angular/router';
import { CONFIG } from 'src/helper/app-config';
import { getPlanogramImageUrl, sortSkusAccordingToBrandOrderAndSize } from 'src/helper/app-utils';
import { CommandService, SkuCommandFactory, SkuCommandMap } from './command.service';
import { environment } from 'src/environments/environment';
import { environment as dev_environment } from 'src/environments/environment.dev';
import { AzureBlobStorageService } from './azure-blob-storage.service';
import { ConfirmDialogModalComponent } from 'src/components/confirm-dialog-modal/confirm-dialog-modal.component';
import { MatDialog } from '@angular/material/dialog';
import * as htmlToImage from 'html-to-image';

interface PlanogramState {
  is_loader: boolean;
  loading_count: number;
  sku_list: SKUModal[];
  sku_info: Object;
  cooler_config: PlanogramModal;
  placed_sku_list: SKUModal[];
  next_event_api_response: boolean;
  planogram_unique_name_error: string;
  planogram_base_details: PlanogramBaseDetailInfo;
  saved_planogram?: Object;
  draft_planogram: Object;
  is_more_planograms:boolean;
  is_planogram_edit: boolean;
  edit_planogram_detail: any;
  selected_planogram_base_data: Object;
  cooler_template_base_data: CoolerTemplateBaseDataModal;
  specific_screen_data: ScreenDataModal[];
  template_unique_name_error: string;
  create_new_template_success: boolean;
  selected_cooler_template: Object;
  selected_cooler_template_base_data: Object;
  saved_templates: Object;
  error_obj: APIErrorObj;
  is_update_highlight_sku_placeholder: boolean;
  picos_skus: SKUModal[];
  selected_planogram_image: string;
  compare_planogram_data: Object;
  recommandations: any[],
  compare_planogram:Object;
  throughput_metrics: any[];
  autofill_throughput_metrics: any[];
  close_copy_planogram_dialog:boolean;
  brandchartcolor:any;
  categorychartcolor:any;
  planogram_metrics:any;
  brands:any;
}

const initialState: PlanogramState = {
  is_loader: false,
  loading_count: 0,
  sku_list: [],
  sku_info: {},
  cooler_config: {} as any,
  is_planogram_edit: false,
  edit_planogram_detail: {},
  placed_sku_list: [],
  next_event_api_response: false,
  planogram_unique_name_error: '',
  planogram_base_details: {} as any,
  saved_planogram: undefined,
  draft_planogram: {},
  is_more_planograms:true,
  selected_planogram_base_data: {} as any,
  cooler_template_base_data: {} as any,
  specific_screen_data: [],
  template_unique_name_error: '',
  create_new_template_success: false,
  selected_cooler_template: {} as any,
  selected_cooler_template_base_data: {} as any,
  saved_templates: {},
  error_obj: { is_error: false, error_msg: '' },
  is_update_highlight_sku_placeholder: false,
  picos_skus: [],
  selected_planogram_image: '',
  compare_planogram_data: {} as any,
  recommandations: [],
  compare_planogram:{},
  throughput_metrics:[],
  autofill_throughput_metrics:[],
  close_copy_planogram_dialog:false,
  brandchartcolor:{},
  categorychartcolor:{},
  planogram_metrics:{},
  brands:{},
};

@Injectable({
  providedIn: 'root',
})
export class PlanogramStateService extends StateService<PlanogramState> implements OnDestroy {

  available_skus: Observable<SKUModal[]> = this.select((state) => state.sku_list);
  sku_info: Observable<Object> = this.select((state) => state.sku_info);

  planogram_placed_skus: Observable<SKUModal[]> = this.select((state) =>  state.placed_sku_list);
  cooler_config_info: Observable<PlanogramModal> = this.select((state) => state.cooler_config);
  loader: Observable<boolean> = this.select((state) => state.is_loader);

  next_event_api_response: Observable<boolean> = this.select(state => state.next_event_api_response);

  planogram_name_error: Observable<string> = this.select((state) => state.planogram_unique_name_error);
  planogram_base_details: Observable<PlanogramBaseDetailInfo> = this.select((state) => state.planogram_base_details);
  all_planogram: Observable<Object> = this.select((state) => state.saved_planogram!);
  is_more_planograms: Observable<boolean> = this.select((state) => state.is_more_planograms);
  is_planogram_edit: Observable<boolean> = this.select((state) => state.is_planogram_edit);
  edit_planogram_detail: Observable<any> = this.select((state) => state.edit_planogram_detail);
  selected_planogram_base_data: Observable<Object> = this.select((state) => state.selected_planogram_base_data);
  cooler_template_base_data: Observable<CoolerTemplateBaseDataModal> = this.select((state) => state.cooler_template_base_data);
  specific_screen_data: Observable<ScreenDataModal[]> = this.select((state) => state.specific_screen_data);
  template_unique_name_error: Observable<string> = this.select((state) => state.template_unique_name_error);
  create_new_template_success: Observable<boolean> = this.select((state) => state.create_new_template_success);
  selected_cooler_template: Observable<any> = this.select((state) => state.selected_cooler_template);
  selected_cooler_template_base_data: Observable<any> = this.select((state) => state.selected_cooler_template_base_data);
  saved_templates: Observable<any> = this.select((state) => state.saved_templates);
  error_obj: Observable<APIErrorObj> = this.select(state => state.error_obj);
  is_update_highlight_sku_placeholder: Observable<boolean> = this.select(state => state.is_update_highlight_sku_placeholder);
  picos_skus: Observable<SKUModal[]> = this.select((state) => state.picos_skus);
  selected_planogram_image: Observable<string> = this.select((state) => state.selected_planogram_image);
  compare_planogram_data: Observable<Object> = this.select((state) => state.compare_planogram_data);
  recommandations: Observable<any[]> = this.select((state) => state.recommandations);
  compare_planogram: Observable<Object> = this.select((state) => state.compare_planogram);
  throughput_metrics: Observable<any[]> = this.select((state) => state.throughput_metrics);
  autofill_throughput_metrics: Observable<any[]> = this.select((state) => state.autofill_throughput_metrics);
  close_copy_planogram_dialog:  Observable<boolean> = this.select((state) => state.close_copy_planogram_dialog);
  brandchartcolor: Observable<any> = this.select((state) => state.brandchartcolor);
  categorychartcolor: Observable<any> = this.select((state) => state.categorychartcolor);
  planogram_metrics: Observable<any> = this.select((state) => state.planogram_metrics);
  sku_brands: Observable<any> = this.select((state) => state.brands);
  private commandFactory: SkuCommandFactory;
  private commandSuccessSubscription!: Subscription;

  constructor(private apiService: PlanogramService, private router: Router, private commandService: CommandService, private blobService: AzureBlobStorageService, public dialog: MatDialog) {
    super(initialState);
      this.commandFactory = new SkuCommandFactory();
      this.commandSuccessSubscription = commandService.commandSuccess().subscribe((args) => {
          const action = args.action; //  Execute, Undo, or Redo
          // TODO: you might want to sync your data here
          if(action === 1 || action === 2){
            // console.log("undo | redo state update :: ", args.result)
            if(args.result.collection){
              this.setState({ placed_sku_list: args.result.collection, is_update_highlight_sku_placeholder: true });
            }
          }
          // console.log("args :: ", args)
        });
  }

  ngOnDestroy(): void {
    this.commandSuccessSubscription.unsubscribe();
  }


  setChartColorConfig(config:any){
    this.setState({...config})
  }

  setPlanogramImage(image_url: string) {
    this.setState({ selected_planogram_image: image_url })
  }

  setPlanogramEdit(is_edit: boolean, id: number) {
    let edit_planogram_detail = {}, selected_planogram_base_data = {};
    if (id !== -1) {
      edit_planogram_detail = { id: id }
    }
    if (is_edit) {
      this.getPlanogramSavedData(id);
    }

    this.setState({ is_planogram_edit: is_edit, edit_planogram_detail: edit_planogram_detail, selected_planogram_base_data: selected_planogram_base_data })
  }

  setCreateNewTemplateSuccessFlag(is_success: boolean) {
    if (!is_success) {
      this.setState({ create_new_template_success: is_success, selected_cooler_template: {}, selected_cooler_template_base_data: {} })
    } else {
      this.setState({ create_new_template_success: is_success })
    }
  }

  setSelectCoolerInfo(info: any) {
    if (info.id) {
      this.setState({ selected_cooler_template: info })
    } else {
      this.setState({ selected_cooler_template: info, selected_cooler_template_base_data: {} })
    }
  }

  openEditAccessErrorPrompt(msg: string, next_event: boolean = false, is_navigate: boolean = false){
    this.setLoaderState(false);
    const dialogRef = this.dialog.open(ConfirmDialogModalComponent, {
      // height: '200px',
      width: '500px',
      data: {
        header_msg: msg,
        header_msg_description: '',
        cancel_action: 'Cancel',
        ok_action: 'Ok'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.setState({selected_planogram_base_data: JSON.parse(JSON.stringify(this.state.selected_planogram_base_data))})
      if (result) {
        if(is_navigate){
          this.router.navigateByUrl(CONFIG.PATH.PLANOGRAMS);
        }
        this.setState({ next_event_api_response: next_event})
      }
    });
  }

  setErrorState(msg: string, is_loader?: boolean) {
    if (msg === '') {
      this.setState({ error_obj: { is_error: false, error_msg: msg } })
    } else{
      msg = environment.environment_name !== dev_environment.environment_name  ? 'Something went wrong.' : msg;
      this.setState({ error_obj: { is_error: true, error_msg: msg } })
    }
    if (is_loader && this.state.is_loader) {
      this.setLoaderState(false);
    }
  }

  setSKUInfo(sku: any) {
    this.setState({ sku_info: sku });
  }

  setNextEventAPIResponse(is_success: boolean) {
    this.setState({ next_event_api_response: is_success })
  }

  setLoaderState(is_loader: boolean) {
    let loading_count = this.state.loading_count;
    if (is_loader) {
      this.setState({ is_loader: is_loader, loading_count: loading_count + 1 })
    } else {
      loading_count = loading_count - 1;
      // console.log("loading count :: ", loading_count)
      if (loading_count <= 0) {
        this.setState({ loading_count: 0, is_loader: is_loader })
      } else {
        this.setState({ loading_count: loading_count })
      }
    }
  }

  clearPlanogramNameError(){
    this.setState({planogram_unique_name_error: ''})
  }

  clearTemplateNameError(){
    this.setState({template_unique_name_error: ''})
  }

  updateSkuPlacing(sku_placing_list: SKUModal[]) {
    this.executeCommand('update-properties', { target: 'placed_sku_list', collection: this.state.placed_sku_list ,  newValues: sku_placing_list });
    this.setState({ placed_sku_list: sku_placing_list });
  }

  public executeCommand<K extends keyof SkuCommandMap>(key: string, commandData: SkuCommandMap[K]) {
    const command = this.commandFactory.create(key, commandData);
    this.commandService.execute(key, command);
  }

  updateHighlightSkuPlaceholder(is_update: boolean) {
    this.setState({ is_update_highlight_sku_placeholder: is_update });
  }

  clearAutofillThroughputCalculation(){
    this.setState({autofill_throughput_metrics: []});
  }


  // API CALLS

  getPicosSKUsList(planogram_id: number, scenario: string) {
    this.apiService.getPicosSKUsList(planogram_id,'picos', scenario)
      .subscribe({
        next: (res: any) => {
          if(res.code === 400){
            this.setErrorState(res.data)
          }else{
            this.setState({ picos_skus: sortSkusAccordingToBrandOrderAndSize(res) });
          }
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error)
        }
      });
  }

  getSKUsList(planogram_id: number, filter: string, scenario: string) {
    this.setState({ sku_list: [] })
    this.apiService.getSKUsList(planogram_id, filter, scenario)
      .subscribe({
        next: (res: any) => {
          if(res.code === 400){
            this.setErrorState(res.data)
          }else{
          let sku_list: any[] = [...new Map(res.all_skus.concat(res.picos_recom).map((item:any) =>
          [item.sku_id, item])).values()];
          this.setState({ sku_list: sortSkusAccordingToBrandOrderAndSize(sku_list), brands: res.brand });
          }
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error)
        }
      });
  }

  getSpecificSku(id: number) {
    // this.setState({ is_loader: true })
    this.apiService.getSpecificSku(id)
      .subscribe({
        next: (res: any) => {
          this.setState({
            sku_info: res
            // is_loader: false
          });
        }, error: (error) => {
          console.log("error :: ", error)
          // this.setState({ is_loader: false })
        }
      });
  }

  getSpecificPlanogram(id: number) {
    this.setState({ is_loader: true })
    this.apiService.getSpecificPlanogram(id)
      .subscribe({
        next: (res: any) => {
          let cooler_config;
          if (res.template_meta) {
            cooler_config = { ...res.cooler_info, ...res.template_meta, vertical_orientation: res.vertical_orientation };
          }
          let selected_planogram_base_data = Object.keys(this.state.selected_planogram_base_data).length > 0 ? this.state.selected_planogram_base_data : res[0].planogram_meta

          this.setState({
            cooler_config: cooler_config,
            throughput_metrics: [res.commercials],
            placed_sku_list: res.sku_info,
            is_update_highlight_sku_placeholder: true,
            selected_planogram_base_data: selected_planogram_base_data,
            is_loader: false
          });
        }, error: (error) => {
          console.log("error :: ", error)
          // this.setState({ is_loader: false })
          this.setErrorState(error, true)

        }
      });
  }

  getSKUsListAndCoolerConfig(id: number, scenario: string) {
    if (id !== undefined) {
      this.setLoaderState(true);
      let required_keys : string[] = ['depth_of_sku', 'dim_benchmark_clearance','pack_type_filling_order', 'fc_sku_filling_order', 'ic_sku_filling_order', 'brand_filling_order']
      this.getSpecificScreenData(required_keys);
      this.getAdditionalInfo(id);
      this.getRecommandations(id);
      this.setState({ sku_list: [] })
      forkJoin([this.apiService.getSpecificPlanogram(id),
      this.apiService.getSKUsList( id, 'all', scenario)])
        .subscribe({
          next: (res: any) => {
            // console.log("res :: ", res)
            if (res[1].code === 400) {
              this.setErrorState(res[1].data, true)
            } else if (res[0].code === 400) {
              this.setErrorState(res[0].data, true)
            }
            else {
              let cooler_config;
              if (res[0].template_meta) {
                cooler_config = { ...res[0].cooler_info, ...res[0].template_meta, vertical_orientation: res[0].vertical_orientation };
              }
              let selected_planogram_base_data = Object.keys(this.state.selected_planogram_base_data).length > 0 ? this.state.selected_planogram_base_data : res[0].planogram_meta
              let sku_list : any[]= [...new Map(res[1].all_skus.concat(res[1].picos_recom).map((item:any) =>
                [item.sku_id, item])).values()];
              this.setState({
                cooler_config: cooler_config, selected_planogram_base_data: selected_planogram_base_data, throughput_metrics: [res[0].commercials],
                placed_sku_list: res[0].sku_info, sku_list: sortSkusAccordingToBrandOrderAndSize(sku_list), brands: res[1].brand,
                is_update_highlight_sku_placeholder: true
              })
            }
            this.setLoaderState(false);
          }, error: (error) => {
            console.log("error :: ", error)
            this.setErrorState(error, true)
          }
        });
    }
  }

  verifyPlanogramUniqueName(name: string, planogram_detail ?:{planogram_id: number,planogram_name:string}) {
    if(planogram_detail){
      this.setLoaderState(true);
    }
    this.apiService.verifyPlanogramuniqueName(name)
      .subscribe({
        next: (res: any) => {
          if (res.code === 400) {
            this.setState({ planogram_unique_name_error: res.data });
            this.setLoaderState(false);
          } else {
            if(planogram_detail){
              this.copyPlanogram(planogram_detail, name)
              this.setState({ close_copy_planogram_dialog: true});
            }
            this.setState({ planogram_unique_name_error: '' });
          }
        }, error: (error) => {
          error = typeof error !== 'string' ? 'Please enter valid planogram name.' : error;
          console.log("error :: ", error)
          this.setErrorState(error, planogram_detail !== null)
          this.setLoaderState(false);
        }
      });
  }

  getBaseData() {
    this.setLoaderState(true)
    this.apiService.getbasedata()
      .subscribe({
        next: (res: any) => {
          this.setState({
            planogram_base_details: res
            // business_goals: res.business_goals,
            // segment: res.segment,
            // channel: res.channel,
            // sub_channel: res.sub_channel
          });
        this.setLoaderState(false)
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error, true)
        }
      });
  }

  getAllPlanograms(body_params: GetPlanogramBodyParams, is_loader: boolean = true) {
    if (is_loader){
      this.setLoaderState(is_loader);
    }
    this.apiService.getAllPlanograms(body_params).subscribe({
      next: (res: any) => {
        if (res.code === 400) {
          this.setErrorState(res.data, is_loader)
        } else {
          let saved_planogram = body_params.offset === 0 ? res : {...this.state.saved_planogram , ...res}
          this.setState({ saved_planogram: saved_planogram, 
            is_more_planograms: Object.keys(res).length === body_params.limit })
        }
        if (is_loader)
          this.setLoaderState(false);
      }, error: (error) => {
        console.log("error :: ", error)
        this.setErrorState(error, is_loader)
      }
    });
  }

  getComparePlanograms(body_params: GetPlanogramBodyParams, is_loader: boolean = true) {
    if (is_loader){
      this.setLoaderState(is_loader);
    }
    this.apiService.getAllPlanograms(body_params).subscribe({
      next: (res: any) => {
        if (res.code === 400) {
          this.setErrorState(res.data, is_loader)
        } else {
          this.setState({ compare_planogram: {...this.state.compare_planogram , ...res} })
        }
        if (is_loader)
          this.setLoaderState(false);
      }, error: (error) => {
        console.log("error :: ", error)
        this.setErrorState(error, is_loader)
      }
    });
  }

  savePlanogramBaseDetails(info: PlanogramBaseDetailModal, is_save:boolean) {
    this.setLoaderState(true);

    this.apiService.savePlanogramBaseDetails(info)
      .subscribe({
        next: (res: any) => {

          this.setState({ next_event_api_response: is_save, is_planogram_edit: true, edit_planogram_detail: res })
          if(is_save)
          this.router.navigateByUrl(CONFIG.PATH.PLANOGRAM + res.id, { state: { screen_index: 1 } });
          else
          this.router.navigateByUrl(CONFIG.PATH.PLANOGRAMS);
          this.setLoaderState(false);
        }, error: (error) => {
          this.setErrorState(error, true)
          console.log("error :: ", error)
        }
      });
  }

  editPlanogramBaseDetails(info: PlanogramBaseDetailModal, is_save:boolean) {
    this.setLoaderState(true);
    this.apiService.editPlanogramBaseDetails(info)
      .subscribe({
        next: (res: any) => {

          this.setState({ next_event_api_response: is_save, is_planogram_edit: true, edit_planogram_detail: res })
          this.setLoaderState(false);
        }, error: (error) => {
          error = JSON.parse(error)
          if(error.pop_up_error){
            this.openEditAccessErrorPrompt(error.msg)
          }else{
            this.setErrorState(error, true)
            console.log("error :: ", error)
          }
        }
      });
  }

  deletePlanogram(id: number) {
    this.apiService.deletePlanogram(id)
      .subscribe({
        next: (res: any) => {
          if(res.delete_flag){
            let planogram_limit = document.cookie.split('planogram_limit=')[1] ? Number(document.cookie.split('planogram_limit=')[1]) : 0
            this.getAllPlanograms({ limit: planogram_limit, offset: 0, search: '', status:'all' }, false)
          }else{
            this.setState({ error_obj: { is_error: true, error_msg: res.msg } })
          }
        }, error: (error) => {
          this.setErrorState(error)
          console.log("error :: ", error)
        }
      });
  }

  getPlanogramSavedData(id: number) {
    this.setLoaderState(true)
    this.apiService.getPlanogramSavedData(id)
      .subscribe({
        next: (res: any) => {
          this.setState({ selected_planogram_base_data: res });
          this.setLoaderState(false)
        }, error: (error) => {
          this.setErrorState(error)
          console.log("error :: ", error, true)
        }
      });
  }

  getCoolerTemplateBaseData(id:number) {
    this.apiService.getCoolerTemplateBaseData(id)
      .subscribe({
        next: (res: any) => {
          this.setState({ cooler_template_base_data: res })
        }, error: (error) => {
          this.setErrorState(error)
          console.log("error :: ", error)
        }
      });
  }

  getSpecificScreenData(keys: string[]) {
    this.apiService.getSpecificScreenData(keys)
      .subscribe({
        next: (res: any) => {
          this.setState({ specific_screen_data: res })
        }, error: (error) => {
          this.setErrorState(error)
          console.log("error :: ", error)
        }
      });
  }

  verifyTemplateUniqueName(name: string) {
    this.apiService.verifyTemplateUniqueName(name)
      .subscribe({
        next: (res: any) => {
          if (res.code === 400) {
            this.setState({ template_unique_name_error: res.data });
          } else {
            this.setState({ template_unique_name_error: '' });
          }
        }, error: (error) => {
          error = typeof error !== 'string' ? 'Please enter valid planogram name.' : error;
          console.log("error :: ", error)
          this.setErrorState(error)
        }
      });
  }

  saveCoolerTemplate(info: any) {
    this.apiService.saveCoolerTemplate(info)
      .subscribe({
        next: (res: any) => {
          if (res.code === 400) {
            this.setErrorState(res.data)
          } else {
            this.setState({ create_new_template_success: true, selected_cooler_template: res })
          }
        }, error: (error) => {
          this.setErrorState(error)
          console.log("error :: ", error)
        }
      });
  }

  editCoolerTemplate(info: any) {
    this.apiService.editCoolerTemplate(info)
      .subscribe({
        next: (res: any) => {
          if (res.code === 400) {
            this.setErrorState(res.data)
          } else {
            this.setState({ selected_cooler_template: res, create_new_template_success: true })
          }
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error)
        }
      });
  }

  getSavedCoolerTemplateDataAndTemplateBaseData(planogram_id: number,id: number) {
    this.setLoaderState(true)
    forkJoin([this.apiService.getSavedCoolerTemplateData(id),
    this.apiService.getCoolerTemplateBaseData(planogram_id)])
      .subscribe({
        next: (res: any) => {
          this.setState({ selected_cooler_template_base_data: res[0], cooler_template_base_data: res[1] })
          this.setLoaderState(false)
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error, true)
        }
      });
  }

  getSavedCoolerTemplateData(id: number) {
    this.apiService.getSavedCoolerTemplateData(id)
      .subscribe({
        next: (res: any) => {
          this.setState({ selected_cooler_template_base_data: res })
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error)
        }
      });
  }

  getTemplates(id:number) {
    this.setLoaderState(true)
    this.apiService.getAllTemplates(id).subscribe({
      next: (res: any) => {
        if (res.code === 400) {
          this.setErrorState(res.data)
        } else {
          this.setState({ saved_templates: res })
        }
      this.setLoaderState(false)
      }, error: (error) => {
        console.log("error :: ", error)
        this.setErrorState(error, true)
      }
    })
  }

  saveTemplateToPlanogram(info: any, is_save:boolean) {
    this.setLoaderState(true);
    this.apiService.saveTemplateToPlanogram(info)
      .subscribe({
        next: (_res: any) => {
          if(is_save)
            this.setState({ next_event_api_response: is_save })
          else
            this.router.navigateByUrl(CONFIG.PATH.PLANOGRAMS);
          this.setLoaderState(false);
        }, error: (error) => {
          error = JSON.parse(error)
          if(error.pop_up_error){
            this.openEditAccessErrorPrompt(error.msg)
          }else{
            this.setErrorState(error, true)
            console.log("error :: ", error)
          }
        }
      });
  }

  editCoolerFacing(info: any) {
    this.apiService.editCoolerFacing(info)
      .subscribe({
       error: (error) => {
        error = JSON.parse(error)
          if(error.pop_up_error){
            this.openEditAccessErrorPrompt(error.msg, false)
          }else{
            this.setErrorState(error, true)
            console.log("error :: ", error)
          }
        }
      });
  }

  async savePlanogramSkuPlacement(info: any, is_save:boolean,name:string,id:any) {
    this.setLoaderState(true)
    let blob = await this.generateImage();

     this.apiService.savePlanogramSkuPalcement(info)
      .subscribe({
        next: (_res: any) => {
          if(is_save)
            this.setState({ next_event_api_response: is_save })
          else
            this.router.navigateByUrl(CONFIG.PATH.PLANOGRAMS);
            if (!(!is_save)) {
              if (blob) {
                this.blobService.uploadImage(blob, `${id}/latest/${environment.environment_name}/${name}-cooler-image`, (res: any) => {
                  // this.planogram_image_url = res;
                })
              }
      
            }
          this.setLoaderState(false)
        }, error: (error) => {
          error = JSON.parse(error)
          if(error.pop_up_error){
            this.openEditAccessErrorPrompt(error.msg)
          }else{
            this.setErrorState(error, true)
            console.log("error :: ", error)
          }
        }
        });
    }
    generateImage = async () => {
      let cooler_node = document.getElementById('image-capture-container')
      if (cooler_node) {
        return htmlToImage.toBlob(cooler_node, {
          width: cooler_node.scrollWidth , height: cooler_node.scrollHeight,
          canvasWidth: cooler_node.scrollWidth, canvasHeight: cooler_node.scrollHeight, 
          pixelRatio:1, skipAutoScale:false, quality:1}).then(function (blob) {
          return blob
        })
          .catch(function (error:any) {
            console.error('oops, something went wrong!', error);
          });
      }
  
    }
  publishPlanogram(info: any) {
    this.apiService.publishPlanogram(info)
      .subscribe({
        next: (_res: any) => {
          this.router.navigateByUrl(CONFIG.PATH.PLANOGRAMS);
        }, error: (error) => {
          error = JSON.parse(error)
          if(error.pop_up_error){
            this.openEditAccessErrorPrompt(error.msg, false, false)
          }else{
            this.setErrorState(error, true)
            console.log("error :: ", error)
          }
        }
      });
  }

  comparePlanogram(ids: number[], is_from_publish:boolean=false) {
    this.setLoaderState(true)
    this.apiService.comparePlanogram(ids)
      .subscribe({
        next: (res: any) => {
          this.setState({ compare_planogram_data: res })
          this.router.navigateByUrl(CONFIG.PATH.COMPAREPLANOGRAM, { state: { ids: ids ,is_from_publish:is_from_publish} })
          this.setLoaderState(false)
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error, true)
        }
      });
  }

  getPlanogramDataAndCoolerConfig(id: number) {
    this.setLoaderState(true)
    forkJoin([this.apiService.getSpecificPlanogram(id),
    this.apiService.comparePlanogram([id])])
      .subscribe({
        next: (res: any) => {
          let cooler_config;
          if (res[0].template_meta) {
            cooler_config = { ...res[0].cooler_info, ...res[0].template_meta, vertical_orientation: res[0].vertical_orientation };
          }
          let selected_planogram_base_data = Object.keys(this.state.selected_planogram_base_data).length > 0 ? this.state.selected_planogram_base_data : res[0].planogram_meta
          let planogram_data = res[1];
          this.setState({
            cooler_config: cooler_config, selected_planogram_base_data: selected_planogram_base_data, throughput_metrics: [res[0].commercials],
            placed_sku_list: res[0].sku_info, compare_planogram_data: planogram_data, is_update_highlight_sku_placeholder: true
          })
          this.setLoaderState(false)
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error, true)
        }
      });
  }

  autofillSkus(planogram_id: number) {
    this.setLoaderState(true);
    this.apiService.autoFillSKUS(planogram_id)
      .subscribe({
        next: (res: any) => {
          if (res.code === 400) {
            this.setErrorState(res.data, true)
          } else {
            this.setState({ placed_sku_list: res.sku_info, autofill_throughput_metrics : [res.commercials], throughput_metrics: [res.commercials] });
          }
          this.setLoaderState(false);
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error, true)
        }
      });
  }

  getRecommandations(id: number) {
    this.apiService.getRecommandations(id)
      .subscribe({
        next: (res: any) => {
          this.setState({ recommandations: res });
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error)
        }
      });
  }

  getAdditionalInfo(planogram_id : number) {
    this.apiService.getAdditionalInfo(planogram_id)
      .subscribe({
        next: (res_: any) => {
          this.setState({planogram_metrics: res_});
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error)
        }
      });
  }

  getThroughputCalculation(info:any) {
    this.apiService.calculateThroughput(info)
      .subscribe({
        next: (res: any) => {
          this.setState({throughput_metrics: res});
          // console.log("calculateThroughput res :: ", res)
        }, error: (error) => {
          this.setState({throughput_metrics: []});
          console.log("error :: ", error)
          this.setErrorState(error)
        }
      });
  }


  verticalAutofillSkus(planogram_id: number, block_config:any) {
    this.setLoaderState(true);
    this.apiService.verticalAutoFillSKUS(planogram_id, block_config)
      .subscribe({
        next: (v_res: any) => {
          if (v_res.code === 400) {
            this.setErrorState(v_res.data, true)
          } else {
            this.setState({ placed_sku_list: v_res.sku_info, autofill_throughput_metrics : [v_res.commercials], throughput_metrics: [v_res.commercials] });
          }
          this.setLoaderState(false);
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error, true)
        }
      });
  }

  copyPlanogram(existing_planogram:{planogram_id: number,planogram_name:string}, planogram_name:string) {
    this.apiService.copyPlanogram(existing_planogram.planogram_id, planogram_name)
      .subscribe({
        next: (res: any) => {
          if (res.code === 400) {
            this.setErrorState(res.data, true)
          } else {
            let source_url = getPlanogramImageUrl(existing_planogram.planogram_id, existing_planogram.planogram_name, false)
            let destination_file_name=`${res.cloned_planogram_id}/latest/${environment.environment_name}/${planogram_name}-cooler-image`;
            let source_file_name=`${existing_planogram.planogram_id}/latest/${environment.environment_name}/${existing_planogram.planogram_name}-cooler-image`;
            this.blobService.copyImage(source_url, destination_file_name,source_file_name, (_res: any) => {
              console.log("planogram image copied")
            })
          let planogram_limit = document.cookie.split('planogram_limit=')[1] ? Number(document.cookie.split('planogram_limit=')[1]) : 0
            this.getAllPlanograms({ limit: planogram_limit, offset: 0, search: '', status:'all' }, false)
            this.setLoaderState(false);
          }
        }, error: (error) => {
          console.log("error :: ", error)
          this.setErrorState(error, true)
        }
      });
  }


}
