import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getPlanogramImageUrl } from 'src/helper/app-utils';

interface DialogData{
  compare_planogram_ids:number[];
  compare_planogram_data: any;
}

@Component({
  selector: 'app-compare-planogram-image-preview',
  templateUrl: './compare-planogram-image-preview.component.html',
  styleUrls: ['./compare-planogram-image-preview.component.css']
})
export class ComparePlanogramImagePreviewComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  getPlanogramPreviewImageSrc=(id:any)=>{
    return getPlanogramImageUrl(id, this.data.compare_planogram_data[id].planogram_name, false)
  }


}
