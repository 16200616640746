<div id="planograms-content">
    <!-- <h1 style="padding-left: 20px;">Planograms</h1> -->
    <!-- <mat-grid-list class="planogram-list-grid" cols="6">

        <mat-grid-tile colspan="5"> -->
            <div id="planograms-list-container">
                <!-- <div class="navbar-container" style="height: 50px;">
                    <nav class="navbar navbar-expand-lg navbar-light" style="height: 100%;">

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav mr-auto">
                                <li class="nav-item" *ngFor="let tab of tabs" [routerLink]="tab.path"
                                    routerLinkActive="tab === active_tab"
                                    (click)="tab.name !== active_tab ? handleActiveTab(tab.name) : null">
                                    <a class="nav-link" [ngClass]="{'active': tab.name === active_tab}">{{tab.name}}</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div> -->

                <div style="width: 100%; height: calc(100%);">
                    <div style="display: flex; justify-content:space-between; align-items: center; min-height: 55px;" >
                        <mat-form-field appearance="fill" class="planogram-search-input" *ngIf="dataSource.data.length > 0">
                            <mat-icon matPrefix>search</mat-icon>
                            <input matInput type="text" [(ngModel)]="search_input" placeholder="Search Planogram"
                                (ngModelChange)="updateFilter($event)">
                            <!-- <em class="bi bi-funnel"></i> -->
                        </mat-form-field>
    
                        <div style="display: flex;" >
                            <button mat-stroked-button  style="margin: 0 20px;" (click)="addNewPlanogram()">
                                <em class="bi bi-plus-circle-fill"></em>
                                New Planogram
                            </button>
                            <!-- <mat-divider></mat-divider> -->
                            <button *ngIf="dataSource.data.length > 0" mat-stroked-button  style="margin-right: 20px;color:black;" (click)="openComparePlanogramDialog()">
                                <em class="bi bi-eye"></em>
                                Compare Planograms</button>
                        </div>
                    </div>
                 

                    <div style="width: 100%; height: calc(100% - 60px); overflow:auto">
                        <!-- <ng-template #mytable let-planograms="data_source" [ngTemplateOutlet]="mytable"
                            [ngTemplateOutletContext]="{data_source: getDataSource()}"> -->
                        <table id="planogram-list-table" mat-table *ngIf="dataSource.data.length > 0" aria-describedby=""
                            [dataSource]="dataSource" #mytable matSort (matSortChange)="announceSortChange($event)"
                            class="my-table mat-elevation-z8">

                            <!-- Name Column -->
                            <ng-container matColumnDef="name">
                                <th style="padding-left: 10px;" mat-header-cell *matHeaderCellDef mat-sort-header> Name
                                </th>
                                <td mat-cell *matCellDef="let planogram"> {{planogram.name}} </td>
                            </ng-container>

                            <!-- Channel Column -->
                            <ng-container matColumnDef="channel">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Channel </th>
                                <td mat-cell *matCellDef="let planogram"> {{planogram.channel.value}} </td>
                            </ng-container>

                            <!-- Sub Channel Column -->
                            <ng-container matColumnDef="sub_channel">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Sub-Channel </th>
                                <td mat-cell *matCellDef="let planogram"> {{planogram.sub_channel.value}} </td>
                            </ng-container>

                            <!-- Business Goals Column -->
                            <ng-container matColumnDef="business_objective">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Business Goals </th>
                                <td mat-cell *matCellDef="let planogram">
                                    {{getAllBusinessObjectives(planogram.business_objective)}} </td>
                            </ng-container>

                            <!-- Created By Column -->
                            <ng-container matColumnDef="user_name">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Created By </th>
                                <td mat-cell *matCellDef="let planogram"> {{planogram.user_name}} </td>
                            </ng-container>

                            <!-- Business Goals Column -->
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                                <td mat-cell *matCellDef="let planogram">
                                    <span class="planogram-status" [ngClass]="{'active' : planogram.status === CONFIG.PLANOGRAMSTATUS.ACTIVE,
                                            'inactive': planogram.status === CONFIG.PLANOGRAMSTATUS.INACTIVE, 
                                         'draft': planogram.status === CONFIG.PLANOGRAMSTATUS.DRAFT}">
                                        {{planogram.status}}
                                    </span>
                                </td>
                            </ng-container>

                            <!-- Action Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                <td mat-cell *matCellDef="let planogram" [ngClass]="planogram.can_edit ?'action-link':'action-link-disabled'">
                                    <!-- <em class="bi bi-view" style="margin-right: 10px;" ></i> -->
                                    <em class="bi bi-pencil" style="margin-right: 10px;color:#25D4CF;" ></em>
                                    <em class="bi bi-files" style="margin-right: 10px;color:#25D4CF;" (click)="copyPlanogram($event, planogram.id, planogram.name)" ></em>
                                    <em class="bi bi-trash" style="color:#25D4CF;"  (click)="handleDeleteClick($event, planogram.id)"></em>
                                    <!-- <em class="bi bi-three-dots-vertical" (click)="$event.stopPropagation()"
                                        [matMenuTriggerFor]="menu"></i> -->
                                    <mat-menu xPosition="before" #menu="matMenu" class="more-action-menu">
                                        <button class="action-button" mat-menu-item
                                            (click)="handleDeleteClick($event,planogram.id)">
                                            <mat-icon>delete</mat-icon>
                                            <span>Delete</span>
                                        </button>
                                    </mat-menu>
                                </td>
                            </ng-container>

                            <!-- (click)="handleRowClick(row)" -->
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                (click)="active_tab === tabs[1].name ? handleRowClick(row) : null"></tr>

                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="6">No data matching with the filter</td>
                            </tr>

                        </table>

                        <mat-paginator [hidden]="dataSource.data.length === 0" [pageSize]="planogram_limit/2"
                            hidePageSize="true" showFirstLastButtons="false" (page)="paginationChange($event)"
                            aria-label="Select page of Planograms" class="planogram-list-paginator" >
                        </mat-paginator>
                    </div>

                </div>
            </div>

        <!-- </mat-grid-tile>


        <mat-grid-tile colspan="1">
            <div id="quick-actions">
                <h4>Quick Actions</h4>
                <div>
                    <button mat-button color="warn" (click)="addNewPlanogram()">
                        <em class="bi bi-plus-circle-fill"></i>
                        New Planogram
                    </button>
                    <mat-divider></mat-divider>
                    <button mat-button color="warn" (click)="openComparePlanogramDialog()">
                        <em class="bi bi-eye"></i>
                        Compare Planograms</button>
                </div>
            </div>

        </mat-grid-tile>

    </mat-grid-list> -->
</div>

<app-loader [is_show_loader]="(loader | async)! || is_show_loader"></app-loader>