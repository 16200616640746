import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(
    private http: HttpClient,
  ) {}




  tokenValidation(hubble_access_token:string) {
    var baseUrl = environment.hubble_dashboard_backend_url + "/usersDetails";
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': hubble_access_token,
    });
    let options = { headers: headers };
    return this.http.get(baseUrl, options).toPromise();
  }
}
