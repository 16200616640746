import { Command } from '../../../modals/undo-redo-command';
// Specific commands
import { UpdatePropertiesCommand, UpdatePropertiesCommandData } from './commands/update-properties-command';

export class CommonCommandFactory<TTarget> {
  public create<K extends commonCommandKeys<TTarget>>(key: K, commandData: CommonCommandMap<TTarget>[K]): Command {
    if (isCommonCommand(commandData, key, 'update-properties')) {
      return new UpdatePropertiesCommand<TTarget>(commandData);
    }
    else
      throw `Unable to create common command. Unknown command key '${key}'.`;
  }
}


/**
 * Maps common command keys to the command data that is needed to
 * execute them.
 */
 export interface CommonCommandMap<TTarget> {
  'update-properties': UpdatePropertiesCommandData<TTarget>;
}

export type commonCommandKeys<T> = keyof CommonCommandMap<T>;

/**
 * A simple helper function that asserts that the command-data/key combination
 * correspond to an expected key in the CommonCommandMap.
 * @example if (isCommonCommand(commandData, key, 'update-properties')) {
 *     return new UpdatePropertiesCommand<T>(commandData); // yes, commandData is UpdatePropertiesCommandData<T>
 *   }
 */
function isCommonCommand<T, K extends keyof CommonCommandMap<T>>(
  commandData: CommonCommandMap<T>[keyof CommonCommandMap<T>],
  actualKey: string,
  expectedKey: K): commandData is CommonCommandMap<T>[K] {
  return actualKey === expectedKey;
}
