<div id='app_comp'>
    <div id="nav-container">
        <app-nav-component  ></app-nav-component>
    </div>
    <div class='router-div'>
        <router-outlet *ngIf="!isIframe">
            
        </router-outlet>
        <app-snakbar-msg-display></app-snakbar-msg-display>
    </div>
</div>