<mat-dialog-content id="compare-planogram-image-preview-dialog-content" >
    <div class="header">
        <div>
            <span>Compare Planograms</span>
        </div>
            <button mat-icon-button class="close-button" disableRipple [mat-dialog-close]="true">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
    </div>
    <div style="height: calc(100% - 40px); width: 100%;" >
        <mat-grid-list [cols]="data.compare_planogram_ids.length" [rowHeight]="'100%'">
            <mat-grid-tile *ngFor="let id of data.compare_planogram_ids; let index=index"
            [ngClass]=" index !== data.compare_planogram_ids.length - 1 ? 'right-border-tile' : '' " >
                <div style="height: 100%; width: 100%;" >
                    <img alt="" style="margin-bottom: 15px;height: 600px; max-width: 100%;
                    max-height: calc(100% - 80px);" [src]="getPlanogramPreviewImageSrc(id)" />
                    <p class="planogram-name" >{{this.data.compare_planogram_data[id].planogram_name}}</p>
                    <p>{{this.data.compare_planogram_data[id]['cooler_details'].doors}} Door
                </div>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</mat-dialog-content>