<div id="cooler-template-creator-form">
    <div id="template-header">
        <h3 style="margin:0" [ngClass]="{'span-header' : is_in_dialog_modal}">
            <em *ngIf="!is_in_dialog_modal" class="bi bi-arrow-left"
                style="margin-right:10px; cursor: pointer;font-size: 18px;" (click)="handleBackClick()"></em>
            {{is_in_dialog_modal ? 'Create Cooler Template' : 'Create new cooler template:' }}
        </h3>
        <div>
            <button mat-raised-button color="warn"
                [disabled]="planogram_create_template_form_group.invalid || (template_name_error | async)  !== ''"
                (click)="createNewCoolerTemplate()">Save Template</button>

            <button mat-icon-button *ngIf="is_in_dialog_modal" id="cooler-template-dialog-close"
                (click)="newTemplateCreateBackAndNextScreenNavigateEvent.emit({ is_success: true, cooler_id: -1 });">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <mat-divider *ngIf="is_in_dialog_modal" style="margin: 15px -24px;"></mat-divider>

    <div id="template-segment">
        <div
            [ngStyle]="{'width':planogram_create_template_form_group.controls['no_of_doors'].value === 3 ? '40%' : '50%' }">
            <div id="template-form-group" [formGroup]="planogram_create_template_form_group">
                <div>

                    <div class="form-group">
                        <label for="templatename">Name Cooler Template
                            <span *ngIf="isRequiredField('template_name')" class="astrick-class"> *</span> </label>
                        <div style="display: flex; align-items: center;">
                            <input type="text" class="form-control" id="templatename" formControlName="template_name"
                                style="min-width: 100%" placeholder="Enter Template Name" required
                                [ngClass]="{'is-invalid' : ((planogram_create_template_form_group.controls['template_name'].invalid && 
                                (planogram_create_template_form_group.controls['template_name'].dirty || planogram_create_template_form_group.controls['template_name'].touched)) || (template_name_error | async)  !== '')}"
                                (blur)="onBlurTemplateName()">
                            <em class="bi" [ngClass]="{'bi-check-circle text-success': (planogram_create_template_form_group.get('template_name')?.valid && (template_name_error | async)  === ''),
                        'bi-x-circle text-danger': (template_name_error | async)  !== ''}"
                                style="margin-left: 5px;"></em>
                        </div>
                        <span *ngIf="(template_name_error | async)  !== ''" class="error-message">
                            {{template_name_error | async}} </span>
                        <span class="error-message"
                            *ngIf="planogram_create_template_form_group.controls['template_name'].errors?.['pattern']">
                            Special characters are not allowed.
                        </span>
                    </div>

                    <div class="form-group mb-3">
                        <label for="coolertype">Cooler Type
                            <span *ngIf="isRequiredField('cooler_type')" class="astrick-class"> *</span>
                        </label>

                        <mat-select class="custom-select d-block w-100" placeholder="Select Cooler Type"
                            formControlName="cooler_type" disableOptionCentering>
                            <mat-option *ngFor="let cooler of getCoolerTypeList(cooler_template_base_data?.cooler_type)"
                                [value]="cooler.id">
                                <em [ngClass]="{'bi-check-circle-fill text-danger' : planogram_create_template_form_group.controls['cooler_type'].value === cooler.id ,
                                'bi-circle' : planogram_create_template_form_group.controls['cooler_type'].value !== cooler.id}"
                                    class="bi"></em>
                                {{cooler.name}}
                            </mat-option>
                        </mat-select>

                    </div>

                    <!-- not used for now -->
                    <!-- <div class="form-group mb-3">
                        <label for="subcoolertype">Sub Cooler Type
                            <span *ngIf="isRequiredField('sub_cooler')" class="astrick-class"> *</span>
                        </label>

                        <mat-select class="custom-select d-block w-100" placeholder="Select Sub Cooler"
                            formControlName="sub_cooler" disableOptionCentering>
                            <mat-option *ngFor="let sub_cooler of cooler_template_base_data?.sub_cooler"
                                [value]="sub_cooler.id">
                                <em [ngClass]="{'bi-check-circle-fill text-danger' : planogram_create_template_form_group.controls['sub_cooler'].value === sub_cooler.id ,
                                'bi-circle' : planogram_create_template_form_group.controls['sub_cooler'].value !== sub_cooler.id}"
                                    class="bi"></i>
                                {{sub_cooler.name}}
                            </mat-option>
                        </mat-select>

                    </div> -->

                    <div class="form-group mb-3">
                        <label for="no_of_doors">Doors
                            <span *ngIf="isRequiredField('no_of_doors')" class="astrick-class"> *</span>
                        </label>

                        <mat-select class="custom-select d-block w-100" placeholder="Select Doors"
                            formControlName="no_of_doors" disableOptionCentering>
                            <mat-option *ngFor="let door of counter(3)" [value]="door">
                                <!-- <em [ngClass]="{'bi-check-circle-fill text-danger' : planogram_create_template_form_group.controls['no_of_doors'].value === door ,
                                'bi-circle' : planogram_create_template_form_group.controls['no_of_doors'].value !== door}"
                                class="bi"></i> -->
                                {{door}}
                            </mat-option>
                        </mat-select>
                    </div>

                    <div class="form-group">
                        <label for="banner" style="margin-bottom: 0;">Banner
                            <span *ngIf="isRequiredField('banner')" class="astrick-class"> *</span> </label>
                        <div class="banner-container">
                            <div *ngFor="let banner of cooler_template_base_data?.banner" style="position: relative;">
                                <img alt="" [src]="banner.image + sas" style="height: 60px; width: 150px; background: red;" />
                                <!-- <img [src]="'./assets/banner'+ (banner.id) + '.png' " style="height: 60px; width: 150px;" /> -->
                                <!-- <mat-checkbox class="absolute-radio-icon" formControlName="banner" color="warn" value="banner"
                            [checked]="planogram_create_template_form_group.controls['banner'].value.id !== banner.id"
                              ></mat-checkbox> -->
                                <!-- <input type="radio" class="absolute-radio-icon" [value]="banner" formControlName="banner"
                            [checked]="planogram_create_template_form_group.controls['banner'].value.id !== banner.id" > -->
                                <em [ngClass]="{'bi-check-circle-fill text-danger' : planogram_create_template_form_group.controls['banner'].value.id === banner.id,
                                      'bi-circle' : planogram_create_template_form_group.controls['banner'].enabled && planogram_create_template_form_group.controls['banner'].value.id !== banner.id,
                                    'disable-icon bi-circle-fill': planogram_create_template_form_group.controls['banner'].disabled}"
                                    (click)="updateCoolerTemplateBanner(banner)" class="bi absolute-radio-icon"></em>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <ng-template #shelfcountformcontrol let-control="control" let-max="max"
                            [ngTemplateOutlet]="shelfcountformcontrol"
                            [ngTemplateOutletContext]="{ control: planogram_create_template_form_group.controls['shelf_count'], max: getMaxShelfCount(selected_base_cooler_info.cooler_height_calc) }">
                            <label for="shelf">Shelves
                                <span *ngIf="isRequiredField('shelf_count')" class="astrick-class"> *</span> </label>
                            <div class="input-group">
                                <input type="number" class="form-control right-icon" id="shelf"
                                    formControlName="shelf_count"
                                    [ngClass]="{'is-invalid': control.invalid && (control.dirty || control.touched)}"
                                    placeholder="Enter Shelf" required>
                                <div class="input-group-append transparent">
                                    <span class="input-group-text">
                                        <em class='bi bi-dash-circle-fill text-danger' aria-hidden='true'
                                            [ngClass]="{'disable-input-icon' : control.invalid, 'inactive-input-icon':control.value === 1}"
                                            (click)="control.value > 1 ? handleFormFeildCounterChnage(planogram_create_template_form_group ,'shelf_count', -1) : null"></em>
                                        <em class='bi bi-plus-circle-fill text-danger' aria-hidden='true'
                                            [ngClass]="{'disable-input-icon' : control.invalid, 'inactive-input-icon':control.value === max}"
                                            (click)="control.value < max ? handleFormFeildCounterChnage(planogram_create_template_form_group, 'shelf_count', 1) : null"></em></span>
                                </div>
                            </div>

                            <span class="error-message" *ngIf="control.errors?.['required']">
                                Shelves is required.
                            </span>
                            <span class="error-message" *ngIf="control.errors?.['max']">
                                Shelves should be less than {{control.errors?.['max'].max+1}}
                            </span>
                            <span class="error-message" *ngIf="control.errors?.['min']">
                                Shelves should be greater than 0.
                            </span>
                        </ng-template>
                    </div>


                    <div class="form-group mb-3" *ngIf="cooler_template_base_data.ic_flag && cooler_template_base_data.fc_flag">
                        <label for="ic_shelves">Select IC Shelves
                            <span *ngIf="isRequiredField('ic_shelves')" class="astrick-class"> *</span>
                        </label>

                        <mat-select class="custom-select d-block w-100" placeholder="Select IC Shelf" multiple
                            formControlName="ic_shelves" disableOptionCentering
                            panelClass="multiple-select-panel-class">
                            <mat-option
                                *ngFor="let shelf of counter(planogram_create_template_form_group.controls['shelf_count'].value)"
                                [value]="shelf"> {{shelf}} </mat-option>
                        </mat-select>
                    </div>

                    <div class="form-group" *ngIf="cooler_template_base_data.ic_flag">
                        <ng-template #icfacingformcontrol let-control="control" let-max="max"
                            [ngTemplateOutlet]="icfacingformcontrol"
                            [ngTemplateOutletContext]="{ control: planogram_create_template_form_group.controls['ic_shelf_facings'], 
                            max: getMaxFacing(cooler_template_base_data.avg_sku_width_ic, selected_base_cooler_info.cooler_width_calc) }">
                            <label for="facing">Facings in each IC Shelf <span
                                    *ngIf="isRequiredField('ic_shelf_facings')" class="astrick-class"> *</span>
                            </label>
                            <span class="right content">Average width of IC SKU's
                                {{cooler_template_base_data.avg_sku_width_ic}} mm</span>
                            <div class="input-group">
                                <input type="number" class="form-control right-icon" id="ic-facing"
                                    formControlName="ic_shelf_facings"
                                    [ngClass]="{'is-invalid': control.invalid && (control.dirty || control.touched)}"
                                    placeholder="Enter IC Shelf Facings" required>

                                <div class="input-group-append transparent">
                                    <span class="input-group-text">
                                        <em class='bi bi bi-dash-circle-fill text-danger' aria-hidden='true'
                                            [ngClass]="{'disable-input-icon' : control.invalid, 'inactive-input-icon':control.value === 0}"
                                            (click)="control.value > 0 ? handleFormFeildCounterChnage(planogram_create_template_form_group, 'ic_shelf_facings', -1) : null"></em>
                                        <em class='bi bi-plus-circle-fill text-danger' aria-hidden='true'
                                            [ngClass]="{'disable-input-icon' : control.invalid, 'inactive-input-icon':control.value === max}"
                                            (click)="control.value < max ? handleFormFeildCounterChnage(planogram_create_template_form_group, 'ic_shelf_facings', 1) : null"></em></span>
                                </div>
                            </div>
                            <span class="error-message" *ngIf="control.errors?.['required']">
                                IC Shelf Facings is required.
                            </span>
                            <span class="error-message" *ngIf="control.errors?.['max']">
                                IC shelf Facings should be less than {{control.errors?.['max'].max+1}}
                            </span>
                            <span class="error-message" *ngIf="control.errors?.['min']">
                                IC shelf Facings should be greater than 0.
                            </span>
                        </ng-template>
                    </div>

                    <div class="form-group" *ngIf="cooler_template_base_data.fc_flag">
                        <ng-template #fcfacingformcontrol let-control="control" let-max="max"
                            [ngTemplateOutlet]="fcfacingformcontrol"
                            [ngTemplateOutletContext]="{ control: planogram_create_template_form_group.controls['fc_shelf_facings'], 
                            max: getMaxFacing(cooler_template_base_data.avg_sku_width_fc, selected_base_cooler_info.cooler_width_calc) }">
                            <label for="facing">Facings in each FC Shelf<span
                                    *ngIf="isRequiredField('fc_shelf_facings')" class="astrick-class"> *</span>
                            </label>
                            <span class="right content">Average width of FC SKU's
                                {{cooler_template_base_data.avg_sku_width_fc}} mm</span>

                            <div class="input-group">
                                <input type="number" class="form-control right-icon" id="fc-facings"
                                    formControlName="fc_shelf_facings"
                                    [ngClass]="{'is-invalid': control.invalid && (control.dirty || control.touched)}"
                                    placeholder="Enter Facings" required>

                                <div class="input-group-append transparent">
                                    <span class="input-group-text">
                                        <em class='bi bi bi-dash-circle-fill text-danger' aria-hidden='true' [ngClass]="{'disable-input-icon' : control.invalid,
                                        'inactive-input-icon':control.value === 0}"
                                            (click)="control.value > 0 ? handleFormFeildCounterChnage(planogram_create_template_form_group, 'fc_shelf_facings', -1): null"></em>
                                        <em class='bi bi-plus-circle-fill text-danger' aria-hidden='true'
                                            [ngClass]="{'disable-input-icon' : control.invalid, 'inactive-input-icon':control.value === max}"
                                            (click)="control.value < max ? handleFormFeildCounterChnage(planogram_create_template_form_group, 'fc_shelf_facings', 1) : null"></em></span>
                                </div>
                            </div>
                            <span class="error-message" *ngIf="control.errors?.['required']">
                                FC Shelf Facings is required.
                            </span>
                            <span class="error-message" *ngIf="control.errors?.['max']">
                                FC Shelf Facings should be less than {{control.errors?.['max'].max+1}}
                            </span>
                            <span class="error-message" *ngIf="control.errors?.['min']">
                                FC Shelf Facings should be greater than 0.
                            </span>
                        </ng-template>
                    </div>

                    <!-- <div class="form-group">
                        <label for="facing">Facings in each Shelf
                            <span *ngIf="isRequiredField('facings')" class="astrick-class"> *</span> </label> -->

                    <!-- <div id="facing-btn-toggle">
                        <mat-button-toggle-group #colorToggle="matButtonToggleGroup" formControlName="mode_of_facings"
                            aria-label="Change color">
                            <mat-button-toggle [value]="CONFIG.COOLERFACINGMODE.BATCH"> Batch </mat-button-toggle>
                            <mat-button-toggle [value]="CONFIG.COOLERFACINGMODE.INDIVIDUAL"> Individual Shelf
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div> -->

                    <!-- <div
                            *ngIf="planogram_create_template_form_group.get('mode_of_facings')?.value === CONFIG.COOLERFACINGMODE.BATCH; then thenBlock else elseBlock">
                        </div> -->
                    <ng-template #thenBlock>
                        <div class="input-group">
                            <input type="number" class="form-control right-icon" id="facing" formControlName="facings"
                                [ngClass]="{'is-invalid': planogram_create_template_form_group.controls['facings'].invalid && 
                                (planogram_create_template_form_group.controls['facings'].dirty || planogram_create_template_form_group.controls['facings'].touched)}"
                                placeholder="Enter Facings" required>

                            <div class="input-group-append transparent">
                                <span class="input-group-text">
                                    <em class='bi bi bi-dash-circle-fill text-danger' aria-hidden='true'
                                        [ngClass]="{'disable-input-icon' : planogram_create_template_form_group.controls['facings'].invalid}"
                                        (click)="handleFormFeildCounterChnage(planogram_create_template_form_group, 'facings', -1)"></em>
                                    <em class='bi bi-plus-circle-fill text-danger' aria-hidden='true'
                                        [ngClass]="{'disable-input-icon' : planogram_create_template_form_group.controls['facings'].invalid}"
                                        (click)="handleFormFeildCounterChnage(planogram_create_template_form_group, 'facings', 1)"></em></span>
                            </div>
                        </div>
                        <span class="error-message"
                            *ngIf="planogram_create_template_form_group.controls['facings'].errors?.['required']">
                            Facings is required.
                        </span>
                        <span class="error-message"
                            *ngIf="planogram_create_template_form_group.controls['facings'].errors?.['max']">
                            Facings should be less than
                            {{planogram_create_template_form_group.controls['facings'].errors?.['max'].max+1}}
                        </span>
                        <span class="error-message"
                            *ngIf="planogram_create_template_form_group.controls['facings'].errors?.['min']">
                            Facings should be greater than 0.
                        </span>
                    </ng-template>

                    <ng-template #elseBlock>
                        <div [formGroup]="individual_shelf_facing" id="individual_shelf_facing">
                            <div *ngFor="let row of cooler_config.shelves; index as shelf_index; ">
                                <div class="input-group">
                                    <div class="input-group-prepend transparent">
                                        <span>Shelf {{shelf_index + 1}} : </span>
                                    </div>
                                    <input type="number" class="form-control right-icon individual-shelf-facing"
                                        id="facing" [formControlName]="'shelf_' + (shelf_index + 1)"
                                        [ngClass]="{'is-invalid': individual_shelf_facing.controls['shelf_' + (shelf_index + 1)].invalid && 
                                        (individual_shelf_facing.controls['shelf_' + (shelf_index + 1)].dirty || individual_shelf_facing.controls['shelf_' + (shelf_index + 1)].touched)}"
                                        placeholder="Enter Shelf Facing" required>

                                    <div class="input-group-append transparent">
                                        <span class="input-group-text">
                                            <em class='bi bi bi-dash-circle-fill text-danger' aria-hidden='true'
                                                [ngClass]="{'disable-input-icon' : individual_shelf_facing.controls['shelf_' + (shelf_index + 1)].invalid}"
                                                (click)="handleFormFeildCounterChnage(individual_shelf_facing, 'shelf_' + (shelf_index + 1), -1)"></em>
                                            <em class='bi bi-plus-circle-fill text-danger' aria-hidden='true'
                                                [ngClass]="{'disable-input-icon' : individual_shelf_facing.controls['shelf_' + (shelf_index + 1)].invalid}"
                                                (click)="handleFormFeildCounterChnage(individual_shelf_facing, 'shelf_' + (shelf_index + 1), 1)"></em></span>
                                    </div>
                                </div>
                                <span class="error-message"
                                    *ngIf="individual_shelf_facing.controls['shelf_' + (shelf_index + 1)].errors?.['required']">
                                    Shelf{{shelf_index + 1}} Facing is required.
                                </span>
                                <span class="error-message"
                                    *ngIf="individual_shelf_facing.controls['shelf_' + (shelf_index + 1)].errors?.['max']">
                                    Shelf{{shelf_index + 1}} should be less than
                                    {{individual_shelf_facing.controls['shelf_' + (shelf_index + 1)].errors?.['max'].max
                                    +1}}
                                </span>
                                <span class="error-message"
                                    *ngIf="individual_shelf_facing.controls['shelf_' + (shelf_index + 1)].errors?.['min']">
                                    Shelf{{shelf_index + 1}} should be greater than 0.
                                </span>
                            </div>
                        </div>
                    </ng-template>

                    <!-- </div> -->

                    <div class="form-group mb-3">
                        <label for="coolertype">Door Handle Direction
                            <span *ngIf="isRequiredField('traffic_direction')" class="astrick-class"> *</span>
                        </label>

                        <mat-select class="custom-select d-block w-100" placeholder="Select Door Handle Direction"
                            formControlName="traffic_direction" disableOptionCentering>
                            <mat-option *ngFor="let dir of getSelectedDoorTrafficOptions()" [value]="dir">
                                <em [ngClass]="{'bi-check-circle-fill text-danger' : planogram_create_template_form_group.controls['traffic_direction'].value === dir,
                                'bi-circle' : planogram_create_template_form_group.controls['traffic_direction'].value !== dir}"
                                    class="bi"></em>
                                {{dir}}
                            </mat-option>
                        </mat-select>

                    </div>

                </div>
            </div>
        </div>

        <div
            [ngStyle]="{'width':planogram_create_template_form_group.controls['no_of_doors'].value === 3 ? '60%' : '50%'}">
            <div id="cooler-image">
                <div>
                    <!-- [placeholder_width]="cooler_template_base_data.avg_sku_width_ic" [placeholder_height]="sku_max_height" -->
                    <app-planogram-component
                        *ngIf="planogram_create_template_form_group.valid && individual_shelf_facing.valid && (template_name_error | async)  === ''"
                        [drop_item_ids]="[]" [cooler_config]="cooler_config" [placed_sku_list]="[]">
                    </app-planogram-component>
                </div>

            </div>
        </div>
    </div>
</div>