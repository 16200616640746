import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable, Subject, SubscriptionLike } from 'rxjs';
import { CONFIG } from 'src/helper/app-config';
import { HistoryStackEntry } from 'src/helper/command-history';
import { SKUModal, PlanogramModal, ScreenDataModal } from 'src/modals/planogram';
import { CommandService } from 'src/services/command.service';
import { PlanogramStateService } from 'src/services/planogram-state.service';
import { abbreviateNumber, getFilledWidth, getPlacedSkuDetail, shelfHeight } from '../../helper/app-utils'
import { ConfirmDialogModalComponent } from '../confirm-dialog-modal/confirm-dialog-modal.component';

@Component({
  selector: 'app-planogram-builder',
  templateUrl: './planogram-builder.component.html',
  styleUrls: ['./planogram-builder.component.css'],
})
export class PlanogramBuilderComponent implements OnInit, OnChanges, OnDestroy {

  @Input() is_display: boolean = false;
  @Input() planogram_id: number;
  @Input() scenario: string;
  @Input() sku_placement_change: boolean;
  @Output() filledSkuEventEmitter = new EventEmitter<number>();
  @Output() businessInsightCalculationEventEmitter = new EventEmitter<any>();
  @Output() skuPlacementEventEmitter = new EventEmitter<boolean>();
  @Output() goToPreviousStepEventEmitter = new EventEmitter<number>();
  @Output() verticalColumnBrandAssociationEventEmitter = new EventEmitter<any>();
  @Output() coolerOrientationEventEmitter = new EventEmitter<boolean>()

  show_sku_list: boolean = true;
  drop_item_ids: string[] = [];
  cooler_config: PlanogramModal = {} as any;
  planogram_placed_skus: SKUModal[] = [];
  available_skus: SKUModal[] = [];
  depth_of_sku: number = 1;
  show_business_insight: boolean = false;
  profit_per_uc: number = 0;
  vertical_cooler_orientation = false;
  vertical_brand_association: any = {};
  throughput_metrics: any[] = [];
  autofill_throughput_metrics: any[] = [];
  throughput_loader: boolean = false;
  dim_benchmark_clearance: number = 0;
  skus_with_lessthan2_facing: string[] = [];

  representaions_industry_standards: { id: number; name: string; category_name: string; industry_standard: number, planogram_value: number }[] = [];
  checklists: { id: number; name: string; base_value?: number, planogram_value?: number, compilance?: number, condition_satisfy?: boolean, type: string }[] = [];
  space_filleds: { id: number; name: string; base_value: number | string, planogram_value: number | string , info:string, note:string}[] = [];

  fc_sku_filling_order: any[] = [];
  ic_sku_filling_order: any[] = [];
  brand_filling_order: any[] = [];
  pack_type_filling_order: { pack_type: string; filling_order: number }[] = []

  ic_shelfs: number[] = [];

  undo_entries: HistoryStackEntry[] = [];
  redo_entries: HistoryStackEntry[] = [];

  cooler_config_subscription: SubscriptionLike;
  planogram_placed_skus_subscription: SubscriptionLike;
  available_skus_subscription: SubscriptionLike;
  screen_data_subscription: SubscriptionLike;
  command_success_subscription: SubscriptionLike;
  throughput_metrics_subscription: SubscriptionLike;
  autofill_throughput_metrics_subscription: SubscriptionLike;
  planogram_metrics_subscription: SubscriptionLike;

  planogram_placed_skus_subscriber: Observable<SKUModal[]> = this.planogramState.planogram_placed_skus;
  available_skus_subscriber: Observable<SKUModal[]> = this.planogramState.available_skus;
  cooler_config_subscriber: Observable<PlanogramModal> = this.planogramState.cooler_config_info;
  screen_data: Observable<ScreenDataModal[]> = this.planogramState.specific_screen_data;
  is_update_highlight_sku_placeholder: Observable<boolean> = this.planogramState.is_update_highlight_sku_placeholder;
  recommandations: Observable<any[]> = this.planogramState.recommandations;
  throughput_metrics_observer: Observable<any[]> = this.planogramState.throughput_metrics;
  autofill_throughput_metrics_observer: Observable<any[]> = this.planogramState.autofill_throughput_metrics;
  planogram_metrics_observer: Observable<any> = this.planogramState.planogram_metrics;
  sku_brands : Observable<any> = this.planogramState.sku_brands;

  add_sku_click_event_subject: Subject<void> = new Subject<void>();

  total_facings: number = 1;

  revenue_number_format: string = 'default'
  profit_number_format: string = 'default'
  transaction_number_format: string = 'default'
  volume_number_format: string = 'default'

  constructor(private planogramState: PlanogramStateService, private commandService: CommandService, public dialog: MatDialog) {
    if (this.undo_entries.length !== 0 || this.redo_entries.length !== 0)
      this.commandService.clear();

  }

  ngOnInit(): void {
    this.listenScreenConfig();

    this.available_skus_subscription = this.available_skus_subscriber.subscribe(sk => {
      this.available_skus = sk;
    })

    this.cooler_config_subscription = this.cooler_config_subscriber.subscribe(config => {
      this.cooler_config = config;
      this.ic_shelfs = config.ic_shelves;
      if (config.shelves) {
        let total_sku_capacity = 0;
        config.shelves.forEach(s => {
          total_sku_capacity += s.no_of_facings;
        })
        this.total_facings = total_sku_capacity * (this.cooler_config.no_of_doors);
      }

      if (config.vertical_orientation && Object.keys(config.vertical_orientation).length > 0) {
        this.vertical_brand_association = config.vertical_orientation;
        this.verticalColumnBrandAssociationEventEmitter.emit(this.vertical_brand_association);
      }
      this.vertical_cooler_orientation = config.vertical_orientation_flag !== undefined ? config.vertical_orientation_flag : false;
    })

    this.planogram_placed_skus_subscription = this.planogram_placed_skus_subscriber.subscribe(skus => {
      this.planogram_placed_skus = skus;
      let filled_sku_capacity = (skus.length / this.total_facings) * 100;
      this.filledSkuEventEmitter.emit(filled_sku_capacity);
      if (this.is_display) {
        this.calculateMetrics();
      }
    })

    this.command_success_subscription = this.commandService.commandSuccess().subscribe(_args => {
      this.updateHistoryTable();
    });

    this.throughput_metrics_subscription = this.throughput_metrics_observer.subscribe(throughput => {
      this.throughput_metrics = throughput;
      this.throughput_loader = false;
      if(this.throughput_metrics.length > 0 && this.throughput_metrics[0].representation){
        this.updateRepresentationMetrics();
      }
    })

    this.autofill_throughput_metrics_subscription = this.autofill_throughput_metrics_observer.subscribe(throughput => {
      this.autofill_throughput_metrics = throughput;
      this.throughput_loader = false;
      if (throughput.length > 0) {
        this.show_business_insight = true
      }
    })

    this.planogram_metrics_subscription = this.planogram_metrics_observer.subscribe(metrics => {
      if(Object.keys(metrics).length > 0){
        if(metrics.checklist)
          this.checklists = metrics.checklist;
        
        if(metrics.representation)
          this.representaions_industry_standards = metrics.representation;

        if(metrics.space_filled)
          this.space_filleds = metrics.space_filled;
      }
    })

  }

  ngOnChanges(changes: SimpleChanges): void {
    
    if (changes['is_display']) {
      this.show_business_insight = false;
      if(this.autofill_throughput_metrics.length > 0)
        this.planogramState.clearAutofillThroughputCalculation();
      if (this.is_display && (this.undo_entries.length !== 0 || this.redo_entries.length !== 0))
        this.commandService.clear();
    }
  }

  ngOnDestroy(): void {
    this.cooler_config_subscription.unsubscribe();
    this.available_skus_subscription.unsubscribe();
    this.screen_data_subscription.unsubscribe();
    this.planogram_placed_skus_subscription.unsubscribe();
    this.command_success_subscription.unsubscribe();
    this.throughput_metrics_subscription.unsubscribe();
    this.autofill_throughput_metrics_subscription.unsubscribe();
    this.planogram_metrics_subscription.unsubscribe();
    if(this.autofill_throughput_metrics.length > 0)
      this.planogramState.clearAutofillThroughputCalculation();
    // this.placed_sku_calcucaltion_info
  }


  getCoolerHeight = ()=>{
    let total_height = 0;
    if(this.cooler_config.shelves.length > 0){
      let ic_shelf = this.cooler_config.ic_shelves.length;
      let fc_shelf = this.cooler_config.shelves.length - ic_shelf;
      let remaining_height = this.cooler_config.cooler_height_calc - (this.cooler_config.max_sku_height_fc*fc_shelf + this.cooler_config.max_sku_height_ic*ic_shelf)
      this.cooler_config.shelves.forEach(shelf=>{
        total_height += (shelfHeight(shelf.cooler_shelf, this.cooler_config) + (remaining_height/this.cooler_config.shelves.length))* shelf.no_of_facings * this.cooler_config.no_of_doors
      })
    }
    return total_height;
  }

  skuCategoryIndustryStandard(total_pack_size: number, category: string, sku_category_group: any) {
    let category_skus: SKUModal[] = sku_category_group[category.toLowerCase()]
    if (category_skus) {
      let category_pack_size = category_skus.reduce((sum, current) => sum + current.pack_size!, 0);
      return Math.round((category_pack_size / total_pack_size) * 100)
    }
    return 0;
  }


  getSpecificShelfFacings = (is_ic: boolean) => {
    let count = 0;
    this.cooler_config.shelves.forEach(s => {
      if (s.ic_shelf_flag === is_ic)
        count += s.no_of_facings;
    })
    return count * (this.cooler_config.no_of_doors);
  }

  updateHistoryTable() {
    this.undo_entries = [...this.commandService.getUndoEntries()].reverse();
    this.redo_entries = [...this.commandService.getRedoEntries()].reverse();
  }

  editCoolerFacings(edit_facings: any) {
    this.cooler_config.shelves = this.cooler_config.shelves.map(s => {
      s.no_of_facings = this.cooler_config.ic_shelves.includes(s.cooler_shelf) ? edit_facings.ic_shelf_facings : edit_facings.fc_shelf_facings;
      return s;
    })
    this.cooler_config.facings_ic = edit_facings.ic_shelf_facings;
    this.cooler_config.facings_fc = edit_facings.fc_shelf_facings;
    if (this.planogram_placed_skus.length > 0) {
      this.filledSkuEventEmitter.emit(0);
    }

    this.emptyCooler();

    this.planogramState.updateHighlightSkuPlaceholder(true);
    this.skuPlacementFlagUpdate();
  }

  emptyCooler = () => {
    if (this.planogram_placed_skus.length > 0) {
      this.planogramState.updateSkuPlacing([])
    }
    if (this.undo_entries.length > 0 || this.redo_entries.length > 0)
      this.commandService.clear();
  }

  undoClick() {
    if (this.undo_entries.length > 0)
      this.commandService.undo();
  }

  redoClick() {
    if (this.redo_entries.length > 0)
      this.commandService.redo();
  }

  listenScreenConfig = () => {
    this.screen_data_subscription = this.screen_data.subscribe(sc => {
      sc.forEach(ob => {
        switch (ob.key) {

          case 'pack_type_filling_order':
          case 'fc_sku_filling_order':
          case 'ic_sku_filling_order':
          case 'brand_filling_order':
            this[ob.key] = JSON.parse(ob.value);
            break;

          case 'depth_of_sku':
          case 'dim_benchmark_clearance':
            this.dim_benchmark_clearance = ob.value
            break;

          default:
            break;
        }
      })
    })
  }

  closeBusinessInsights = () => {
    this.show_business_insight = false;
  }

  calculateDelta = (current_cal: number, autofill_cal: number) => {
    let delta: any = {};
    if (current_cal && autofill_cal) {
      delta.difference = current_cal - autofill_cal;
      if (delta.difference !== 0) {
        delta.percentage_change = Math.ceil(((current_cal - autofill_cal) / autofill_cal) * 100)
      }
      delta.difference = this.abbreviateNumber(current_cal - autofill_cal);
    }
    return delta;
  }

  calculateBusinessInsight = () => {
    if (this.planogram_placed_skus.length > 0 && this.cooler_config.shelves && this.cooler_config.shelves.length > 0) {
      this.show_business_insight = true;
      let info = { planogram_id: this.planogram_id, sku_details: getPlacedSkuDetail(this.planogram_placed_skus) }
      this.planogramState.getThroughputCalculation(info);
      this.throughput_loader = true;
    }
  }

  calculateMetrics = () => {
    if (this.planogram_placed_skus.length > 0 && this.available_skus.length > 0 && this.cooler_config.shelves && this.cooler_config.shelves.length > 0) {
      let total_facings = 0, total_space_filled = 0;
      let sku_width = 0, sku_height = 0;
      let placed_sku_info: any[] = this.getAllPlacedSkusIds().map(id_count => {
        total_facings += id_count.facing
        let sku: any = this.available_skus.find(s => s.sku_id === id_count.id)
        if (sku) {
          total_space_filled += sku.sku_diameter_length * sku.sku_height * id_count.facing;
          sku_width += sku.sku_diameter_length * id_count.facing;
          sku_height += sku.sku_height * id_count.facing;
        }

        return { ...sku, ...{ sku_facing_count: id_count.facing } }
      })

      this.checklists = this.checklists.map(checklist => {
        return this.calculateChecklist(checklist, placed_sku_info);
      })

      this.space_filleds = this.space_filleds.map((space_feild) => {
        space_feild.planogram_value = this.calculateSpaceFillMetrics(space_feild.planogram_value, space_feild.id, total_space_filled, sku_width, sku_height)
        return space_feild;
      })
    } else {
      this.resetChecksData();
    }
  }

  updateRepresentationMetrics = ()=>{
    if(this.throughput_metrics.length > 0 && this.throughput_metrics[0].representation){
      this.representaions_industry_standards = this.representaions_industry_standards.map(standard => {
        standard.planogram_value = this.throughput_metrics[0].representation[standard.category_name] || 0;
        return standard;
      })
    }
    
  }

  resetChecksData(){

    this.representaions_industry_standards = this.representaions_industry_standards.map(standard => {
      standard.planogram_value = 0;
      return standard;
    })

    this.checklists = this.checklists.map(checklist => {
      if(checklist.id === 1 || checklist.id === 2){
        checklist.planogram_value = 0;
      }else if(checklist.id === 3 || checklist.id === 4 || checklist.id === 5){
        checklist.condition_satisfy = false;
      }
      return checklist;
    })
    this.skus_with_lessthan2_facing = [];

    this.space_filleds = this.space_filleds.map((space_feild) => {
      space_feild.planogram_value = 0
      return space_feild;
    })
  }

  calculateChecklist = (checklist: any, placed_sku_info: any[]) => {
    switch (checklist.id) {
      case 1:
        checklist.base_value = this.available_skus.filter(s => s.picos === 1).length;
        checklist.planogram_value = placed_sku_info.filter(s => s.picos === 1).length;
        return checklist;

      case 2:
        checklist.base_value = this.available_skus.filter(s => s.picos === 0).length;
        checklist.planogram_value = placed_sku_info.filter(s => s.picos === 0).length;
        return checklist;

      case 3:
        let sorted_placed_skus = this.planogram_placed_skus.slice();
        sorted_placed_skus = this.sortSKUAccToPosition(sorted_placed_skus);
        let ic_skus = sorted_placed_skus.filter(s => this.ic_shelfs.includes(s.shelf!))
        let fc_skus = sorted_placed_skus.filter(s => !this.ic_shelfs.includes(s.shelf!))
        checklist.condition_satisfy = this.skuFillingOrderCheck(ic_skus, true) && this.skuFillingOrderCheck(fc_skus, false)
        return checklist;

      case 4:
        this.skus_with_lessthan2_facing = placed_sku_info.filter(s => s.sku_facing_count < 2).map(s=> s.sku_name);
        checklist.condition_satisfy = this.skus_with_lessthan2_facing.length === 0;
        return checklist;
      default:
        return checklist;
    }
  }

  sortSKUAccToPosition = (sorted_placed_skus: SKUModal[])=>{
    return sorted_placed_skus.sort((s1, s2) => {
      if (s2.shelf && s1.shelf) {
        if (s2.position && s1.position) {
          return s1.shelf === s2.shelf ? s1.position - s2.position : s1.shelf - s2.shelf
        } else {
          return s1.shelf - s2.shelf
        }
      } else {
        return 0
      }
    })
  }

  skuFillingOrderCheck(list: SKUModal[], is_ic: boolean) {

    let placed_skus_name_order = list.map(sku => {
      let sku_ = this.available_skus.find(s => s.sku_id === sku.sku_id)
      if (sku_) {
        return {
          sku_name: sku_.sku_name, pack_size: sku_.pack_size, brand_order: sku_.brand_order,
          pack_order: this.getSKUPackOrder(sku_.pack_type!), fill_order: this.getSKUFillOrder(sku_.sku_name, is_ic)
        }
      } else {
        return {}
      }
    })
    return placed_skus_name_order.every((s, i, a) => {
      if (a[i - 1] && a[i - 1].pack_size === s.pack_size) {
        if (a[i - 1].pack_order === s.pack_order) {
          return a[i - 1].brand_order! <= s.brand_order!
        }
        return a[i - 1].pack_order! <= s.pack_order!
      }
      return !a[i - 1] || a[i - 1].pack_size! <= s.pack_size!
    }
    );
  }

  getSKUPackOrder = (pack_type: string) => {
    if (pack_type) {
      let pack = this.pack_type_filling_order.find(p => p.pack_type === pack_type)
      return pack ? pack.filling_order : 1
    }
    return null;
  }

  getSKUFillOrder = (sku_name: string, is_ic: boolean) => {
    let sku = is_ic ? this.ic_sku_filling_order.find(s => s.sku_name === sku_name) : this.fc_sku_filling_order.find(s => s.sku_name === sku_name)
    return sku ? sku.filling_order : 1
  }


  skuBrandOrderCheck(list: SKUModal[]) {
    let placed_skus_name_order = list.map(sku => {
      return { sku_name: sku.sku_name, pack_size: sku.sku_volume, brand_order: this.getSKUOrder(sku.sku_id) }
    })
    let group = placed_skus_name_order.reduce((groups: any, item) => {
      const group_ = (groups[item.pack_size] || []);
      group_.push(item);
      groups[item.pack_size] = group_;
      return groups;
    }, {});

    return Object.values(group).every((skus: any) => {
      return skus.every((s: any, i: any, a: any) => {
        return !i || a[i - 1]?.brand_order! <= s.brand_order!
      });
    })
  }

  getSKUOrder = (sku_id: number) => {
    let sku = this.available_skus.find(s => s.sku_id === sku_id)
    return sku ? sku.brand_order : 1
  }

  calculateSpaceFillMetrics = (planogram_value: any, id: number, total_pack_size: number, sku_width: number, sku_height: number) => {
    switch (id) {
      case 1:
        return total_pack_size;

      case 2:
        return this.getFilledShelfCount();

      case 3:
        return this.planogram_placed_skus.filter(s => this.cooler_config.ic_shelves.includes(s.shelf!)).length;

      case 4:
        return this.planogram_placed_skus.filter(s => !this.cooler_config.ic_shelves.includes(s.shelf!)).length;

      case 5:
        return sku_width;

      case 6:
        return sku_height;

      default:
        return planogram_value;
    }
  }

  getAllPlacedSkusIds = () => {
    let ids: { id: number, facing: number }[] = [];
    this.planogram_placed_skus.forEach(sk => {
      let index = ids.findIndex(o => o.id === sk.sku_id);
      if (index !== -1) {
        ids[index].facing += 1;
      } else {
        ids.push({ id: sk.sku_id, facing: 1 })
      }
    })
    return ids;
  }

  getFilledShelfCount() {
    let count: number = 0;
    this.cooler_config.shelves?.forEach(shelf => {
      let shelf_facings = shelf.no_of_facings * (this.cooler_config.no_of_doors);

      let filled_facings = this.planogram_placed_skus.filter(ps => ps.shelf === shelf.cooler_shelf);

      if (shelf_facings === filled_facings.length) {
        count++;
      }

    })
    return count;
  }

  skuAddEvent(event: any) {
    this.add_sku_click_event_subject.next(event);
    this.skuPlacementFlagUpdate();
  }

  skuPlacementFlagUpdate = () => {
    if (!this.sku_placement_change) {
      this.skuPlacementEventEmitter.emit(true);
    }
  }

  addDropItemId = (obj: { id: string, row_no: number, position: number }) => {
    if (!this.drop_item_ids.includes(obj.id))
      this.drop_item_ids.push(obj.id);
  }

  abbreviateNumber = (num: number | string, is_dimension_conersion: boolean = false, is_abbr: boolean = true, number_format:string = 'default') => {
    let num_ = Number(num)
    if (!isNaN(num_)) {

      if (is_dimension_conersion && num_) {
        let feet = ((num_ * 0.393700) / 12);
        return Math.floor(feet) + " ' " + Math.round((feet - Math.floor(feet)) * 12);
      } else if (is_abbr) {
        // let decimal_place = number_format !== 'default' ? 2 : CONFIG.DECIMALPLACE
        return abbreviateNumber(num_, CONFIG.DECIMALPLACE,  number_format)
      }
    }
    return num;
  }

  calculateSpaceProgress = (space_fill: any) => {
    if (!isNaN(space_fill.planogram_value) && !isNaN(space_fill.base_value)) {
      return (space_fill.planogram_value / space_fill.base_value) * 100
    }
    return 0;
  }

  getSpaceMetricValue = (space_fill: any) => {
    if(space_fill.id === 1 || space_fill.id === 5 || space_fill.id === 6){
      if (!isNaN(space_fill.planogram_value) && !isNaN(space_fill.base_value)){
        // console.log("(space_fill.base_value) :: ", space_fill.base_value , " space_fill.planogram_value :: ",space_fill.planogram_value )
        return Math.round((space_fill.planogram_value / space_fill.base_value) * 100) + '%'
      }
    }else{
      return this.abbreviateNumber(space_fill.planogram_value , space_fill.id ===
        5 || space_fill.id === 6, space_fill.id !== 2 && space_fill.id !==
        3 && space_fill.id !== 4) + '/' + this.abbreviateNumber(space_fill.base_value , space_fill.id === 5 ||
        space_fill.id === 6, space_fill.id !== 2 && space_fill.id !== 3 && space_fill.id !== 4
        )
    }
    
    return '-/-';
  }

  getRepresentationMetricValue = (representation: any)=>{
    return (!isNaN(representation.planogram_value) ? Math.round(representation.planogram_value*100)/100 : 0) + '/' +  (!isNaN(representation.industry_standard) ? Math.round(representation.industry_standard*100)/100 : 0)  + '%'
  }

  updateCoolerOrientation = (orientation: boolean) => {
    this.vertical_cooler_orientation = orientation;
    this.coolerOrientationEventEmitter.emit(orientation);
    this.planogramState.clearAutofillThroughputCalculation();
    if (orientation) {
      this.emptyCooler()
      this.planogramState.updateHighlightSkuPlaceholder(true);
    }
  }

  getBlockFacings = (block_index: number) => {
    let facings: {ic_facing :number[], fc_facing:number[]} = {ic_facing:[], fc_facing: []}
    let fc_facing_count = 0, ic_facing_count = 0;
    // no_of_facings_fc = 1 if no_of_facings_ic==1 else round((vertical_brand_association[row]["no_of_facings"]*ic_avg_width)/fc_avg_width)
    Object.values(this.vertical_brand_association).every((block: any, index) => {
      let fc_count = block.no_of_facings == 1 ? 1 : Math.round((block.no_of_facings*this.cooler_config.avg_sku_width_ic)/this.cooler_config.avg_sku_width_fc)
      if ((index + 1) === block_index) {
        facings.ic_facing = Array(block.no_of_facings).fill(0).map((_x, i) => i + ic_facing_count + 1);
        facings.fc_facing = Array(fc_count).fill(0).map((_x, i) => i + fc_facing_count + 1);
        return false;
      }
      ic_facing_count += block.no_of_facings;
      fc_facing_count += fc_count;
      return true;
    })
    // console.log("block_facings :: ", facings)
    return facings;
  }

  clearVerticalColumnPlacement = (block: number) => {
    let block_facings = this.getBlockFacings(block);
    let filterd_column = this.planogram_placed_skus.filter(s => (s.sku_volume <= CONFIG.ICSHELFMAXVOLUME && block_facings.ic_facing.includes(s.position!)) || 
    (s.sku_volume > CONFIG.ICSHELFMAXVOLUME && block_facings.fc_facing.includes(s.position!)));
    if (filterd_column.length > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogModalComponent, {
        // height: '200px',
        width: '500px',
        data: {
          header_msg: 'Are you sure you want to cancel?',
          header_msg_description: 'All progress will be lost.',
          // cancel_action: 'Cancel',
          ok_action: 'Continue'
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.planogramState.updateSkuPlacing(this.planogram_placed_skus.filter(s => (s.sku_volume <= CONFIG.ICSHELFMAXVOLUME && !block_facings.ic_facing.includes(s.position!)) || 
          (s.sku_volume > CONFIG.ICSHELFMAXVOLUME && !block_facings.fc_facing.includes(s.position!))))
          this.planogramState.updateHighlightSkuPlaceholder(true);
        }
      });

    }
  }

  updateVerticalColumnBrandAssociation = (brand_association: any) => {
    this.vertical_brand_association = brand_association;
    // console.log(" vertical block config : : ", brand_association)
    this.verticalColumnBrandAssociationEventEmitter.emit(brand_association);
  }

  isShowNote = (space_fill: any)=>{
    let is_show : boolean = false;
    if(space_fill.note){
      if (!isNaN(space_fill.planogram_value) && !isNaN(space_fill.base_value) && space_fill.planogram_value > space_fill.base_value){
        is_show = true
      } else if(space_fill.id == 5){
        let cooler_width = this.cooler_config.cooler_width_calc * (1 - this.dim_benchmark_clearance);
        let no_of_doors : number[] = Array(this.cooler_config.no_of_doors).fill(0).map((_x, i) => i + 1)
        let shelfs : number[] = []
        this.cooler_config.shelves.forEach(shelf=>{
          let is_overlay = no_of_doors.every(door=>{
            let skus_placed_on_row = this.planogram_placed_skus.filter(sku => sku.shelf === shelf.cooler_shelf && sku.position! > (door-1)*shelf.no_of_facings &&  sku.position! <= door*shelf.no_of_facings);
            let filled_width = getFilledWidth(skus_placed_on_row);
            if(filled_width > cooler_width){
              // console.log("shelf :: ", shelf , " diff :: ", filled_width - cooler_width)
              return false;
            }
            return true;
          })
          if(!is_overlay){
            is_show = true
            shelfs = shelfs.concat(shelf.cooler_shelf)
            // return false
          }
          // return true
        })
        if(is_show){
          if(shelfs.length> 1){
            space_fill.note = 'Shelves '  + shelfs.join(' , ') + ' are ' + CONFIG.COOLEROVERSHOOTING.SHELFWIWIDTH;
          }else{
            space_fill.note = 'Shelf ' + shelfs[0] + ' is ' + CONFIG.COOLEROVERSHOOTING.SHELFWIWIDTH;
          }
          this.space_filleds = this.space_filleds.map(s=> {
            if(s.id === space_fill.id){
              s.note = space_fill.note
            }
            return s
          })
        }

      }else if(space_fill.id == 6){
        let shelfs : number[] = []
        this.cooler_config.shelves.forEach(shelf=>{
          let skus_placed_on_row = this.planogram_placed_skus.filter(sku => sku.shelf === shelf.cooler_shelf);
          let height = shelfHeight(shelf.cooler_shelf, this.cooler_config)
          let is_grater = skus_placed_on_row.every(s => height > s.sku_height)
          if(!is_grater){
            is_show = true
            shelfs = shelfs.concat(shelf.cooler_shelf)
          }
        })

        if(is_show){
          if(shelfs.length> 1){
            space_fill.note = 'Shelves '  + shelfs.join(' , ') + ' are ' +  CONFIG.COOLEROVERSHOOTING.SHELFHEIGHT;
          }else{
            space_fill.note = 'Shelf ' + shelfs[0] + ' is ' + CONFIG.COOLEROVERSHOOTING.SHELFHEIGHT;
          }
          this.space_filleds = this.space_filleds.map(s=> {
            if(s.id === space_fill.id){
              s.note = space_fill.note
            }
            return s
          })
        }
      }
    }
    return is_show
  }

  triggerMenuOpen = (open_menu_trigger: MatMenuTrigger, close_menu_trigger:MatMenuTrigger[])=>{
    // close_menu_trigger.forEach(menu=>{
    //   menu.closeMenu()
    // })
    open_menu_trigger.openMenu()
  }

  closeMyMenu = (close_menu_trigger:MatMenuTrigger[])=>{
    console.log("function called...")
     close_menu_trigger.forEach(menu=>{
      menu.closeMenu()
    })
  }

  changeNumberFormat = (metric_name: string, number_format:string)=>{
    let name : 'revenue_number_format' | 'profit_number_format' = metric_name + '_number_format' as any
    this[name] = number_format
    console.log("metric_name :: ", metric_name, " , number_format :: ", number_format)
  }

}
