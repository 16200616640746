import { FormControl } from "@angular/forms";
import { environment } from "src/environments/environment";
import { PlanogramModal, SKUModal } from "src/modals/planogram";
import { CONFIG } from "./app-config";

export function noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { 'whitespace': true };
}

export function getPlanogramImageUrl(id: number, name: string, is_time_span: boolean = true) {
  if (is_time_span) {
    return `https://${environment.picturesAccount}.blob.core.windows.net/${environment.picturesContainer}/${id}/latest/${environment.environment_name}/${name}-cooler-image?timespan=${new Date().getTime()}`;
  } else {
    return `https://${environment.picturesAccount}.blob.core.windows.net/${environment.picturesContainer}/${id}/latest/${environment.environment_name}/${name}-cooler-image?`;
  }
}

export function abbreviateNumber(num: number, fixed: number = CONFIG.DECIMALPLACE, number_format: string = 'default') {
  if (num === null || num === undefined) { return null; } // terminate early
  if (isNaN(num) || num === 0) { return '0'; } // terminate early
  fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
  let b = (num).toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(Number(b[1].slice(1)), 14) / 3); // floor at decimals, ceiling at trillions
    k = number_format != 'default' ? CONFIG.NUMBER_SUFFIX.findIndex(s=> s===number_format ) : k;
  let c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(fixed), // divide by power
    d = Number(c) < 0 ? c : Math.abs(Number(c)), // enforce -0 is 0
    abbr = Number(d).toFixed(fixed) + CONFIG.NUMBER_SUFFIX[k];
  return abbr;
}

export function sortSkusAccordingToBrandOrderAndSize(sku_list: SKUModal[]) {
  sku_list.sort((sk1, sk2) => {
    if (sk1.brand_order && sk2.brand_order) {
      if (sk1.brand_order < sk2.brand_order) {
        return -1;
      } else if (sk1.brand_order > sk2.brand_order) {
        return 1;
      } else {
        return sk1.sku_volume - sk2.sku_volume
      }
    } else {
      return 0
    }
  })
  return sku_list
}

export function getPlacedSkuDetail(planogram_placed_skus: SKUModal[]) {
  let skus: any = {};
  planogram_placed_skus.forEach(sk => {
    if (skus[sk.sku_id]) {
      skus[sk.sku_id] += 1;
    } else {
      skus[sk.sku_id] = 1
    }
  })
  return skus;
}

export function shelfHeight(row_index: number, cooler_config: PlanogramModal){
  let height_ = 0;
  if(cooler_config.ic_shelves && !cooler_config.ic_shelves.includes(row_index)){
    height_= cooler_config.max_sku_height_fc + CONFIG.SHELF_HEIGHT_CLEARANCE
  }else{
    height_= cooler_config.max_sku_height_ic + CONFIG.SHELF_HEIGHT_CLEARANCE
  }
  return height_
}

export function getChartColor(categories: string[], config:any) {
  let color: string[] = [];
    
  categories.forEach(category => {
    category=category.replace(/\s+/g, '')
    if (config[category.toLowerCase()]){
      color.push(config[category.toLowerCase()]);
    }else{
      color.push('');
    }
  })
  return color;
}

export function  getFilledWidth(skus_placed_on_row: SKUModal[]){
  let filled_width = 0;
  skus_placed_on_row.forEach(sk => {
    filled_width += sk.sku_diameter_length
  })
  return filled_width;
}