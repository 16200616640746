
<mat-dialog-content [ngStyle]="{'text-align': 'left' }" >
    <div class="form-group">
        <label for="planogram_name"
            [ngClass]="{'text-danger' : (planogram_name_error | async)  !== ''}">Planogram Name
            <span class="astrick-class"> *</span>
        </label>
        <div style="display: flex; align-items: center;">
            <input type="text" class="form-control" id="planogramname"
                [formControl]="planogram_name" placeholder="Enter Planogram Name" required
                style="min-width: 100%"
                [ngClass]="{'is-invalid' : (planogram_name_error | async)  !== ''}">
            <em class="bi" [ngClass]="{'bi-check-circle text-success': (planogram_name.valid && (planogram_name_error | async)  === ''),
            'bi-x-circle text-danger': (planogram_name_error | async)  !== ''}"
                style="margin: 0 5px;"></em>
        </div>

        <span *ngIf="(planogram_name_error | async)  !== ''" class="error-message">
            {{planogram_name_error | async}} </span>
        <span class="error-message" *ngIf="planogram_name.errors?.['pattern']">
            <span *ngIf="(planogram_name_error | async)  !== ''">,</span> Special characters are not allowed.
        </span>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close>Cancel</button>
    <button mat-flat-button color="warn" cdkFocusInitial (click)="validatePlanogramname()" >Save</button>
  </mat-dialog-actions>
  
