<div id="compare-planogram-container">
    <div>
        <mat-grid-list [cols]="compare_planogram_ids.length + 2" rowHeight="280px" class="image-preview-list">
            <mat-grid-tile class="left-align-grid-tile">
                <button mat-stroked-button (click)="goBack()">
                    <em class="bi bi-arrow-left" style="margin-right: 5px;"></em>
                    Go Back
                </button>
            </mat-grid-tile>

            <mat-grid-tile *ngFor="let id of compare_planogram_ids">
                <div>
                    <div
                        class="image-preview mat-badge mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium">
                        <img style="height: 150px;max-width: 100%;" [src]="getPlanogramPreviewImageSrc(id)" alt="" />
                        <em class="bi bi-x-circle mat-badge-content mat-badge-active" style="color: red;cursor: pointer;"
                            (click)="removePlanogram(id)"></em>
                    </div>
                    <p class="name mat-badge mat-badge-above mat-badge-before mat-badge-medium">
                        {{compare_planogram_data[id].planogram_name}}
                        <em class="bi bi-eye mat-badge-content mat-badge-active" style="color: red;cursor: pointer;"
                            (click)="openImagePreviewDialog()"></em>
                    </p>
                    <p>{{compare_planogram_data[id]['cooler_details'].doors}} Door
                        <!-- .{{compare_planogram_data[id]['cooler_details'].cooler_capacity}} -->
                    </p>
                </div>
            </mat-grid-tile>

            <mat-grid-tile class="right-align-grid-tile">
                <mat-card class="add-another-planogram-card" (click)="addPlanogramToCompare()"
                    [ngClass]="{'disable': compare_planogram_ids.length === 3}">
                    <em class="bi bi-plus"></em>
                    <p>Add another planogram</p>
                </mat-card>
            </mat-grid-tile>
        </mat-grid-list>

        <div id="planogram-compare-data-accordion-container" style="height: calc(100% - 280px);"
            *ngIf="compare_planogram_ids.length > 0">
            <div style="height: 100%; width: 100%; overflow: auto;">
                <mat-accordion id="planogram-data-accordion" multi>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Overview </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="compare_planogram_data">
                            <mat-grid-list [cols]="compare_planogram_ids.length + 2" class="planogrm-sales-grid"
                                rowHeight="50px">
                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile"> Channel
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{compare_planogram_data[id]['overview']?.channel}}
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile">
                                    Sub-Channel </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{compare_planogram_data[id]['overview']?.sub_channel}}
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile"> Segment
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{compare_planogram_data[id]['overview']?.segment}}
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile"> Business
                                    Goals </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{compare_planogram_data[id]['overview']?.business_goal.join(', ')}}
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="left-align-grid-tile"> Status </mat-grid-tile>
                                <mat-grid-tile *ngFor="let id of compare_planogram_ids; let p_index = index"
                                    colspan="1">
                                    <span class="planogram-status"
                                        [ngClass]="{'active' : compare_planogram_data[id]['overview']?.status === CONFIG.PLANOGRAMSTATUS.ACTIVE,
                                'inactive': compare_planogram_data[id]['overview']?.status === CONFIG.PLANOGRAMSTATUS.INACTIVE, 
                             'draft': compare_planogram_data[id]['overview']?.status === CONFIG.PLANOGRAMSTATUS.DRAFT}">
                                        {{compare_planogram_data[id]['overview']?.status}}
                                    </span>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1"></mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Cooler Details </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="compare_planogram_data">
                            <mat-grid-list [cols]="compare_planogram_ids.length + 2" class="planogrm-sales-grid"
                                rowHeight="50px">
                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile"> Cooler
                                    Type </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{compare_planogram_data[id]['cooler_details']?.cooler_type}}
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile"> Banner
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{compare_planogram_data[id]['cooler_details']?.banner}}
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile"> Doors
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{compare_planogram_data[id]['cooler_details']?.doors}}
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile"> Shelves
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{compare_planogram_data[id]['cooler_details']?.shelves}}
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile"> Facings
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{compare_planogram_data[id]['cooler_details']?.facings}}
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="left-align-grid-tile"> Door Handle Direction
                                </mat-grid-tile>
                                <mat-grid-tile *ngFor="let id of compare_planogram_ids; let p_index = index"
                                    colspan="1">
                                    {{compare_planogram_data[id]['cooler_details']?.traffic_direction}}
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1"></mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>Estimated Annualized Throughput </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="compare_planogram_data">
                            <mat-grid-list [cols]="compare_planogram_ids.length + 2" class="planogrm-sales-grid"
                                rowHeight="50px">
                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile"> Revenue
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{abbreviateNumber(compare_planogram_data[id]['commercials']?.revenue)}} M-LC
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile"> Profit
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{abbreviateNumber(compare_planogram_data[id]['commercials']?.profit)}} M-LC
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile"> Volume
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    {{abbreviateNumber(compare_planogram_data[id]['commercials']?.volume)}} UC
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="left-align-grid-tile"> Transaction </mat-grid-tile>
                                <mat-grid-tile *ngFor="let id of compare_planogram_ids; let p_index = index"
                                    colspan="1">
                                    {{abbreviateNumber(compare_planogram_data[id]['commercials']?.transaction)}} Units
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1"></mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="assortment-panel" [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title> Assortment </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="compare_planogram_data">
                            <mat-grid-list [cols]="compare_planogram_ids.length + 2" class="planogrm-assortment-grid"
                                rowHeight="200px">
                                <mat-grid-tile colspan="1" class="border-bootom-content left-align-grid-tile">
                                    <div>Category Distribution 
                                        <div class="chart-labels-container">
                                         <div class="chart-labels-div" *ngFor="let label of getLabels('category_distribution')" > 
                                            <div [ngStyle]="{background: categorychartcolor[getConfigLabel(label)] || 'black'}" ></div> {{label}}
                                         </div>
                                        </div> 
                                    </div> 
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content"
                                    *ngFor="let id of compare_planogram_ids; let p_index = index" colspan="1">
                                    <canvas id="category-chart" baseChart
                                        [data]="getBarChartData(compare_planogram_data[id]['assortment'].category_distribution, true)"
                                        [options]="chart_options" [type]="'bar'">
                                    </canvas>
                                </mat-grid-tile>
                                <mat-grid-tile class="border-bootom-content" colspan="1"></mat-grid-tile>

                                <mat-grid-tile colspan="1" class="left-align-grid-tile">
                                    <div>Brands Distribution
                                        <div class="chart-labels-container" >
                                            <div class="chart-labels-div" *ngFor="let label of getLabels('brands_distribution')" > 
                                                <div [ngStyle]="{background: brandchartcolor[getConfigLabel(label)] || 'black'}" ></div> {{label}}
                                            </div>
                                           </div> 
                                    </div> 
                                </mat-grid-tile>
                                <mat-grid-tile *ngFor="let id of compare_planogram_ids; let p_index = index"
                                    colspan="1">
                                    <canvas id="brand-chart" baseChart
                                        [data]="getBarChartData(compare_planogram_data[id]['assortment'].brands_distribution, false)"
                                        [options]="chart_options" [type]="'bar'">
                                    </canvas>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1"></mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-expansion-panel>

                </mat-accordion>
            </div>

        </div>

    </div>
</div>

<app-loader [is_show_loader]="(is_show_loader | async)!"></app-loader>