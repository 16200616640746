<div id='sku-component' style="height: 100%; width: 100%;">
  <button mat-stroked-button color="warn" id="auto-fill-sku-btn"
    [disabled]="vertical_cooler_orientation && vertical_column_brand_mapping_form.invalid"
    (click)="autoFillSkus()">Auto-fill SKUs</button>

  <mat-tab-group class="sku-mat-tab-group" animationDuration="0ms" scrollDistance="180"  [selectedIndex]="1"
    style="height: calc(100% - 70px)">
    <mat-tab label="Orientation" [disabled]="vertical_cooler_orientation && vertical_column_brand_mapping_form.invalid">
      <div class="form-group">
        <div style="display:flex; align-items:center; margin-bottom: 10px;">
          <mat-label>Horizontal</mat-label>
          <mat-slide-toggle style="margin: 0 8px;" [checked]="vertical_cooler_orientation" color="warn"
            class="custom-slide" (change)="updateOrientation($event)"></mat-slide-toggle>
          <mat-label>Vertical</mat-label>
        </div>
        <!-- <mat-checkbox (change)="updateOrientation($event)" [checked]="vertical_cooler_orientation" > Vertical </mat-checkbox> -->
        <div *ngIf="vertical_cooler_orientation && cooler_config.ic_flag" [formGroup]="vertical_column_brand_mapping_form">
          <label class="astrick-class" style="margin-bottom: 10px;" >Individual block facings should cumulatively add up to total available facings on one IC shelf of cooler. * </label>
          <div *ngFor="let block_index of counter(verticalBlocks()); ">
            <div class="form-group mb-3">
              <label>Block {{block_index}}
                <span class="astrick-class"> *</span>
              </label> <em *ngIf="block_index === verticalBlocks() && block_index !== 1"
                class="bi bi-trash block-delete-icon" (click)="removeBlock(block_index)"></em>

              <mat-select class="custom-select d-block w-100" placeholder="Select Brand" multiple
                formControlName="block{{block_index}}" disableOptionCentering
                (selectionChange)="handleBrandChange($event, block_index)" panelClass="multiple-select-panel-class">
                <mat-option *ngFor="let brand of available_brands" [value]="brand">
                  {{brand}}
                </mat-option>
              </mat-select>

              <span class="error-message"
                *ngIf="vertical_column_brand_mapping_form.controls['block' + block_index].errors?.['required']">
                Minimum 1 Brand selection is required.
              </span>
              <span class="error-message"
                *ngIf="vertical_column_brand_mapping_form.controls['block' + block_index].errors?.['blockbrand']">
                {{CONFIG.VERTICAL_BLOCK_BRAND_ERROR}}
              </span>
            </div>

            <div class="form-group">
              <ng-template #blockfacing let-control="control" let-max="max"
                            [ngTemplateOutlet]="blockfacing"
                            [ngTemplateOutletContext]="{ control: vertical_column_brand_mapping_form.controls['block' + block_index + 'facingcount'], 
                            max: getBlockMaxFacing(block_index) }">
              <label>No. of Facings
                <span class="astrick-class"> *</span>
              </label>
              <div class="input-group">
                <!-- (ngModelChange)="handleBlockFacingChange($event, block_index)" -->
                <input type="number" class="form-control right-icon"
                  [formControlName]="'block' + block_index + 'facingcount'"
                  (ngModelChange)="handleBlockFacingChange($event, block_index)" placeholder="Enter No. of Facings"
                  required>
                <div class="input-group-append transparent">
                  <span class="input-group-text">
                    <em class='bi bi bi-dash-circle-fill text-danger' aria-hidden='true'
                      (click)="control.value > 0 ? handleFormFeildCounterChnage(control, -1) : null"
                      [ngClass]="{'disable-input-icon' : control.invalid,'inactive-input-icon':control.value === 0}"></em>
                    <em class='bi bi-plus-circle-fill text-danger' aria-hidden='true'
                      (click)="control.value < max ? handleFormFeildCounterChnage(control, 1) : null"
                      [ngClass]="{'disable-input-icon' : control.invalid, 'inactive-input-icon':control.value === max}"></em>
                  </span>
                </div>
              </div>

              <span class="error-message"
                *ngIf="control.errors?.['required']">
                No of Facings is required.
              </span>
              <span class="error-message"
                *ngIf="control.errors?.['max']">
                No of Facings should be less than {{control.errors?.['max'].max + 1}}
              </span>
              <span class="error-message"
                *ngIf="control.errors?.['min']">
                No of Facings should be greater than 0.
              </span>
              <span class="error-message"
                *ngIf="!control.errors?.['max'] && control.errors?.['blockfacing']">
                Total No of Blocks facings should be less than {{control.errors?.['blockfacing'].max}}.
              </span>
              </ng-template>
            </div>
          </div>
          <div id="add-new-block" [ngClass]="isAddNewBlock() ? 'text-danger' : 'disable' " (click)="addBlock()">
            <span>
              <em class="bi bi-plus-circle"></em>
              Add New
            </span>
          </div>
        </div>
        <div *ngIf="vertical_cooler_orientation && !cooler_config.ic_flag">
          <label class="astrick-class" style="margin-bottom: 10px;" >Vertical assortment of SKUs cannot be generated for FC specialized coolers.</label>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Browse SKU" [disabled]="vertical_cooler_orientation && vertical_column_brand_mapping_form.invalid">
      <mat-form-field appearance="fill" class="sku-search-input">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput [(ngModel)]="sku_search_input" type="text" placeholder="Search by SKU Name">
        <!-- <mat-icon>sort</mat-icon> -->

      </mat-form-field>

      <mat-chip-list>
        <mat-chip disableRipple [ngClass]="sku_filter === 'all' ? 'selected-sku-filter-chip' : '' "
          (click)="filterSkus('all')">All</mat-chip>
        <mat-chip disableRipple [ngClass]="sku_filter === 'recommanded' ? 'selected-sku-filter-chip' : '' "
          (click)="filterSkus('recommanded')">Recommended</mat-chip>
        <mat-chip disableRipple [ngClass]="sku_filter === 'picos' ? 'selected-sku-filter-chip' : '' "
          (click)="filterSkus('picos')">PicOS based</mat-chip>
      </mat-chip-list>
      <div style="height: calc(100% - 100px); overflow: auto;">
        <mat-list cdkDropList cdkDropListSortingDisabled id='subjectList' [cdkDropListConnectedTo]="drop_item_ids">
          <mat-list-item class="no-padding sku-list-item"
            *ngFor="let sku of filter_sku_list | sortAndFilter :  sku_search_input.trim().toLowerCase() : 'sku_name'"
            cdkDrag (cdkDragStarted)="dragStart($event)" (cdkDragMoved)="moved($event)" [cdkDragData]="sku">
            <img mat-list-icon [src]='sku.sku_img_link + sas' alt="..." class="static-sku-dimension" [ngStyle]="{'height.px': sku.sku_height*CONFIG.MMTOPIXCEL/CONFIG.HEIGHTCONSTANT , 
           'width.px': sku.sku_diameter_length*CONFIG.MMTOPIXCEL/CONFIG.WIDTHCONSTANT , 'border-radius':0}" />
            <h3 mat-line> {{sku.sku_name}} </h3>
            <p mat-line>
              <span> {{sku.sku_volume}}ml </span>
            </p>

            <h3 mat-line>
              Velocity - <strong>{{abbreviateNumber(Math.round(sku.velocity))}}</strong> Units/year
              <!-- <span> Velocity - {{Math.round(sku.velocity)}} Units/year </span> -->
            </h3>
            <span mat-line style="color: red; text-align: right; font-size: 21px;padding-top: 2px;">
              <em class="bi bi-info-circle" style="margin-right: 10px;cursor:pointer"
                (click)="showSKUInformation(sku)"></em>
              <em class="bi bi-plus-circle-fill" style="cursor:pointer" (click)="addSku(sku)"></em>
            </span>

            <img *cdkDragPreview [src]='sku.sku_img_link + sas' alt="..." [ngStyle]="{'height.px': sku.sku_height*CONFIG.MMTOPIXCEL/CONFIG.HEIGHTCONSTANT , 
           'width.px': sku.sku_diameter_length*CONFIG.MMTOPIXCEL/CONFIG.WIDTHCONSTANT , 'border-radius':0}">
          </mat-list-item>
        </mat-list>
      </div>

    </mat-tab>

    <mat-tab label="Edit Cooler Facings"
      [disabled]="vertical_cooler_orientation && vertical_column_brand_mapping_form.invalid">
      <!-- <div class="form-group">
        <label for="facing">Facings on each Shelf</label>
        <div class="input-group">
          <input type="number" class="form-control right-icon" id="facing" [formControl]="shelf_facing_input"
            placeholder="Enter Facing" required>

          <div class="input-group-append transparent">
            <span class="input-group-text">
              <i class='bi bi bi-dash-circle-fill text-danger' aria-hidden='true'
                (click)="handleFormFeildCounterChnage(shelf_facing_input, -1)"
                [ngClass]="{'disable-input-icon' : shelf_facing_input.invalid}"></i>
              <i class='bi bi-plus-circle-fill text-danger' aria-hidden='true'
                (click)="handleFormFeildCounterChnage(shelf_facing_input, 1)"
                [ngClass]="{'disable-input-icon' : shelf_facing_input.invalid}"></i>
            </span>
          </div>
        </div>
        <span class="error-message" *ngIf="shelf_facing_input.errors?.['required']">
          Facing is required.
        </span>
        <span class="error-message" *ngIf="shelf_facing_input.errors?.['max']">
          Facing should be less than {{max_facing+1}}
        </span>
        <span class="error-message" *ngIf="shelf_facing_input.errors?.['min']">
          Facing should be greater than 0.
        </span>
      </div> -->

      <div [formGroup]="edit_facing_form_group">

        <div class="form-group" *ngIf="cooler_config.ic_flag">
          <ng-template #icfacingformcontrol let-control="control" let-max="max"
                            [ngTemplateOutlet]="icfacingformcontrol"
                            [ngTemplateOutletContext]="{ control: edit_facing_form_group.controls['ic_shelf_facings'], 
                            max: getMaxFacing(cooler_config.avg_sku_width_ic, cooler_config.cooler_width_calc) }">
          <label for="facing">Facings in each IC Shelf <span class="astrick-class"> *</span>
          </label>
          <span class="right content">Average width of IC SKU's
            {{cooler_config.avg_sku_width_ic}} mm</span>
          <div class="input-group">
            <input type="number" class="form-control right-icon" id="ic-facing" formControlName="ic_shelf_facings"
              [ngClass]="{'is-invalid': control.invalid && (control.dirty || control.touched)}"
              placeholder="Enter IC Shelf Facings" required>

            <div class="input-group-append transparent">
              <span class="input-group-text">
                <em class='bi bi bi-dash-circle-fill text-danger' aria-hidden='true'
                  [ngClass]="{'disable-input-icon' : control.invalid, 'inactive-input-icon':control.value === 0}"
                  (click)="control.value > 0 ? handleFormFeildCounterChnage(control, -1) : null"></em>
                <em class='bi bi-plus-circle-fill text-danger' aria-hidden='true'
                  [ngClass]="{'disable-input-icon' : control.invalid, 'inactive-input-icon':control.value === max}"
                  (click)="control.value < max ? handleFormFeildCounterChnage(control, 1) : null"></em></span>
            </div>
          </div>
          <span class="error-message" *ngIf="control.errors?.['required']">
            IC Shelf Facings is required.
          </span>
          <span class="error-message" *ngIf="control.errors?.['max']">
            IC shelf Facings should be less than {{control.errors?.['max'].max+1}}
          </span>
          <span class="error-message" *ngIf="control.errors?.['min']">
            IC shelf Facings should be greater than 0.
          </span>
          </ng-template>
        </div>

        <div class="form-group" *ngIf="cooler_config.fc_flag">
          <ng-template #fcfacingformcontrol let-control="control" let-max="max"
                            [ngTemplateOutlet]="fcfacingformcontrol"
                            [ngTemplateOutletContext]="{ control: edit_facing_form_group.controls['fc_shelf_facings'], 
                            max: getMaxFacing(cooler_config.avg_sku_width_fc, cooler_config.cooler_width_calc) }">
          <label for="facing">Facings in each FC Shelf<span class="astrick-class"> *</span>
          </label>
          <span class="right content">Average width of FC SKU's
            {{cooler_config.avg_sku_width_fc}} mm</span>

          <div class="input-group" >
            <input type="number" class="form-control right-icon" id="fc-facings" formControlName="fc_shelf_facings"
              [ngClass]="{'is-invalid': control.invalid && (control.dirty || control.touched)}"
              placeholder="Enter Facings" required>

            <div class="input-group-append transparent">
              <span class="input-group-text">
                <em class='bi bi bi-dash-circle-fill text-danger' aria-hidden='true'
                  [ngClass]="{'disable-input-icon' : control.invalid, 'inactive-input-icon':control.value === 0}"
                  (click)="control.value > 0 ? handleFormFeildCounterChnage(control, -1) : null"></em>
                <em class='bi bi-plus-circle-fill text-danger' aria-hidden='true'
                  [ngClass]="{'disable-input-icon' : control.invalid, 'inactive-input-icon':control.value === max}"
                  (click)="control.value < max ? handleFormFeildCounterChnage(control, 1) : null"></em></span>
            </div>
          </div>
          <span class="error-message" *ngIf="control.errors?.['required']">
            FC Shelf Facings is required.
          </span>
          <span class="error-message" *ngIf="control.errors?.['max']">
            FC Shelf Facings should be less than {{control.errors?.['max'].max+1}}
          </span>
          <span class="error-message" *ngIf="control.errors?.['min']">
            FC Shelf Facings should be greater than 0.
          </span>
          </ng-template>
        </div>
        <button mat-raised-button color="warn" [disabled]="edit_facing_form_group.invalid || !is_update_facing" (click)="updateICFCFacings()" >
          Update Facings
      </button>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>