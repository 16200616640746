<div style="height: 100%; width: 100%;"  [hidden]="!is_display" >
    <mat-grid-list class="planogram-publish-grid" cols="6">

        <mat-grid-tile colspan="2" style="text-align: center;">
            <div class="grid-tile-content">
                <em class="bi bi-arrow-left back-icon" (click)="goToPreviousStepEventEmitter.emit(2)"></em>
                <h3 class="sticky-header" >Preview</h3>
                <div id="icons">
                    <em style="cursor: pointer;" class="bi bi-download" (click)="downloadImage()"></em>
                </div>

                <img alt="" style="max-width: 100%;" [src]="planogram_image_url"/>

                <mat-spinner *ngIf="!planogram_image_url" style="margin: 40px auto;" ></mat-spinner>
            </div>

        </mat-grid-tile>

        <mat-grid-tile colspan="4">
            <div class="grid-tile-content">
                <div class="sticky-header flex-div-sticky-header" >
                <h3 >Analysis</h3>

                    <button mat-button  (click)="addToCompare()" >
                        <em class="bi bi-plus"></em>
                        Add to compare
                    </button>
                </div>
                <!-- <h3 class="sticky-header" style="padding: 10px 0 15px 24px; border-bottom: 1px solid #0000001f;z-index: 3;margin: 0;">Analysis</h3>
                <div class="right-div">
                    <button mat-button color="warn" (click)="addToCompare()" >
                        <i class="bi bi-plus"></i>
                        Add to compare
                    </button>
                </div> -->
                <!-- <mat-divider></mat-divider> -->

                <mat-accordion id="planogram-data-accordion" multi>
                    <!-- #enddocregion multi -->
                    <mat-expansion-panel [expanded]="true" >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Estimated Annualized Throughput
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="selected_planogram_data">
                            <mat-grid-list cols="6" class="planogrm-sales-grid" rowHeight="4:1">
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Revenue</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{abbreviateNumber(selected_planogram_data['commercials']?.revenue)}} M-LC</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Profit</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{abbreviateNumber(selected_planogram_data['commercials']?.profit)}} M-LC</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Volume</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{abbreviateNumber(selected_planogram_data['commercials']?.volume)}} UC</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Transaction</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{abbreviateNumber(selected_planogram_data['commercials']?.transaction)}} Units</div>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Basic Information
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="selected_planogram_data">
                            <mat-grid-list cols="6" class="planogrm-sales-grid" rowHeight="4:1">
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Planogram Name</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{selected_planogram_data.planogram_name}}</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Channel</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{selected_planogram_data['overview']?.channel}}</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Sub-Channel</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{selected_planogram_data['overview'].sub_channel}}</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Segment</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{selected_planogram_data['overview'].segment}}</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Business Goal</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{selected_planogram_data['overview'].business_goal.join(', ')}}</div>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Cooler Type
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="selected_planogram_data">
                            <mat-grid-list cols="6" class="planogrm-sales-grid" rowHeight="4:1">
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Cooler Type</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{selected_planogram_data['cooler_details']?.cooler_type}}</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Doors</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{selected_planogram_data['cooler_details']?.doors}}</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Shelves</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{selected_planogram_data['cooler_details']?.shelves}}</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Number of Facings</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{selected_planogram_data['cooler_details']?.facings}}</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="1">
                                    <div class="info-header">Door Handle Direction</div>
                                </mat-grid-tile>
                                <mat-grid-tile colspan="2">
                                    <div class="info">{{selected_planogram_data['cooler_details']?.traffic_direction}}
                                    </div>
                                </mat-grid-tile>
                            </mat-grid-list>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="assortment-panel" >
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Assortment
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div *ngIf="selected_planogram_data">
                            <table id="product-added-list-table" mat-table *ngIf="dataSource.data.length > 0" aria-describedby=""
                                [dataSource]="dataSource" class="my-table mat-elevation-z8">

                                <ng-container matColumnDef="product_added">
                                    <th class="table-header" style="padding-left: 0" mat-header-cell *matHeaderCellDef >
                                        PRODUCTS ADDED </th>
                                    <td mat-cell *matCellDef="let product" style="padding-left: 0"> {{product.product_added}} </td>
                                </ng-container>

                                <ng-container matColumnDef="facings">
                                    <th class="table-header" mat-header-cell *matHeaderCellDef  style="text-align: right;width: 150px;" > NUMBER OF FACINGS </th>
                                    <td mat-cell *matCellDef="let product" style="text-align: right;"> {{product.facings}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>

                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Category Distribution
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div style="width: 50%;" *ngIf="selected_planogram_data">
                            <canvas id="category-chart" aria-label="Hello ARIA World" role="img"
                            baseChart width="500px" height="200"
                            [data]="getDoughnutChartData(selected_planogram_data['assortment'].category_distribution, true)" 
                            [options]="chart_options"
                            [type]="'doughnut'">
                            </canvas>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Brand Distribution
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div style="width: 50%;" *ngIf="selected_planogram_data">
                            <canvas id="brand-chart" width="500px" height="200" baseChart 
                            [data]="getDoughnutChartData(selected_planogram_data['assortment'].brands_distribution, false)" 
                            [options]="chart_options"
                            
                            [type]="'doughnut'">
                            </canvas>
                        </div>
                    </mat-expansion-panel>

                </mat-accordion>

            </div>

        </mat-grid-tile>

    </mat-grid-list>
</div>

<app-loader [is_show_loader]="!is_display"  ></app-loader>