import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { saveAs } from 'file-saver';
import { Observable, SubscriptionLike } from 'rxjs';
import { getPlanogramImageUrl, abbreviateNumber, getChartColor } from 'src/helper/app-utils';
import { AzureBlobStorageService } from 'src/services/azure-blob-storage.service';
import { PlanogramStateService } from 'src/services/planogram-state.service';
import { ChartData, ChartOptions } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';
import { ComparePlanogramDialogComponent } from '../compare-planogram-dialog/compare-planogram-dialog.component';

@Component({
  selector: 'app-publish-planogram',
  templateUrl: './publish-planogram.component.html',
  styleUrls: ['./publish-planogram.component.css']
})

export class PublishPlanogramComponent implements OnInit, OnDestroy {

  constructor(private planogramState: PlanogramStateService, public dialog: MatDialog, private blobService: AzureBlobStorageService) { }

  @Input() planogram_id: number;
  @Input() is_display: boolean = false;
  @Output() goToPreviousStepEventEmitter = new EventEmitter<number>();

  Object = Object;
  planogram_name: string;
  planogram_image_url: string = '';

  selected_planogram_data: any;
  displayedColumns: string[] = ['product_added', 'facings'];
  dataSource = new MatTableDataSource();
  brandchartcolor: any= {};
  categorychartcolor: any= {};


  chart_options: ChartOptions = {
    responsive: true, maintainAspectRatio: false, animation: false,
    layout: { padding: 0 },
    plugins: { legend: { position: 'right', align: 'start' }, }
  }

  compare_planogram_data_subscription: SubscriptionLike;
  brandchartcolor_subscription: SubscriptionLike;
  categorychartcolor_subscription: SubscriptionLike;

  compare_planogram_data_observer: Observable<Object> = this.planogramState.compare_planogram_data;
  brandchartcolor_observer: Observable<any> = this.planogramState.brandchartcolor;
  categorychartcolor_observer: Observable<any> = this.planogramState.categorychartcolor;


  ngOnInit(): void {
    this.compare_planogram_data_subscription = this.compare_planogram_data_observer.subscribe((d: any) => {
      if (this.planogram_id && Object.keys(d).length > 0 && d[this.planogram_id]) {
        this.selected_planogram_data = d[this.planogram_id];
        this.planogram_name = d[this.planogram_id].planogram_name;
        if (this.selected_planogram_data && this.selected_planogram_data['assortment']?.products_added) {
          this.dataSource.data = Object.entries(this.selected_planogram_data['assortment']?.products_added).map(([key, value], _j) => {
            return { product_added: key, facings: value }
          })
        }
        this.planogram_image_url = getPlanogramImageUrl(this.planogram_id, this.planogram_name);
      }
    })

    this.brandchartcolor_subscription = this.brandchartcolor_observer.subscribe((brandcolor) => {
      this.brandchartcolor = brandcolor;
    })

    this.categorychartcolor_subscription = this.categorychartcolor_observer.subscribe((categorycolor) => {
      const newCategoryColor: any = {};
      for (const key in categorycolor) {
        if (categorycolor.hasOwnProperty(key)) {
          newCategoryColor[key.toLowerCase()] = categorycolor[key];
        }
      }
      console.log('keys colors',newCategoryColor);
      this.categorychartcolor =newCategoryColor;
    })

  }

  ngOnDestroy(): void {
    this.compare_planogram_data_subscription.unsubscribe();
    this.brandchartcolor_subscription.unsubscribe();
    this.categorychartcolor_subscription.unsubscribe();
  }

  getDoughnutChartData = (category: Object, is_category_distribution: boolean) => {
    let doughnutChartData: ChartData = {} as any;
    if (category) {
      let dataset = Object.values(category)
      let config = is_category_distribution ? this.categorychartcolor : this.brandchartcolor;
      let color = getChartColor(Object.keys(category), config);
      doughnutChartData = { labels: Object.keys(category), datasets: [{ data: dataset, backgroundColor: color, hoverBackgroundColor: color, hoverBorderColor: color }] };
    }
    return doughnutChartData;
  }

  downloadImage() {
    saveAs(this.planogram_image_url, `${this.planogram_name}_image`)
  }

  addToCompare() {
    this.dialog.open(ComparePlanogramDialogComponent, {
      height: '90%',
      width: '80%',
      data: {
        planogram_ids: [Number(this.planogram_id)],
        is_from_publish: true
      }
    });
  }

  abbreviateNumber = (number: number) => {
    return abbreviateNumber(number);
  }

}
