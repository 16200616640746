import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/services/data.service';
import { getCookie } from 'src/services/service-utilities';
import { UserService } from 'src/services/user.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  title = 'angular-cde-builder';
  isIframe = false;
  login_display = false;
  private readonly _destroying$ = new Subject<void>();
  saved_access_token_db ='';


  constructor(private userService: UserService,    public dataService: DataService
    ) { }

  ngOnInit() {
  //   this.isIframe = window !== window.parent && !window.opener;
  //   this.broadcastService.msalSubject$
  //   .pipe(
  //     filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
  //   )
  //   .subscribe({next:(result: EventMessage) => {
  //     if(result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS){
  //       this.saveToken(result.payload)
  //     }
  //   },
  //   error: (error) => {
  //       console.log("error :: ", error)
  //   }
  // });

  //   this.broadcastService.inProgress$
  //   .pipe(
  //     filter((status: InteractionStatus) => status === InteractionStatus.None),
  //     takeUntil(this._destroying$)
  //   )
  //   .subscribe(() => {
  //     this.login_display = this.authService.instance.getAllAccounts().length > 0;
  //   })
  if (!getCookie('hubble_access_token')) {
    // console.log('this is toekn '+ this.dataService.tokenValidation("hubble_access_token"))
    Swal.fire({
      icon: 'warning',
      title: 'Invalid Access Token ,Redirecting to Hubble !!!',
      showConfirmButton: false,
      timer: 4000,
    }).then(
      () => (window.location.href = environment.hubble_dashboard_url)
    );
    // this.dataService.tokenValidation("hubble_access_token")
  } else {
    this.dataService
      .tokenValidation(getCookie('hubble_access_token'))
      .then((res:any) => {
        if (res['code'] !== 200) {
          Swal.fire({
            icon: 'warning',
            title: res['err_msg'] + ' ,Redirecting to Hubble !!!',
            showConfirmButton: false,
            timer: 4000,
          }).then(
            () => (window.location.href = environment.hubble_dashboard_url)
          );
        }
      })
      .catch(() =>
        Swal.fire({
          icon: 'warning',
          title: 'An Error Ocurred ,Redirecting to Hubble !!!',
          showConfirmButton: false,
          timer: 4000,
        }).then(
          () => (window.location.href = environment.hubble_dashboard_url)
        )
      );
  }

  }


  saveToken(payload: any){
    if(this.saved_access_token_db !== payload.accessToken){
      let user_info = {username: payload.account.username,
        access_token:payload.accessToken,
        expiresOn:payload.expiresOn,
        name:payload.account.name
      }
      this.userService.saveUserToken(user_info).subscribe({
        next: (res: any) => {
          this.saved_access_token_db = res.access_token;
        }, error: (error) => {
          console.log("error :: ", error)
        }
      });
    }

  }

  // login() {
  //   this.authService.loginRedirect();
  // }

  // logout(){
  //   this.authService.logoutRedirect();
  // }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
