import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { Observable, SubscriptionLike } from 'rxjs';
import { APIErrorObj } from 'src/modals/planogram';
import { PlanogramStateService } from 'src/services/planogram-state.service';

@Component({
  selector: 'app-snakbar-msg-display',
  templateUrl: './snakbar-msg-display.component.html',
  styleUrls: ['./snakbar-msg-display.component.css']
})
export class SnakbarMsgDisplayComponent implements OnInit, OnDestroy {

  constructor(private snackBar: MatSnackBar, private planogramState: PlanogramStateService) { }
  snackBarRef: MatSnackBarRef<TextOnlySnackBar>;

  error_obj: Observable<APIErrorObj> = this.planogramState.error_obj;

  error_obj_subscription: SubscriptionLike;
  snackBarRef_subscription: SubscriptionLike;

  ngOnInit(): void {
    this.error_obj_subscription = this.error_obj.subscribe(error=>{
      if(error.is_error){
        this.snackBarRef = this.snackBar.open(error.error_msg,  'close', {
          duration: 3000
        });

        if(this.snackBarRef_subscription){
          this.snackBarRef_subscription.unsubscribe();
        }

       this.snackBarRef_subscription = this.snackBarRef.afterDismissed().subscribe(_info=> {
          this.planogramState.setErrorState('');
        })
      }
    })

  }

  ngOnDestroy(){
    this.error_obj_subscription.unsubscribe();
    this.snackBarRef_subscription.unsubscribe();
  }
  
}
