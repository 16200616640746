<em *ngIf="show_sku_list && is_display" class="hide-sku-icon bi bi-chevron-double-left"
    (click)="show_sku_list = !show_sku_list"></em>

<mat-sidenav-container class="side-nav-container" *ngIf="is_display" [hasBackdrop]="false">
    <mat-sidenav [opened]="show_sku_list" mode="side">
        <!-- [placeholder_width]="placeholder_width"  -->
        <app-sku-component [drop_item_ids]="drop_item_ids" [sku_list]="available_skus" [cooler_config]="cooler_config"
            [planogram_id]="planogram_id" [dim_benchmark_clearance]="dim_benchmark_clearance"
            [empty_cooler_alert]="planogram_placed_skus.length > 0" [scenario]="scenario"
            [sku_brands]="sku_brands | async" (addSkuEventEmitter)="skuAddEvent($event)"
            (skuPlacementFlagEventEmitter)="skuPlacementFlagUpdate()"
            [vertical_cooler_orientation]="vertical_cooler_orientation"
            [vertical_brand_association]="vertical_brand_association"
            (editFacingEventEmitter)="editCoolerFacings($event)"
            (clearVerticalColumnPlacementEventEmitter)="clearVerticalColumnPlacement($event)"
            (coolerOrientationEventEmitter)="updateCoolerOrientation($event)"
            (verticalColumnBrandAssociationEventEmitter)="updateVerticalColumnBrandAssociation($event)">
        </app-sku-component>
    </mat-sidenav>
    <mat-sidenav opened mode="side" position="end" class="recommandation-sidenav">
        <div style="width: 100%; height:100%">
            <as-split direction="horizontal">
                <as-split-area [size]="100">
                    <as-split direction="vertical">
                        <as-split-area [size]="75" class="right-padding-split-area">
                            <div id="metric-calculation-container">

                                <mat-tab-group  id="planogram-checklist-group" [selectedIndex]="1">
                                    <mat-tab label="Checklist">
                                        <section *ngFor="let checklist of checklists" style="margin:5px 0">
                                            <div class="checklist-container">
                                                <em class="bi"
                                                    [ngClass]="(checklist.type === 'determine' && ((checklist.planogram_value || 0)/(checklist.base_value || 0))*100 >= (checklist.compilance || 0)) || (checklist.type === 'condition-based' && checklist.condition_satisfy !== undefined && checklist.condition_satisfy === true) ? 'bi-check-circle checked selected' : 'bi-circle'"></em>
                                                <span>{{checklist.name}} <strong
                                                        *ngIf="checklist.id === 4 && skus_with_lessthan2_facing.length > 0"
                                                        [ngbPopover]="tipContent" placement="bottom-right"
                                                        container="body" autoClose="outside" #p="ngbPopover"
                                                        (click)="p.open()" triggers="manual"
                                                        popoverClass="sku-popover-class"
                                                        style="cursor: pointer; margin-left:5px;"> (
                                                        {{skus_with_lessthan2_facing.length}} ) </strong>
                                                </span>
                                            </div>
                                        </section>
                                        <ng-template #tipContent>
                                            <ul style="max-height: 200px; overflow: auto;">
                                                <li *ngFor="let sku_name of skus_with_lessthan2_facing">{{sku_name}}
                                                </li>
                                            </ul>
                                        </ng-template>

                                    </mat-tab>
                                    <mat-tab label="Representation">
                                        <!-- <mat-chip-list class="representation-chip-list">
                                            <mat-chip color="warn" selected>Industry Standards</mat-chip>
                                            <mat-chip disabled selectable="false" chipListSelectable="false">User defined
                                            </mat-chip>
                                        </mat-chip-list> -->

                                        <section class="representation-standards"
                                            *ngFor="let representation of representaions_industry_standards">
                                            <div class="left-right-element-alignment"><span><em class="bi"
                                                        [ngClass]="representation.planogram_value > 0 ? 'bi-check-circle checked selected' : 'bi-circle'"></em>
                                                    {{representation.name}}</span>
                                                <span>{{getRepresentationMetricValue(representation)}}</span>
                                            </div>
                                            <mat-progress-bar  mode="determinate"
                                                [value]="(representation.planogram_value/representation.industry_standard)*100 || 0">
                                            </mat-progress-bar>
                                        </section>
                                    </mat-tab>
                                    <mat-tab label="Space Filled">
                                        <section class="space-fiiled-standards"
                                            *ngFor="let space_fill of space_filleds">
                                            <div class="left-right-element-alignment"><span>
                                                    <em class="bi"
                                                        [ngClass]="space_fill.planogram_value > 0 ? 'bi-check-circle checked selected' : 'bi-circle'"></em>
                                                    {{space_fill.name}} <em class="bi bi-info-circle"
                                                        [ngbPopover]="tipContent" placement="bottom" container="body"
                                                        autoClose="outside" #p="ngbPopover"
                                                        triggers="mouseenter:mouseleave"
                                                        popoverClass="sku-popover-class"
                                                        style="cursor: pointer; margin-left:5px;"></em>
                                                    <!-- (click)="p.open()" triggers="manual" -->
                                                    <ng-template #tipContent>
                                                        <div>{{space_fill.info}}</div>
                                                        <div *ngIf="space_fill.note && isShowNote(space_fill)">
                                                            <strong>NOTE : </strong> {{space_fill.note}}
                                                        </div>
                                                    </ng-template>
                                                </span>
                                                <span>{{this.getSpaceMetricValue(space_fill)}} </span>

                                            </div>
                                            <mat-progress-bar  mode="determinate"
                                                [value]="calculateSpaceProgress(space_fill)">
                                            </mat-progress-bar>
                                        </section>
                                    </mat-tab>
                                </mat-tab-group>
                                <div [ngStyle]="{ 'width':'100%' }">
                                    <div id="loader-container" *ngIf="throughput_loader">
                                        <mat-spinner></mat-spinner>
                                    </div>
                                    <div
                                        *ngIf="show_business_insight && planogram_placed_skus.length > 0 ; then thenBlock else elseBlock">
                                    </div>
                                </div>

                                <ng-template #thenBlock>
                                    <h4 style="margin: 5px 0;">Estimated Annualized Throughput </h4>

                                    <table id="throughput-table" class="mat-table cdk-table" aria-describedby="">
                                        <thead role="rowgroup">
                                            <tr class="mat-header-row cdk-header-row">
                                                <th class="mat-header-cell cdk-header-cell">SKU<br>Selection</th>
                                                <th class="mat-header-cell cdk-header-cell">
                                                    <div style="text-align: left;display: inline-block;">
                                                        Transaction <br>
                                                        <div
                                                            style="display: flex; align-items: center; justify-content: left;">
                                                            <div>(Units)</div>
                                                            <mat-icon
                                                                style="cursor: pointer;font-size: 21px; width: 21px; height: 21px;"
                                                                #menuTrigger1="matMenuTrigger"
                                                                [matMenuTriggerData]="{metric_name: 'transaction'}"
                                                                [matMenuTriggerFor]="menu">keyboard_arrow_down
                                                            </mat-icon>
                                                        </div>
                                                    </div>
                                                </th>
                                                <!-- (mouseover)="triggerMenuOpen(menuTrigger1 , [menuTrigger2, menuTrigger3, menuTrigger4])"  -->
                                                <!-- <mat-menu #transactionmenu="matMenu" class="throughputmetrics-menu" [hasBackdrop]="false" > 
                                                    <button mat-menu-item (click)="changeNumberFormat()" >In K's</button>
                                                    <button mat-menu-item (click)="changeNumberFormat()" >In M's</button>
                                                  </mat-menu> -->
                                                <th class="mat-header-cell cdk-header-cell">
                                                    <div style="text-align: left;display: inline-block;">
                                                        Volume <br>
                                                        <div
                                                            style="display: flex; align-items: center; justify-content: left;">
                                                            <div>(UC)</div>
                                                            <mat-icon
                                                                style="cursor: pointer;font-size: 21px; width: 21px; height: 21px;"
                                                                #menuTrigger2="matMenuTrigger"
                                                                [matMenuTriggerData]="{metric_name: 'volume'}"
                                                                [matMenuTriggerFor]="menu">keyboard_arrow_down
                                                            </mat-icon>
                                                        </div>
                                                    </div>
                                                </th>
                                                <!-- <mat-menu #volumemenu="matMenu" class="throughputmetrics-menu" [hasBackdrop]="false"> 
                                                    <button mat-menu-item (click)="changeNumberFormat()" >In K's</button>
                                                    <button mat-menu-item (click)="changeNumberFormat()">In M's</button>
                                                  </mat-menu> -->
                                                <th class="mat-header-cell cdk-header-cell">
                                                    <div style="text-align: left;display: inline-block;">
                                                        Revenue <br>
                                                        <div
                                                            style="display: flex; align-items: center;justify-content: left;">
                                                            <div>(LC)</div>
                                                            <mat-icon
                                                                style="cursor: pointer;font-size: 21px; width: 21px; height: 21px;"
                                                                #menuTrigger3="matMenuTrigger"
                                                                [matMenuTriggerData]="{metric_name: 'revenue'}"
                                                                [matMenuTriggerFor]="menu">keyboard_arrow_down
                                                            </mat-icon>
                                                        </div>
                                                    </div>
                                                </th>
                                                <!-- <mat-menu #revenuemenu="matMenu" class="throughputmetrics-menu" [hasBackdrop]="false"> 
                                                    <button mat-menu-item (click)="changeNumberFormat()" >In K's</button>
                                                    <button mat-menu-item (click)="changeNumberFormat()" >In M's</button>
                                                  </mat-menu> -->
                                                <th class="mat-header-cell cdk-header-cell">
                                                    <div style="text-align: left;display: inline-block;">
                                                        Profit <br>
                                                        <div
                                                            style="display: flex; align-items: center; justify-content: left;">
                                                            <div>(LC)</div>
                                                            <mat-icon
                                                                style="cursor: pointer;font-size: 21px; width: 21px; height: 21px;"
                                                                #menuTrigger4="matMenuTrigger"
                                                                [matMenuTriggerData]="{metric_name: 'profit'}"
                                                                [matMenuTriggerFor]="menu">keyboard_arrow_down
                                                            </mat-icon>
                                                        </div>
                                                    </div>
                                                </th>
                                                <!-- <mat-menu #profitmenu="matMenu" class="throughputmetrics-menu" [hasBackdrop]="false"> 
                                                    <button mat-menu-item (click)="changeNumberFormat()" >In K's</button>
                                                    <button mat-menu-item (click)="changeNumberFormat()" >In M's</button>
                                                  </mat-menu> -->
                                                <mat-menu #menu="matMenu" class="throughputmetrics-menu"
                                                    xPosition="before" [hasBackdrop]="true">
                                                    <ng-template matMenuContent let-metric_name="metric_name">
                                                        <!-- <div (mouseout)="closeMyMenu([menuTrigger1, menuTrigger2, menuTrigger3, menuTrigger4])"> -->
                                                        <button mat-menu-item
                                                            (click)="changeNumberFormat(metric_name, 'K')">In
                                                            K's</button>
                                                        <button mat-menu-item
                                                            (click)="changeNumberFormat(metric_name, 'M')">In
                                                            M's</button>
                                                        <button mat-menu-item
                                                            (click)="changeNumberFormat(metric_name, 'default')">Default</button>
                                                        <!-- </div> -->

                                                    </ng-template>

                                                </mat-menu>
                                            </tr>
                                        </thead>
                                        <tbody role="rowgroup">
                                            <tr class="mat-row cdk-row">
                                                <td class="mat-cell cdk-cell">Auto Fill</td>
                                                <td class="mat-cell cdk-cell">
                                                    {{abbreviateNumber(autofill_throughput_metrics[0]?.transaction ||
                                                    '-', false, true, transaction_number_format)}}</td>
                                                <td class="mat-cell cdk-cell">
                                                    {{abbreviateNumber(autofill_throughput_metrics[0]?.volume || '-',
                                                    false, true, volume_number_format)}}</td>
                                                <td class="mat-cell cdk-cell">
                                                    {{abbreviateNumber(autofill_throughput_metrics[0]?.revenue || '-',
                                                    false, true, revenue_number_format)}}</td>
                                                <td class="mat-cell cdk-cell">
                                                    {{abbreviateNumber(autofill_throughput_metrics[0]?.profit || '-',
                                                    false, true, profit_number_format)}}</td>
                                            </tr>
                                            <tr class="mat-row cdk-row">
                                                <td class="mat-cell cdk-cell">Current</td>
                                                <td class="mat-cell cdk-cell">
                                                    {{abbreviateNumber(throughput_metrics[0]?.transaction || '-', false,
                                                    true, transaction_number_format)}}</td>
                                                <td class="mat-cell cdk-cell">
                                                    {{abbreviateNumber(throughput_metrics[0]?.volume || '-', false,
                                                    true, volume_number_format)}}</td>
                                                <td class="mat-cell cdk-cell">
                                                    {{abbreviateNumber(throughput_metrics[0]?.revenue || '-', false,
                                                    true, revenue_number_format)}}</td>
                                                <td class="mat-cell cdk-cell">
                                                    {{abbreviateNumber(throughput_metrics[0]?.profit || '-', false,
                                                    true, profit_number_format)}}</td>
                                            </tr>
                                            <tr class="mat-row cdk-row">
                                                <td class="mat-cell cdk-cell"><em class="bi bi-triangle"></em></td>
                                                <td class="mat-cell cdk-cell">
                                                    <ng-template #deltatransaction let-transaction="transaction"
                                                        [ngTemplateOutlet]="deltatransaction"
                                                        [ngTemplateOutletContext]="{transaction: calculateDelta(throughput_metrics[0]?.transaction , autofill_throughput_metrics[0]?.transaction) }">
                                                        <div *ngIf="transaction.difference"> {{transaction.difference}}
                                                            <span *ngIf="transaction.percentage_change"
                                                                [ngClass]="transaction.percentage_change > 0 ? 'text-success' : 'text-danger' ">
                                                                ({{transaction.percentage_change}}%)
                                                            </span>
                                                        </div>
                                                    </ng-template>
                                                </td>
                                                <td class="mat-cell cdk-cell">
                                                    <ng-template #deltavolume let-volume="volume"
                                                        [ngTemplateOutlet]="deltavolume"
                                                        [ngTemplateOutletContext]="{volume: calculateDelta(throughput_metrics[0]?.volume , autofill_throughput_metrics[0]?.volume) }">
                                                        <div *ngIf="volume.difference"> {{volume.difference }}
                                                            <span *ngIf="volume.percentage_change"
                                                                [ngClass]="volume.percentage_change > 0 ? 'text-success' : 'text-danger' ">
                                                                ({{volume.percentage_change}}%)
                                                            </span>
                                                        </div>
                                                    </ng-template>
                                                </td>
                                                <td class="mat-cell cdk-cell">
                                                    <ng-template #deltarevenue let-revenue="revenue"
                                                        [ngTemplateOutlet]="deltarevenue"
                                                        [ngTemplateOutletContext]="{revenue: calculateDelta(throughput_metrics[0]?.revenue , autofill_throughput_metrics[0]?.revenue) }">
                                                        <div *ngIf="revenue.difference"> {{revenue.difference}}
                                                            <span *ngIf="revenue.percentage_change"
                                                                [ngClass]="revenue.percentage_change > 0 ? 'text-success' : 'text-danger' ">
                                                                ({{revenue.percentage_change}}%)
                                                            </span>
                                                        </div>
                                                    </ng-template>
                                                </td>
                                                <td class="mat-cell cdk-cell">
                                                    <ng-template #deltaprofit let-profit="profit"
                                                        [ngTemplateOutlet]="deltaprofit"
                                                        [ngTemplateOutletContext]="{profit: calculateDelta(throughput_metrics[0]?.profit , autofill_throughput_metrics[0]?.profit) }">
                                                        <div *ngIf="profit.difference"> {{profit.difference}}
                                                            <span *ngIf="profit.percentage_change"
                                                                [ngClass]="profit.percentage_change > 0 ? 'text-success' : 'text-danger' ">
                                                                ({{profit.percentage_change}}%)
                                                            </span>
                                                        </div>
                                                    </ng-template>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>

                                    <button mat-raised-button id="recalculate-insight-btn" 
                                        (click)="calculateBusinessInsight()">Recalculate Business Insights &
                                        Representation</button>
                                </ng-template>
                                <ng-template #elseBlock>
                                    <button mat-raised-button id="calculate-business-insight-btn" color="warn"
                                        [disabled]="planogram_placed_skus.length === 0"
                                        (click)="calculateBusinessInsight()">Calculate Business Insights &
                                        Representation</button>
                                </ng-template>
                            </div>
                        </as-split-area>
                        <as-split-area [size]="25" style="cursor: default;" class="right-padding-split-area">
                            <h3 style="margin:10px 0">Insights</h3>
                            <mat-card class="recommended-card" *ngFor="let recommandation of recommandations | async">
                                <em class="bi bi-lightbulb"></em>
                                <ng-template #recomandationtemplete let-channel="channel" let-sku_name="sku_name"
                                    [ngTemplateOutlet]="recomandationtemplete"
                                    [ngTemplateOutletContext]="{sku_name: recommandation.split('has')[0],
                                                    channel: recommandation.substring(recommandation.lastIndexOf('for') + 3, recommandation.lastIndexOf('channel')) }">

                                    <div class="recommandation"> <strong>{{sku_name}}</strong>
                                        {{recommandation.replace(sku_name , ' ').split(channel)[0]}}
                                        <strong>{{channel}}</strong>
                                        {{recommandation.split(channel)[1]}}
                                    </div>

                                </ng-template>

                            </mat-card>
                        </as-split-area>
                    </as-split>
                </as-split-area>
            </as-split>

        </div>

    </mat-sidenav>
    <div class="sticy-container">
        <div class="absolute-container">
            <h4 *ngIf="!show_sku_list" class="show-sku-list-heading">
                <em class="bi bi-chevron-double-right outer-circle" (click)="show_sku_list = !show_sku_list"></em> Edit
                Cooler
                /
                Browse SKUs
            </h4>
            <em class="bi bi-arrow-left back-icon" (click)="goToPreviousStepEventEmitter.emit(1)"></em>
        </div>

        <div class="undo-redo-div"
            *ngIf="cooler_config && cooler_config.cooler_height && cooler_config.cooler_width && cooler_config.shelves && cooler_config.shelves.length > 0">
            <em class="bi bi-arrow-counterclockwise" style="margin-right: 10px;"
                [ngStyle]="{'cursor': undo_entries.length > 0 ? 'pointer' : 'default' , 'opacity': undo_entries.length > 0 ? '1' : '0.5' }"
                (click)="undoClick()"></em>
            <em class="bi bi-arrow-clockwise" style=" margin-right: 10px;"
                [ngStyle]="{'cursor': redo_entries.length > 0 ? 'pointer' : 'default' , 'opacity': redo_entries.length > 0 ? '1' : '0.5' }"
                (click)="redoClick()"></em>
        </div>
    </div>
    <!-- [placeholder_width]="placeholder_width" [placeholder_height]="placeholder_height"  -->
    <app-planogram-component [drop_item_ids]="drop_item_ids" [cooler_config]="(cooler_config)"
        [placed_sku_list]="planogram_placed_skus" [drag_drop_allowed]="true"
        [dim_benchmark_clearance]="dim_benchmark_clearance"
        [is_update_highlight_sku_placeholder]="(is_update_highlight_sku_placeholder | async)!"
        [vertical_cooler_orientation]="vertical_cooler_orientation"
        [vertical_brand_association]="vertical_brand_association" (newDropItemIdEvent)="addDropItemId($event)"
        (skuPlacementFlagEventEmitter)="skuPlacementFlagUpdate()"
        [add_sku_click_event]="add_sku_click_event_subject.asObservable()">
    </app-planogram-component>
</mat-sidenav-container>